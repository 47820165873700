import { all } from 'redux-saga/effects';
import { initUsersSagas } from './users/users.sagas';
import { initCompanySagas } from './company/company.sagas';
import { initSessionsSagas } from './sessions/sessions.sagas';
import { initThemeSagas } from './theme/theme.sagas';
import { initNotificationsSagas } from './notifications/notifications.sagas';
import { initCustomersSagas } from './customers/customers.sagas';
import { initDocumentationSagas } from './documentation/documentation.saga';
import { initAgentsSagas } from './agents/agents.sagas';
import { initAuthUserSagas } from './authUser/authUser.sagas';
import { initToastsSagas } from './toasts/toasts.sagas';
import { initBannerSagas } from './banner/banner.sagas';
import { initNavigationSagas } from './navigation/navigation.sagas';
import { initDashboardSagas } from './dashboard/dashboard.sagas';
import { initQASagas } from './qa/qa.sagas';
import { initDocumentsSagas } from './documents/documents.sagas';
import { initTelegramSagas } from './telegram/telegram.sagas';
import { initCallsSagas } from './call/call.sagas';
import { initBillingSagas } from './billing/billing.sagas';
import { initIntegrationsSagas } from './integrations/integrations.sagas';

export function* initSagas() {
  yield all([
    initAuthUserSagas(),
    initUsersSagas(),
    initSessionsSagas(),
    initCompanySagas(),
    initThemeSagas(),
    initNotificationsSagas(),
    initCustomersSagas(),
    initDocumentationSagas(),
    initAgentsSagas(),
    initToastsSagas(),
    initBannerSagas(),
    initNavigationSagas(),
    initDashboardSagas(),
    initQASagas(),
    initDocumentsSagas(),
    initTelegramSagas(),
    initCallsSagas(),
    initBillingSagas(),
    initIntegrationsSagas(),
  ]);
}
