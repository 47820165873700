import React, { useRef, useState } from 'react';
import Icon, { IconName } from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import * as SC from './styles';
import { useColors } from '../../hooks/theme';
import useOnClickOutside from '../../hooks/useOnClickOutside';

export interface IMenuItem {
  iconName: IconName;
  color?: string;
  title: string;
  onClick: () => void;
}

export interface IIconMenuProps {
  position?: 'left' | 'right';
  buttonIcon: IconName;
  buttonColor?: string;
  buttonBgColor?: string;
  buttonWidth?: number;
  buttonHeight?: number;
  buttonWithBackground?: boolean;
  menuItems: IMenuItem[];
  isVisible?: boolean;
}

export default function IconMenu({
  position = 'right',
  buttonIcon,
  buttonColor,
  buttonBgColor,
  buttonWidth,
  buttonHeight,
  buttonWithBackground = false,
  menuItems,
  isVisible = true,
}: IIconMenuProps) {
  const colors = useColors();
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrapperRef, () => setOpen(false));

  function handleToggleOpen() {
    setOpen(prev => !prev);
  }

  function handleItemClick(itemFunction: () => void) {
    itemFunction();
    setOpen(false);
  }

  return (
    <SC.Wrapper ref={wrapperRef} $isVisible={isVisible}>
      <IconButton
        iconName={buttonIcon}
        onClick={handleToggleOpen}
        iconColor={buttonColor}
        width={buttonWidth}
        height={buttonHeight}
        withBackground={buttonWithBackground}
        backgroundColor={buttonBgColor}
      />
      {open && (
        <SC.Dropdown $position={position}>
          {menuItems.map(item => (
            <SC.DropdownItem key={item.title} onClick={() => handleItemClick(item.onClick)} $color={item.color}>
              <Icon name={item.iconName} color={item.color ?? colors.gray500} />
              {item.title}
            </SC.DropdownItem>
          ))}
        </SC.Dropdown>
      )}
    </SC.Wrapper>
  );
}
