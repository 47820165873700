import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { store } from '../redux/store';
import { usePush } from './navigation';
import { auth } from '../config/firebase';
import { ROUTES } from '../constants/routes';
import { setAuthUser } from '../redux/authUser/authUser.redux';

export function useAuth() {
  const push = usePush();
  const [isReady, setIsReady] = useState(false);
  const userToken = localStorage.getItem('userToken');
  const currentRoute = Object.values(ROUTES).find(item => item.path === window.location.pathname);
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, async user => {
      if (!user) {
        localStorage.removeItem('userToken');
        push('/');
        setIsReady(true);
        return;
      }

      const { displayName, email, uid, photoURL } = user;
      const firebaseToken = await auth.currentUser?.getIdTokenResult();
      const role = firebaseToken?.claims.role;

      const hasPermission = currentRoute?.permissions.includes(role);

      if (!hasPermission && currentRoute) {
        setRedirected(true);
        return push(ROUTES.dashboard.path);
      }

      store.dispatch(
        setAuthUser({
          displayName: displayName ?? '',
          email: email ?? '',
          uid,
          role,
          companyId: firebaseToken?.claims.company_id,
          token: firebaseToken?.token || '',
          photoUrl: photoURL ?? '',
        }),
      );

      localStorage.setItem('userToken', firebaseToken?.token || '');

      setIsReady(true);
    });

    if (!userToken && currentRoute?.path === ROUTES.login.path) {
      setIsReady(true);
    }

    return () => {
      unregisterAuthObserver();
    };
  }, [redirected]);

  return { isReady };
}
