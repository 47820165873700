import styled from 'styled-components';

export const Input = styled.input`
  display: none;
`;

export const InputWrapper = styled.span<{ $background?: string; $checked: boolean }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;

  transition: all 0.2s;
  border: ${props => !props.$checked && `1px solid ${props.theme.colors.gray300}`};
  border-radius: 0.4rem;
  background: ${({ $checked, theme, $background }) => {
    const color = $background ?? theme.colors.primary500;
    return $checked ? color : theme.colors.gray50;
  }};
`;

export const Label = styled.label<{ $disabled: boolean; $readOnly: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  cursor: pointer;
  pointer-events: ${({ $disabled, $readOnly }) => ($disabled || $readOnly ? 'none' : 'auto')};
`;
