import { ReactNode } from 'react';
import Icon, { IconName } from '../Icon/Icon';
import * as SC from './styles';

interface ITooltipProps {
  text: string;
  id: string;
  iconName?: IconName;
  iconColor?: string;
  children?: ReactNode;
}

export default function Tooltip({ text, id, iconName, iconColor, children }: ITooltipProps) {
  return (
    <>
      <SC.TooltipStyled id={`tooltip-${id}`}>{text}</SC.TooltipStyled>
      <SC.Wrapper data-tooltip-id={`tooltip-${id}`}>
        {iconName && <Icon name={iconName} color={iconColor} />}
        {children}
      </SC.Wrapper>
    </>
  );
}
