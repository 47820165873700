import * as SC from './styles';

interface IDividerProps {
  title?: string;
  noMargin?: boolean;
}

export default function Divider({ title, noMargin = false }: IDividerProps) {
  return <SC.Divider $noMargin={noMargin}>{title && <span>{title}</span>}</SC.Divider>;
}
