import styled from 'styled-components';

export const RadioFieldContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const RadioWrapper = styled.div<{ $disabled: boolean }>`
  display: flex;
  gap: 1.8rem;

  opacity: ${({ $disabled }) => ($disabled ? '.5' : 1)};
`;

export const RadioFieldLabelRequired = styled.sup`
  color: red;
`;

export const InputErrorWrapper = styled.div`
  position: absolute;
  top: calc(100% + 0.2rem);
`;
