import React from 'react';
import * as SC from './styles';
import Icon, { IconName } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

export interface ICheckboxProps {
  name: string;
  id?: string;
  label?: string;
  onChange?: (e: any) => void;
  checked: boolean;
  value?: string;
  disabled?: boolean;
  color?: string;
}

export default function Checkbox({ name, id, label, onChange, checked, value, disabled = false, color }: ICheckboxProps) {
  return (
    <SC.Label $disabled={disabled} $readOnly={!onChange}>
      <SC.Input type="checkbox" id={id} value={value} name={name} checked={checked} onChange={onChange} readOnly={!onChange} />
      <SC.InputWrapper $background={color} $checked={checked}>
        {checked && <Icon name={IconName.Check} width={1.4} height={1.4} />}
      </SC.InputWrapper>
      {label && <Typography variant="body">{label}</Typography>}
    </SC.Label>
  );
}
