import { createSlice, Reducer, PayloadAction } from '@reduxjs/toolkit';
import { IAskedQuestion, ICallAnalytics, IChatOpen, IDashboardState, IDepartment, ISuccessEvaluation } from './dashboard.types';
import { EDateFilter } from '../../common/types';

const initialState: IDashboardState = {
  activeSessions: 0,
  askedQuestionsCount: 0,
  topAskedQuestions: [],
  dateFilter: EDateFilter.today,
  chatOpen: { opened: 0, shown: 0 },
  calls: {
    departments: [],
    successEvaluation: undefined,
    directions: undefined,
    handlers: undefined,
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    initDashboard: () => {},
    setChatOpen: (state: IDashboardState, action: PayloadAction<IChatOpen>) => {
      state.chatOpen = action.payload;
    },
    setActiveSessions: (state: IDashboardState, action: PayloadAction<number>) => {
      state.activeSessions = action.payload;
    },
    setAskedQuestionsCount: (state: IDashboardState, action: PayloadAction<number>) => {
      state.askedQuestionsCount = action.payload;
    },
    setTopAskedQuestions: (state: IDashboardState, action: PayloadAction<IAskedQuestion[]>) => {
      state.topAskedQuestions = action.payload;
    },
    setFilterDate: (state: IDashboardState, action: PayloadAction<EDateFilter>) => {
      state.dateFilter = action.payload;
    },
    setCallsAnalytics: (state: IDashboardState, action: PayloadAction<ICallAnalytics>) => {
      state.calls = action.payload;
    },
  },
});

export const {
  initDashboard,
  setChatOpen,
  setActiveSessions,
  setAskedQuestionsCount,
  setTopAskedQuestions,
  setFilterDate,
  setCallsAnalytics,
} = dashboardSlice.actions;

export const dashboardReducer: Reducer<IDashboardState> = dashboardSlice.reducer;
