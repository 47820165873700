import { Reducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICallsState, ICall, IListCall, ECallHandler } from './call.types';
import { EDateFilter } from '../../common/types';

const initialState: ICallsState = {
  calls: [],
  activeCall: undefined,
  dateFilter: EDateFilter.today,
  handlerFilter: undefined,
};

export const callsSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {
    getCalls: (state: ICallsState, action: PayloadAction<string>) => {},
    setCalls: (state: ICallsState, action: PayloadAction<IListCall[]>) => {
      state.calls = action.payload;
    },
    getActiveCall: (state: ICallsState, action: PayloadAction<string>) => {},
    setActiveCall: (state: ICallsState, action: PayloadAction<ICall | undefined>) => {
      state.activeCall = action.payload;
    },
    setDateFilter: (state: ICallsState, action: PayloadAction<EDateFilter>) => {
      state.dateFilter = action.payload;
    },
    setHandlerFilter: (state: ICallsState, action: PayloadAction<ECallHandler>) => {
      state.handlerFilter = action.payload;
    },
  },
});

export const { getCalls, setCalls, getActiveCall, setActiveCall, setDateFilter, setHandlerFilter } = callsSlice.actions;

export const callsReducer: Reducer<ICallsState> = callsSlice.reducer;
