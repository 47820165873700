import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { IDocumentsState, IUploadingDocument } from './documents.types';

const initialState: IDocumentsState = {
  uploadingDocument: undefined,
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setUploadingDocument: (state: IDocumentsState, action: PayloadAction<IUploadingDocument>) => {
      state.uploadingDocument = action.payload;
    },
    uploadDocument: (state: IDocumentsState, action: PayloadAction<File>) => {},
  },
});

export const { setUploadingDocument, uploadDocument } = documentsSlice.actions;

export const documentsReducer: Reducer<IDocumentsState> = documentsSlice.reducer;
