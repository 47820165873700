import { useSelector } from 'react-redux';
import { IBillingState } from '../../redux/billing/billing.types';
import Card from '../Card/Card';
import { Typography } from '../Typography/Typography';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import { getDateFilter } from '../../common';
import { EDateFilter } from '../../common/types';
import { DateTime } from 'luxon';
import Loader, { LoaderEnum } from '../Loader/Loader';

export default function CompanyBilling() {
  const { t } = useTranslation();
  const { totalCost, todayCost, dailyAnalytics, isBillingLoading } = useSelector((state: { billing: IBillingState }) => state.billing);
  const dates = getDateFilter(EDateFilter.thisMonth);
  const datesText = `${DateTime.fromISO(dates.start).toFormat('MMM dd')} - ${DateTime.fromISO(dates.end).toFormat('MMM dd')}`;

  if (isBillingLoading) {
    return (
      <Card>
        <Loader variant={LoaderEnum.Page} />
      </Card>
    );
  }

  return (
    <SC.Wrapper>
      <Card>
        <SC.BillingCards>
          <SC.BillingCard>
            <Typography variant="caption">{t('billing.dailyTitle')}</Typography>
            <Typography variant="h2">${todayCost}</Typography>
            <Typography variant="caption">{t('billing.dailySubTitle')}</Typography>
          </SC.BillingCard>
          <SC.BillingCard>
            <Typography variant="caption">{t('billing.monthlyTitle')}</Typography>
            <Typography variant="h2">${totalCost}</Typography>
            <Typography variant="caption">{datesText}</Typography>
          </SC.BillingCard>
        </SC.BillingCards>
      </Card>
      <Card>
        <Typography variant="h2">{t('billing.statisticsTitle')}</Typography>
        <SC.StatisticsHead>
          <Typography variant="caption" color="default">
            {t('billing.statisticsHeadDate')}
          </Typography>
          <Typography variant="caption" color="default">
            {t('billing.statisticsHeadMinutes')}
          </Typography>
          <Typography variant="caption" color="default">
            {t('billing.statisticsHeadCost')}
          </Typography>
        </SC.StatisticsHead>
        {dailyAnalytics.map(item => (
          <SC.StatisticsItem key={item.date}>
            <Typography variant="caption">{item.date}</Typography>
            <Typography variant="caption">{item.minutes}</Typography>
            <Typography variant="caption">${item.cost}</Typography>
          </SC.StatisticsItem>
        ))}
      </Card>
    </SC.Wrapper>
  );
}
