import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getBillingData, setBillingData, setIsBillingLoading } from './billing.redux';
import { IGetBillingDataResponse } from './billing.types';
import { getBillingDataRequest } from './billing.service';
import { getDateFilter } from '../../common';
import { EDateFilter } from '../../common/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { showToast } from '../toasts/toasts.redux';
import { t } from '../../config/i18n';

export function* getBillingDataSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  yield put(setIsBillingLoading(true));
  const currentFilter = getDateFilter(EDateFilter.thisMonth);
  const { success, data }: IGetBillingDataResponse = yield call(getBillingDataRequest, companyId, currentFilter.start, currentFilter.end);

  if (success && data) {
    yield put(setBillingData(data));
  } else {
    yield put(
      showToast({
        type: 'error',
        message: t('billing.getBillingDateFailed'),
      }),
    );
  }

  yield put(setIsBillingLoading(false));
}

export function* initBillingSagas(): SagaIterator {
  yield takeEvery(getBillingData.toString(), getBillingDataSaga);
}
