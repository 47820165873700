import { z } from 'zod';
import { UserRole } from '../redux/users/users.types';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const emailValidation = z.string().email('Invalid email format.');

export const domainValidation = z.string().refine(
  (value: string) => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const productionPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/;
    const developmentPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+|localhost)(\/[^\s]*)?$/;
    const pattern = isDevelopment ? developmentPattern : productionPattern;

    return pattern.test(value || '');
  },
  {
    message: 'Invalid domain format',
  },
);

export const companyNameValidation = z.string().min(1, 'Field is required').max(100, 'Maximum of 100 characters allowed');
export const adminNameValidation = z.string().min(1, 'Field is required').max(100, 'Maximum of 100 characters allowed');

export const industryValidation = z.string().min(1, 'Field is required').max(100, 'Maximum of 100 characters allowed');

const phoneSchema = z.string().refine(value => isValidPhoneNumber(value), {
  message: 'Invalid phone number format',
});

const optionalPhoneSchema = z
  .string()
  .nullable()
  .refine(
    value => {
      if (value === null || value === undefined || value === '') return true;
      return isValidPhoneNumber(value);
    },
    {
      message: 'Invalid phone number format',
    },
  );

export const newCompanyValidation = z.object({
  image: z.string(),
  name: companyNameValidation,
  domain: domainValidation,
  adminName: adminNameValidation,
  adminEmail: emailValidation,
  industry: industryValidation,
  email: emailValidation,
  phone: phoneSchema,
});

export const editCompanyValidation = z.object({
  image: z.string(),
  name: companyNameValidation,
  domain: domainValidation,
  industry: industryValidation,
  email: emailValidation,
  phone: phoneSchema,
});

export const agentValidation = z.object({
  image: z.string(),
  name: z.string().min(1, 'Field is required').max(100, 'Maximum of 100 characters allowed'),
  personality: z.string().min(3, 'Minimum of 3 characters long').max(100, 'Maximum of 100 characters allowed'),
  greeting: z.string().min(2, 'Minimum of 2 characters long').max(200, 'Maximum of 200 characters allowed'),
});

export const accountValidation = z.object({
  photoUrl: z.string(),
  displayName: z.string().min(1, 'Field is required').max(100, 'Maximum of 100 characters allowed'),
});

export type AccountValidationType = z.infer<typeof accountValidation>;

export const editUserValidation = z.object({
  photoUrl: z.string(),
  displayName: z.string().min(1, 'Field is required').max(100, 'Maximum of 100 characters allowed'),
  email: emailValidation,
  role: z.enum([...Object.values(UserRole)] as [string, ...string[]]),
  whatsappPhone: optionalPhoneSchema,
});

export const userValidation = z.object({
  photoUrl: z.string(),
  displayName: z.string().min(1, 'Field is required').max(100, 'Maximum of 100 characters allowed'),
  email: emailValidation,
  role: z.enum([...Object.values(UserRole)] as [string, ...string[]]),
  whatsappPhone: optionalPhoneSchema,
});

export const qAValidation = z.object({
  question: z.string().min(1, 'Field is required').max(10000, 'Maximum of 10000 characters allowed'),
  answer: z.string().min(1, 'Field is required').max(10000, 'Maximum of 10000 characters allowed'),
  tags: z.array(z.number()).optional(),
});

export type QAValidationType = z.infer<typeof qAValidation>;

const colorSchema = z.string().refine(value => /^#([0-9A-F]{3}){1,2}$/i.test(value), {
  message: 'Must be a valid hex color code',
});

export const urlSchema = z.object({
  url: z.string().refine(
    value => {
      if (value.trim() === '') {
        return true;
      }
      try {
        new URL(value);
        return true;
      } catch {
        return false;
      }
    },
    {
      message: 'Invalid URL',
    },
  ),
});

export type urlSchemaType = z.infer<typeof urlSchema>;

export const textValidation = z.object({
  content: z.string(),
});

export type TextSchemaType = z.infer<typeof textValidation>;

export const telegramChatValidation = z.object({
  chatName: z.string().min(1, 'Field is required').max(30, 'Maximum of 30 characters allowed'),
  chatId: z
    .string()
    .min(1, 'Field is required')
    .refine(
      value => {
        if (isNaN(Number(value))) return false;
        return true;
      },
      {
        message: 'Must be a number',
      },
    ),
  chatAdminId: z.string().refine(
    value => {
      if (value.length === 0) return true;
      if (isNaN(Number(value))) return false;
      return true;
    },
    {
      message: 'Must be a number',
    },
  ),
});

export type TelegramChatValidationType = {
  chatName: string;
  chatId: number;
  chatAdminId: number;
};

export const smartmovingValidation = z.object({
  providerKey: z.string().min(1, 'Field is required'),
  branchId: z.string().min(1, 'Field is required'),
});

export type SmartmovingValidationType = z.infer<typeof smartmovingValidation>;

export const telegramNotificationValidation = z.object({
  chatId: z.string().min(1, 'Field is required'),
});

export type TelegramNotificationValidationType = z.infer<typeof telegramNotificationValidation>;

export const trelloValidation = z.object({
  apiKey: z.string().min(1, 'Field is required'),
  apiToken: z.string().min(1, 'Field is required'),
  idList: z.string().min(1, 'Field is required'),
});

export type TrelloValidationType = z.infer<typeof trelloValidation>;

export const aircallValidation = z.object({
  apiId: z.string().min(1, 'Field is required'),
  apiToken: z.string().min(1, 'Field is required'),
});

export type AircallValidationType = z.infer<typeof aircallValidation>;
