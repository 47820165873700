import { EDateFilter } from '../../common/types';

export enum ENotificationEnum {
  welcome = 'welcome',
  readyToBook = 'ready_to_book',
  needsHuman = 'needs_human',
  contactRequest = 'contact_request',
  unknownAnswer = 'unknown_answer',
  unansweredQuestion = 'unanswered_question',
}

export interface INotification {
  type: ENotificationEnum;
  title: string;
  content: string;
  createdAt: string;
  sessionId: string;
  read: boolean;
  data?: Record<string, any>;
  qaId?: number;
}

export interface INotificationWithId extends INotification {
  id: string;
}

export interface IRawNotification {
  type: ENotificationEnum;
  data: Record<string, string>;
  createdAt: string;
  sessionId: string;
  read: boolean;
  id: string;
  qaId?: string;
}

export interface INotificationsState {
  notifications: INotificationWithId[];
  isNotificationsOpen: boolean;
  unreadNotificationsCount: number;
  typeFilter?: ENotificationEnum;
  dateFilter: EDateFilter;
  activeNotification?: INotificationWithId;
}
