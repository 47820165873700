export enum ECustomerStatus {
  new = 'new',
  contacted = 'contacted',
  converted = 'converted',
  lost = 'lost',
}

export interface ICustomer {
  status: ECustomerStatus;
  firstName: string;
  lastName: string;
  sessionId: string;
  companyId: string;
  email: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
}

export interface ICustomerWithId extends ICustomer {
  id: string;
}

export interface ICustomersState {
  customer: ICustomerWithId;
  customers: ICustomerWithId[];
  sortBy?: TCustomersSort;
  isDescendingOrder: boolean;
  searchValue: string;
}

export type TCustomersSort = 'firstName' | 'email' | 'phone' | 'status';
