import { useTranslation } from 'react-i18next';
import * as SC from './styles';
import Icon, { IconName } from '../Icon/Icon';
import { useColors } from '../../hooks/theme';
import { useState } from 'react';
import { Typography } from '../../components/Typography/Typography';
import { IAgentWithId } from '@tuler/shared/lib/types';
import Badge from '../Badge/Badge';
import IconMenu, { IMenuItem } from '../IconMenu/IconMenu';
import { useDispatch } from 'react-redux';
import { showToast } from '../../redux/toasts/toasts.redux';
import Card from '../Card/Card';

interface AgentsProps {
  agents: IAgentWithId[];
  onShowAgentModal: (id?: string) => void;
  onOpenDeleteAgentModal: (id: string) => void;
  onEnableAgent: (id: string, value: boolean) => void;
}

export default function Agents({ agents, onShowAgentModal, onOpenDeleteAgentModal, onEnableAgent }: AgentsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const colors = useColors();

  function copyAgentScript(agentId: string) {
    const script =
      process.env.NODE_ENV !== 'production'
        ? `<script
    id="tuler-script"
    defer="defer"
    data-agent-id="${agentId}"
    src="https://storage.googleapis.com/tuler-chat/v2/index.js"
    ></script>`
        : `<script
        id="tuler-script"
        defer="defer"
        data-agent-id="${agentId}"
        src="https://storage.googleapis.com/tuler-chat-prod/v2/index.js"
        ></script>`;

    navigator.clipboard.writeText(script);
    dispatch(
      showToast({
        message: t('agents.agentCodeCopied'),
      }),
    );
  }

  function getAgent(agent: IAgentWithId) {
    const isActive = agent.enabled;
    const menuItems: IMenuItem[] = [
      {
        iconName: IconName.User,
        title: isActive ? t('agents.agentDeactivate') : t('agents.agentActivate'),
        onClick: () => onEnableAgent(agent.id, !isActive),
        color: isActive ? colors.red500 : colors.green500,
      },
      {
        iconName: IconName.DocumentDuplicate,
        title: t('agents.agentCopy'),
        onClick: () => copyAgentScript(agent.id),
      },
      {
        iconName: IconName.PencilAlt,
        title: t('agents.agentEdit'),
        onClick: () => onShowAgentModal(agent.id),
      },
      {
        iconName: IconName.Trash,
        title: t('agents.agentDelete'),
        onClick: () => onOpenDeleteAgentModal(agent.id),
      },
    ];

    return (
      <Card key={agent.id} padding="small">
        <SC.AgentsItem>
          {agent.image ? (
            <SC.AgentsItemImage src={agent.image} />
          ) : (
            <SC.AgentsItemImageNull>
              <Icon name={IconName.User} color={colors.gray300} width={5} height={5} />
            </SC.AgentsItemImageNull>
          )}
          <SC.AgentsItemHead>
            <Typography variant="h2">{agent.name}</Typography>
            <IconMenu
              position="right"
              buttonIcon={IconName.DotsHorizontal}
              buttonColor={colors.gray900}
              menuItems={menuItems}
              buttonHeight={2}
              buttonWidth={2}
            />
          </SC.AgentsItemHead>
          <SC.AgentsItemBody>
            <Typography variant="body" ellipsis>
              {agent.personality}
            </Typography>
            <Badge text={isActive ? t('agents.agentActive') : t('agents.agentNotActive')} variant={isActive ? 'success' : 'error'} />
          </SC.AgentsItemBody>
        </SC.AgentsItem>
      </Card>
    );
  }

  return (
    <SC.Agents>
      <SC.AgentsList>{agents.map(agent => getAgent(agent))}</SC.AgentsList>
    </SC.Agents>
  );
}
