import { delay } from 'redux-saga/effects';
import styled, { DefaultTheme } from 'styled-components';
import { TTypographyColor } from './Typography';

interface BaseProps {
  $ellipsis?: boolean;
  $bold?: boolean;
  $color?: TTypographyColor;
  $uppercase: boolean;
  theme: DefaultTheme;
  $isPre: boolean;
}

const baseStyles = (props: BaseProps): Record<string, any> => {
  const ellipsis = props.$ellipsis
    ? { display: '-webkit-box', '-webkit-line-clamp': '1', '-webkit-box-orient': 'vertical', overflow: 'hidden' }
    : {};

  const bold = props.$bold ? { fontWeight: 700 } : {};
  const whiteSpace = props.$isPre ? { whiteSpace: 'pre-wrap', wordBreak: 'break-word' } : {};
  let color = {};
  switch (props.$color) {
    case undefined:
      color = {};
      break;
    case 'success':
      color = { color: props.theme.colors?.success.default };
      break;
    case 'warning':
      color = { color: props.theme.colors?.warning.default };
      break;
    case 'brand':
      color = { color: props.theme.colors?.brand.default };
      break;
    case 'error':
      color = { color: props.theme.colors?.error.default };
      break;
    default:
      color = { color: props.theme.colors?.text[props.$color] };
  }

  const uppercase = props.$uppercase ? { textTransform: 'uppercase' } : {};
  return { margin: 0, lineHeight: '150%', ...ellipsis, ...bold, ...color, ...uppercase, ...whiteSpace };
};

// TODO: Delete all old typographies

export const H5 = styled.h5<BaseProps>`
  font-weight: 600;
  font-size: 1.4rem;
  color: ${props => props.theme.colors?.text.default};
  ${baseStyles}
`;

export const H6 = styled.h6<BaseProps>`
  font-weight: 600;
  font-size: 1.2rem;
  color: ${props => props.theme.colors?.text.default};
  ${baseStyles}
`;

export const Subtitle2 = styled.p<BaseProps>`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${props => props.theme.colors?.text.default};
  ${baseStyles}
`;

export const Span = styled.p<BaseProps>`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${props => props.theme.colors?.text.secondary};
  ${baseStyles}
`;

// new

export const H1 = styled.h1<BaseProps>`
  font-weight: 700;
  font-size: 2.4rem;
  color: ${props => props.theme.colors?.text.default};
  ${baseStyles}
`;

export const H2 = styled.h2<BaseProps>`
  font-weight: 700;
  font-size: 2rem;
  color: ${props => props.theme.colors?.text.default};
  ${baseStyles}
`;

export const H3 = styled.h3<BaseProps>`
  font-weight: 600;
  font-size: 1.6rem;
  color: ${props => props.theme.colors?.text.default};
  ${baseStyles}
`;

export const H4 = styled.h4<BaseProps>`
  font-weight: 600;
  font-size: 1.4rem;
  color: ${props => props.theme.colors?.text.default};
  ${baseStyles}
`;

export const Subtitle1 = styled.p<BaseProps>`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${props => props.theme.colors?.text.default};
  ${baseStyles}
`;

export const Placeholder = styled.p<BaseProps>`
  font-weight: 500;
  font-size: 1.4rem;
  color: ${props => props.theme.colors?.text.secondary};
  ${baseStyles}
`;

export const Caption = styled.p<BaseProps>`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${props => props.theme.colors?.text.secondary};
  ${baseStyles}
`;

export const Body = styled.p<BaseProps>`
  font-weight: 400;
  font-size: 1.4rem;
  color: ${props => props.theme.colors?.text.body};
  ${baseStyles}
`;

export const Message = styled.p<BaseProps>`
  font-weight: 500;
  font-size: 1.5rem;
  color: ${props => props.theme.colors?.text.body};
  ${baseStyles}
`;

export const Label = styled.p<BaseProps>`
  font-weight: 600;
  font-size: 1.2rem;
  color: ${props => props.theme.colors?.text.default};
  ${baseStyles}
`;
