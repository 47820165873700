import { SagaIterator } from 'redux-saga';
import { call, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { logger } from '../../logger';
import { PayloadAction } from '@reduxjs/toolkit';
import { goBack, push, replace } from 'redux-first-history';
import { discardChanges, showDiscardModal } from '../banner/banner.redux';
import { IBannerState } from '../banner/banner.types';
import { pushAction, replaceAction, goBackAction, pushQuery } from './navigation.redux';
import { QueryParams } from './navigation.types';

export function* shouldContinueNavigationSaga(): SagaIterator {
  try {
    const { isSaveBannerVisible } = yield select((state: { banner: IBannerState }) => state.banner);

    if (isSaveBannerVisible) {
      yield put(showDiscardModal(true));

      const { leave, stay } = yield race({
        leave: take(discardChanges),
        stay: take(showDiscardModal),
      });

      if (leave) {
        return true;
      }

      if (stay) {
        return false;
      }
    }
    return true;
  } catch (e) {
    logger.error('shouldContinueNavigationSaga', e);
  }
}

export function* pushUrlSaga({ payload: url }: PayloadAction<string>): SagaIterator {
  try {
    const shouldContinue = yield call(shouldContinueNavigationSaga);

    if (shouldContinue) {
      return yield put(push(url));
    }
  } catch (e) {
    logger.error('pushUrlSaga', e);
  }
}

export function* replaceUrlSaga({ payload: url }: PayloadAction<string>): SagaIterator {
  try {
    const shouldContinue = yield call(shouldContinueNavigationSaga);

    if (shouldContinue) {
      return yield put(replace(url));
    }
  } catch (e) {
    logger.error('replaceUrlSaga', e);
  }
}

export function* goBackSaga(): SagaIterator {
  try {
    const shouldContinue = yield call(shouldContinueNavigationSaga);

    if (shouldContinue) {
      return yield put(goBack());
    }
  } catch (e) {
    logger.error('goBackSaga', e);
  }
}

export function* pushQuerySaga({ payload: params }: PayloadAction<QueryParams>): SagaIterator {
  const queryParams = new URLSearchParams(location.search);

  for (const [key, value] of Object.entries(params)) {
    queryParams.set(key, value);
  }

  yield put(pushAction(`${location.pathname}?${queryParams.toString()}`));
}

export function* initNavigationSagas(): SagaIterator {
  yield takeEvery(pushAction.toString(), pushUrlSaga);
  yield takeEvery(replaceAction.toString(), replaceUrlSaga);
  yield takeEvery(goBackAction.toString(), goBackSaga);
  yield takeEvery(pushQuery.toString(), pushQuerySaga);
}
