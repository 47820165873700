import { Reducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAgentForm, IAgentsState } from './agents.types';
import { IAgentWithId } from '@tuler/shared/lib/types';

const initialState: IAgentsState = {
  agents: [],
  agentModalOpen: false,
  isDeleteAgentModalOpen: false,
  editAgentId: '',
};

export const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    getAgents: (state: IAgentsState, action: PayloadAction<string>) => {},
    saveAgent: (state: IAgentsState, action: PayloadAction<IAgentForm>) => {},
    updateAgent: (state: IAgentsState, action: PayloadAction<IAgentWithId>) => {
      const updateAgent = action.payload;
      const updateAgentIndex = state.agents.findIndex((agent: IAgentWithId) => agent.id === updateAgent.id);
      if (updateAgentIndex === -1) {
        state.agents = [...state.agents, updateAgent];
      } else {
        state.agents[updateAgentIndex] = updateAgent;
      }
    },
    deleteAgent: () => {},
    enableAgent: (state: IAgentsState, action: PayloadAction<{ enable: boolean; id: string }>) => {},
    setAgents: (state: IAgentsState, action: PayloadAction<IAgentWithId[]>) => {
      state.agents = action.payload;
    },
    showDeleteAgentModal: (state: IAgentsState, action: PayloadAction<boolean>) => {
      state.isDeleteAgentModalOpen = action.payload;
    },
    showAgentModal: (state: IAgentsState, action: PayloadAction<boolean>) => {
      state.agentModalOpen = action.payload;
    },
    setEditAgentId: (state: IAgentsState, action: PayloadAction<string>) => {
      state.editAgentId = action.payload;
    },
    resetEditAgentId: (state: IAgentsState) => {
      state.editAgentId = '';
    },
  },
});

export const {
  getAgents,
  saveAgent,
  updateAgent,
  showDeleteAgentModal,
  deleteAgent,
  enableAgent,
  setAgents,
  resetEditAgentId,
  showAgentModal,
  setEditAgentId,
} = agentsSlice.actions;

export const agentsReducer: Reducer<IAgentsState> = agentsSlice.reducer;
