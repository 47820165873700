import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import { FormField } from '../../common/types';
import Card from '../../components/Card/Card';
import EntityForm from '../../components/EntityForm/EntityForm';
import { IIntegrationsState } from '../../redux/integrations/integrations.types';
import { ICompanyState } from '../../redux/company/company.types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { TrelloValidationType, trelloValidation } from '../../schemas';
import { createTrello, getActiveTrello, updateTrello } from '../../redux/integrations/integrations.redux';

export default function TrelloPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeTrello, isTrelloLoading } = useSelector((state: { integrations: IIntegrationsState }) => state.integrations);
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const formKey = JSON.stringify(activeTrello);

  const trelloFields: FormField[] = [
    {
      name: 'apiKey',
      defaultValue: activeTrello?.apiKey || '',
      label: t('trello.formApiKey'),
      placeholder: t('trello.formApiKeyPlaceholder'),
      required: true,
      type: 'password',
    },
    {
      name: 'apiToken',
      defaultValue: activeTrello?.apiToken || '',
      label: t('trello.formApiToken'),
      placeholder: t('trello.formApiTokenPlaceholder'),
      required: true,
      type: 'password',
    },
    {
      name: 'idList',
      defaultValue: activeTrello?.idList || '',
      label: t('trello.formIdList'),
      placeholder: t('trello.formIdListPlaceholder'),
      required: true,
      type: 'password',
    },
  ];

  function handleSave(data: TrelloValidationType) {
    if (activeTrello?.id) {
      dispatch(updateTrello({ ...data, id: activeTrello?.id }));
    } else {
      dispatch(createTrello(data));
    }
  }

  useEffect(() => {
    if (!companyId) return;

    dispatch(getActiveTrello(companyId));
  }, [companyId]);

  return (
    <Layout>
      <PageContent hasBack title={t('trello.title')}>
        <Card>
          <EntityForm
            id={String(activeTrello?.id)}
            key={formKey}
            fields={trelloFields}
            onSubmit={handleSave}
            validationSchema={trelloValidation}
            isLoading={isTrelloLoading}
          />
        </Card>
      </PageContent>
    </Layout>
  );
}
