import { useSelector } from 'react-redux';
import { IAuthUserState } from '../redux/authUser/authUser.types';

export interface IUsePermission {
  isAdmin: boolean;
  isSuperuser: boolean;
}

export const usePermission = (): IUsePermission => {
  const { user } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const isAdmin = user.role === 'admin' || user.role === 'superuser';
  const isSuperuser = user.role === 'superuser';

  return {
    isAdmin,
    isSuperuser,
  };
};
