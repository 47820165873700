import styled from 'styled-components';

export const Header = styled.div`
  position: sticky;
  top: -${({ theme }) => theme.spacing.s9};
  padding: ${({ theme }) => theme.spacing.s6} 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s6};
  background: ${({ theme }) => theme.colors.bg.default};

  z-index: 1;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Inner = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 2rem;
`;
