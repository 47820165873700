import { PayloadAction } from '@reduxjs/toolkit';
import { ListResult, StorageReference, listAll, ref } from 'firebase/storage';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery, take } from 'redux-saga/effects';
import {
  saveQA,
  getText,
  saveText,
  setText,
  getQAList,
  setQAList,
  deleteQA,
  closeDeleteQaModal,
  scrapWebsite,
  getLinks,
  setLinks,
  deleteAllLinks,
  setDeleteAllLinksModalOpen,
  deleteLink,
  scrapeSitemap,
  trainAgent,
  resetOpenedQAs,
} from './documentation.redux';
import { storage } from '../../config/firebase';
import { t } from '../../config/i18n';
import { EDocumentation, EDocumentationAction, IDocumentationState } from './documentation.types';
import { closeModalLoading, showModalLoading } from '../loader/loader.redux';
import { getCompany } from '../company/company.redux';
import { ICompanyState } from '../company/company.types';
import { showToast } from '../toasts/toasts.redux';
import { DocumentationMetaService, LinkService, QAService, QAsService, TextService, WebsiteService } from './documentation.service';
import { showProcessingBanner, showSaveBanner, showTrainBanner } from '../banner/banner.redux';
import { IQAWithId } from '../qa/qa.types';

export function* getTextSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  const textsService = new TextService(companyId);
  const data = yield call(textsService.getText);

  yield put(setText(data));
}

export function* saveTextSaga({ payload: content }: PayloadAction<string>): SagaIterator {
  yield put(showModalLoading(t('documentation.text.loadingText')));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { text } = yield select((state: { documentation: IDocumentationState }) => state.documentation);
  const textsService = new TextService(companyId);
  const documentationMetaService = new DocumentationMetaService(companyId);
  let action = EDocumentationAction.Delete;

  // if text.content is empty and there is new content then this is a new save
  if (!text.content && content) {
    action = EDocumentationAction.Add;
  }
  // if text.content is not empty and there is new content then this is an update
  if (text.content && content) {
    action = EDocumentationAction.Update;
  }
  // if text.content is not empty and content is empty then this is a delete
  if (text.content && !content) {
    action = EDocumentationAction.Delete;
  }

  yield call(textsService.updateText, content, content.length);
  yield call(documentationMetaService.addDocumentationMetaChange, [
    {
      type: EDocumentation.Text,
      id: 'default',
      action,
    },
  ]);
  yield put(
    setText({
      content: content,
      charCount: content.length,
    }),
  );
  yield put(closeModalLoading());
  yield put(
    showToast({
      message: t('documentation.text.saved'),
    }),
  );
}

export function* getQAListSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  const qaService = new QAsService(companyId);
  const qaList = yield call(qaService.getQAList);
  yield put(setQAList(qaList));
}

export function* saveQASaga({ payload: qA }: PayloadAction<IQAWithId>) {
  yield put(showModalLoading(qA.id ? t('documentation.qa.editQALoading') : t('documentation.qa.createQALoading')));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { qAList } = yield select((state: { documentation: IDocumentationState }) => state.documentation);
  const question = qA.question.trim();
  const answer = qA.answer.trim();
  // const qaService = new QAService(companyId, qA.id);
  const documentationMetaService = new DocumentationMetaService(companyId);

  // Since react-hook-form manages the new qa state, the qa stored in redux is not filled out and this
  // is how we can tell the difference between committed and uncommitted.
  const isCommittedQA = !!qAList.find((item: IQAWithId) => item.id === qA.id)?.question;

  const dataToUpdate = {
    question: question,
    answer: answer,
    charCount: (question + answer).length,
  };

  if (isCommittedQA) {
    // yield call(qaService.updateQA, dataToUpdate);
    // yield call(documentationMetaService.addDocumentationMetaChange, [
    //   {
    //     type: EDocumentation.QA,
    //     id: qA.id,
    //     action: EDocumentationAction.Update,
    //   },
    // ]);
  } else {
    // yield call(qaService.createQA, dataToUpdate);
    // yield call(documentationMetaService.addDocumentationMetaChange, [
    //   {
    //     type: EDocumentation.QA,
    //     id: qA.id,
    //     action: EDocumentationAction.Add,
    //   },
    // ]);
  }

  yield put(getQAList(companyId));
  yield put(resetOpenedQAs());
  yield put(closeModalLoading());
  yield put(showSaveBanner(false));

  yield put(
    showToast({
      message: qA.id ? t('documentation.qa.updated') : t('documentation.qa.created'),
    }),
  );
}

export function* deleteQASaga() {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { deleteQAId, qAList } = yield select((state: { documentation: IDocumentationState }) => state.documentation);
  const existingQa = qAList.find((item: IQAWithId) => item.id === deleteQAId);
  const qaService = new QAService(companyId, deleteQAId);
  const documentationMetaService = new DocumentationMetaService(companyId);

  yield put(closeDeleteQaModal());
  yield put(showModalLoading(t('documentation.qa.deleteQALoading')));

  if (existingQa.question) {
    yield call(qaService.deleteQA);

    yield call(documentationMetaService.addDocumentationMetaChange, [
      {
        type: EDocumentation.QA,
        id: deleteQAId,
        action: EDocumentationAction.Delete,
      },
    ]);
  }

  yield put(getQAList(companyId));
  yield take(setQAList);

  const { qAList: updatedQaList } = yield select((state: { documentation: IDocumentationState }) => state.documentation);

  if (updatedQaList.length === 0) {
    yield put(showSaveBanner(false));
  }

  yield put(closeModalLoading());
  yield put(
    showToast({
      message: t('documentation.qa.deleted'),
    }),
  );
}

export function* scrapWebsiteSaga({ payload: url }: PayloadAction<string>): SagaIterator {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { ignoreWords } = yield select((state: { documentation: IDocumentationState }) => state.documentation);
  const websiteService = new WebsiteService(companyId);

  yield put(
    showProcessingBanner({
      isVisible: true,
      title: t('documentation.scrappingProcessingTitle'),
      description: t('documentation.scrappingWebsiteDesc'),
    }),
  );
  yield call(websiteService.scrapWebsiteRequest, companyId, url, ignoreWords);
}

export function* getLinksSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  const websiteService = new WebsiteService(companyId);
  const links = yield call(websiteService.getLinks);

  yield put(setLinks(links));
}

export function* deleteAllLinksSaga(): SagaIterator {
  yield put(setDeleteAllLinksModalOpen(false));
  yield put(showModalLoading(t('documentation.website.deleteAllLinksLoading')));

  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { links } = yield select((state: { documentation: IDocumentationState }) => state.documentation);
  const changes = Object.values(links).map((item: any) => ({
    type: EDocumentation.Website,
    id: item.id,
    action: EDocumentationAction.Delete,
  }));
  const websiteService = new WebsiteService(companyId);
  const documentationMetaService = new DocumentationMetaService(companyId);

  yield call(websiteService.deleteAllLinks);
  yield call(documentationMetaService.addDocumentationMetaChange, changes);

  yield put(getCompany(companyId));
  yield put(setLinks({}));
  yield put(closeModalLoading());
  yield put(
    showToast({
      message: t('documentation.website.allLinksDeleted'),
    }),
  );
}

export function* deleteLinkSaga({ payload: id }: PayloadAction<string>): SagaIterator {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const linkService = new LinkService(companyId, id);
  const documentationMetaService = new DocumentationMetaService(companyId);

  yield call(linkService.deleteLink);
  yield call(documentationMetaService.addDocumentationMetaChange, [
    {
      type: EDocumentation.Website,
      id,
      action: EDocumentationAction.Delete,
    },
  ]);
  yield put(
    showToast({
      message: t('documentation.website.linkDeleted'),
    }),
  );
}

export function* scrapeSitemapSaga({ payload: url }: PayloadAction<string>) {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { ignoreWords } = yield select((state: { documentation: IDocumentationState }) => state.documentation);
  const websiteService = new WebsiteService(companyId);

  yield put(
    showProcessingBanner({
      isVisible: true,
      title: t('documentation.scrappingProcessingTitle'),
      description: t('documentation.scrappingWebsiteDesc'),
    }),
  );
  yield call(websiteService.scrapeSitemapRequest, companyId, url, ignoreWords);
}

export function* trainAgentSaga() {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const documentationService = new DocumentationMetaService(companyId);
  yield put(showTrainBanner(false));
  yield put(
    showProcessingBanner({
      isVisible: true,
      title: t('documentation.agentTrainingTitle'),
      description: t('documentation.agentTrainingDesc'),
    }),
  );
  yield call(documentationService.trainAgentRequest, companyId);
}

export function* initDocumentationSagas(): SagaIterator {
  yield takeEvery(getText.toString(), getTextSaga);
  yield takeEvery(saveText.toString(), saveTextSaga);
  yield takeEvery(saveQA.toString(), saveQASaga);
  yield takeEvery(getQAList.toString(), getQAListSaga);
  yield takeEvery(deleteQA.toString(), deleteQASaga);
  yield takeEvery(scrapWebsite.toString(), scrapWebsiteSaga);
  yield takeEvery(getLinks.toString(), getLinksSaga);
  yield takeEvery(deleteAllLinks.toString(), deleteAllLinksSaga);
  yield takeEvery(deleteLink.toString(), deleteLinkSaga);
  yield takeEvery(scrapeSitemap.toString(), scrapeSitemapSaga);
  yield takeEvery(trainAgent.toString(), trainAgentSaga);
}
