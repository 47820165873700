import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s3};
`;

export const Contacts = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s7};
`;

export const ContactsItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s2};

  text-decoration: none;
`;
