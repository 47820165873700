import { createSlice, PayloadAction, Reducer, Slice } from '@reduxjs/toolkit';
import { IThemeState } from './theme.types';
import { ITheme } from '@tuler/shared';

const initialState: IThemeState = {
  primaryColor: '',
  editPrimaryColor: '',
  isLoading: false,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    createTheme: (state: IThemeState, action: PayloadAction<string>) => {},
    updateTheme: (state: IThemeState, action: PayloadAction<string>) => {},
    getTheme: (state: IThemeState, action: PayloadAction<string>) => {},
    setTheme: (state: IThemeState, action: PayloadAction<ITheme>) => {
      state.primaryColor = action.payload.primaryColor;
      state.editPrimaryColor = action.payload.primaryColor;
    },
    setEditPrimaryColor: (state: IThemeState, action: PayloadAction<string>) => {
      state.editPrimaryColor = action.payload;
    },
    setThemeLoading: (state: IThemeState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { createTheme, updateTheme, getTheme, setTheme, setEditPrimaryColor, setThemeLoading } = themeSlice.actions;

export const themeReducer: Reducer<IThemeState> = themeSlice.reducer;
