import * as SC from './styles';
import { usePushQuery, useURLParams } from '../../hooks/navigation';
import { IDashboardSession } from '../../types';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import EmptyContent from '../EmptyContent/EmptyContent';
import { Typography } from '../Typography/Typography';
import Badge from '../Badge/Badge';

export interface ChatItemModel {
  companyName: string | undefined;
  companyDomain: string | undefined;
  sessionId: string;
  time: string;
}

interface ChatsProps {
  sessions: IDashboardSession[] | null;
}

function ChatList({ sessions }: ChatsProps) {
  const { t } = useTranslation();
  const pushQuery = usePushQuery();
  const { sessionId } = useURLParams();
  const hasSessions = !!sessions?.length;

  function handleOpenChat(sessionId: string) {
    pushQuery({ sessionId });
  }

  function getChats() {
    return sessions?.map(({ id, updatedAt, createdAt, lastMessageContent, userMessageUnseenCount }, i) => {
      const active = sessionId === id;
      const updatedDate = DateTime.fromISO(updatedAt);
      const updatedTime: string = updatedDate.toLocaleString(DateTime.TIME_SIMPLE);
      const createdAtTime: string = DateTime.fromISO(createdAt).toLocaleString(DateTime.DATETIME_SHORT);
      const isNotToday = !updatedDate.startOf('day').equals(DateTime.now().startOf('day'));
      const formattedDate = isNotToday ? `${updatedDate.toLocaleString(DateTime.DATE_SHORT)} ${updatedTime}` : updatedTime;
      const formattedLastMessageContent = lastMessageContent?.replace(/\[([^\]]+)\]\((http[^)]+)\)/g, '$1');

      return (
        <SC.Item key={id} $active={active} onClick={() => handleOpenChat(id)}>
          <SC.Wrapper>
            <SC.ItemHead>
              <Typography variant="h4" ellipsis>
                Started: {createdAtTime}
              </Typography>
              <Typography variant="caption">{formattedDate}</Typography>
            </SC.ItemHead>
            <SC.ItemBody>
              <Typography variant="caption" ellipsis>
                {formattedLastMessageContent}
              </Typography>
              {!!userMessageUnseenCount && <Badge variant="error" text={userMessageUnseenCount} />}
            </SC.ItemBody>
          </SC.Wrapper>
        </SC.Item>
      );
    });
  }

  return (
    <SC.Container>
      <SC.List>{hasSessions ? getChats() : <EmptyContent title={t('chats.noChats')} />}</SC.List>
    </SC.Container>
  );
}

export default ChatList;
