import { DateTime } from 'luxon';
import { IListCall } from '../../redux/call/call.types';
import EmptyContent from '../EmptyContent/EmptyContent';
import { IconName } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';

interface ICallList {
  calls: IListCall[];
  activeCallId: number | null;
  onOpen: (id: number) => void;
}

export default function CallList({ calls, activeCallId, onOpen }: ICallList) {
  const { t } = useTranslation();
  const hasCalls = calls.length > 0;

  function getCalls() {
    return calls.map(call => {
      const createDate = DateTime.fromISO(call.createdAt).toLocaleString(DateTime.DATETIME_SHORT);
      const callType = call.direction ? call.direction : t('calls.webCall');

      return (
        <SC.CallItem key={call.id} onClick={() => onOpen(call.id)} $isActive={call.id === activeCallId}>
          <SC.CallItemCol>
            <Typography variant="h4">{call.phoneNumber ?? 'N/A'}</Typography>
            <Typography variant="caption">{callType}</Typography>
          </SC.CallItemCol>

          <SC.CallItemCol>
            <Typography variant="caption">{createDate}</Typography>
            <Typography variant="caption">{call.handler.toUpperCase()}</Typography>
          </SC.CallItemCol>
        </SC.CallItem>
      );
    });
  }

  return <SC.List>{hasCalls ? getCalls() : <EmptyContent icon={IconName.Phone} title={t('calls.listEmptyTitle')} />}</SC.List>;
}
