import { call, put, select, takeEvery } from 'redux-saga/effects';
import { uploadDocument } from './documents.redux';
import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { ICompanyState } from '../company/company.types';
import { DocumentsService } from './documents.service';
import { showToast } from '../toasts/toasts.redux';
import { t } from 'i18next';
import { closeModalLoading, showModalLoading } from '../loader/loader.redux';
import { getQAList, getTagOrder, getTags } from '../qa/qa.redux';
import { setDocumentationMeta } from '../documentation/documentation.redux';
import { IDocumentationState } from '../documentation/documentation.types';

export function* uploadDocumentSaga({ payload: document }: PayloadAction<File>): SagaIterator {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const documentsService = new DocumentsService();
  const { documentationMeta }: IDocumentationState = yield select((state: { documentation: IDocumentationState }) => state.documentation);

  yield put(setDocumentationMeta({ ...documentationMeta, isProcessingDocument: true }));

  const { success, errorMessage } = yield call(documentsService.upload, companyId, document);
  const toastErrorMessage = errorMessage ? errorMessage : t('documents.uploadFailed');

  if (success) {
    yield put(getQAList(companyId));
    yield put(getTags(companyId));
    yield put(getTagOrder(companyId));
  } else {
    yield put(setDocumentationMeta({ ...documentationMeta, isProcessingDocument: false }));
    yield put(
      showToast({
        type: 'error',
        message: success ? t('documents.uploaded') : toastErrorMessage,
      }),
    );
  }
}

export function* initDocumentsSagas(): SagaIterator {
  yield takeEvery(uploadDocument.toString(), uploadDocumentSaga);
}
