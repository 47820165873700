import styled, { keyframes } from 'styled-components';

const appearDropdown = keyframes`
  0% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0)
  }
`;

export const Wrapper = styled.div<{ $isVisible: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  opacity: ${({ $isVisible }) => ($isVisible ? '1' : 0)};
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  transition: all 0.2s;
`;

export const Dropdown = styled.div<{ $position: 'left' | 'right' }>`
  padding: 0.6rem;
  width: 17rem;
  position: absolute;
  top: calc(100% + 0.8rem);
  right: ${({ $position }) => ($position === 'right' ? '0' : 'auto')};
  left: ${({ $position }) => ($position === 'left' ? '0' : 'auto')};
  display: flex;
  flex-direction: column;

  animation: ${appearDropdown} 0.2s 1;
  border-radius: 1.2rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  overflow: hidden;
  z-index: 1;
`;

export const DropdownItem = styled.div<{ $color?: string }>`
  padding: 0.7rem 0.8rem;
  display: grid;
  grid-template-columns: 1.8rem 1fr;
  align-items: center;
  gap: 1rem;

  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: ${({ $color, theme }) => ($color ? $color : theme.colors.text.secondary)};
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 0.4rem;

  &:hover {
    background: ${({ theme }) => theme.colors.gray100};
  }
`;
