import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const IconBackground = styled.div<{ color: string | undefined; $width?: number; $height?: number }>`
  height: ${props => (props.$height ? `${props.$height}rem` : 'auto')};
  width: ${props => (props.$width ? `${props.$width}rem` : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${props => (props.color ? props.color : props.theme.colors.bg.darkest)};
  border-radius: 50%;
`;
interface IconProps {
  $width?: number;
  $height?: number;
  $color?: string;
}

export const Icon = styled(ReactSVG)<IconProps>`
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    height: ${props => (props.$height ? `${props.$height}rem` : 'auto')};
    width: ${props => (props.$width ? `${props.$width}rem` : 'auto')};
    fill: ${props => props.$color || ''};
  }
`;
