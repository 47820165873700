import * as SC from './styles';

interface IAvatarProps {
  size?: 's' | 'm' | 'l';
  image?: string;
  name: string;
  customTitle?: string;
}

export default function Avatar({ image, name, size = 'm', customTitle }: IAvatarProps) {
  const [word1, word2] = name.split(' ');
  const initials = word2 ? word1[0] + word2[0] : word1[0];

  let imageSize = '3.2rem';
  let fontSize = '1.2rem';

  switch (size) {
    case 's':
      imageSize = '2rem';
      fontSize = '.8rem';
      break;
    case 'm':
      imageSize = '3.2rem';
      fontSize = '1.2rem';
      break;
    case 'l':
      imageSize = '4.8rem';
      fontSize = '2.1rem';
      break;
  }

  return (
    <SC.AvatarWrapper title={customTitle ?? name} $size={imageSize}>
      {image ? (
        <SC.AvatarImage src={image} $size={imageSize} />
      ) : (
        <SC.AvatarPlaceholder $size={imageSize} $fontSize={fontSize}>
          {initials}
        </SC.AvatarPlaceholder>
      )}
    </SC.AvatarWrapper>
  );
}
