import styled from 'styled-components';
import { WithCustomScrollbar } from '../../GlobalStyle';

export const Filter = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s4};
`;

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const List = styled.div`
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing.s2};
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  ${WithCustomScrollbar}
`;

export const Item = styled.div<{ $read: boolean }>`
  padding: ${({ theme }) => `${theme.spacing.s4} ${theme.spacing.s6} ${theme.spacing.s4} ${theme.spacing.s3}`};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s3};

  background: ${props => (!props.$read ? props.theme.colors.bg.dark : 'none')};
  border-radius: ${({ theme }) => theme.radius.xl};
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background: ${props => props.theme.colors.bg.dark};
  }
`;

export const ItemIcon = styled.div`
  position: relative;
`;

export const ItemIconSmall = styled.div<{ $bgColor: string }>`
  position: absolute;
  right: -0.2rem;
  bottom: -0.2rem;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: ${({ theme }) => theme.radius.xl};
`;

export const ItemText = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s1};
`;
