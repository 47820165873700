import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.s5};
`;

export const Table = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s7};
`;

export const TableHeader = styled.div`
  display: grid;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.s4} 0;
  gap: ${({ theme }) => theme.spacing.s5};
  grid-template-columns: repeat(12, 1fr);

  border: 0.1rem solid ${({ theme }) => theme.colors.bg.dark};
  border-bottom: 0;
  border-radius: ${({ theme }) => `${theme.radius.m} ${theme.radius.m} 0 0`};
  background: ${({ theme }) => theme.colors.bg.dark};
`;

export const TableHeaderItem = styled.div<{ $isActive: boolean; $size: number }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s1};
  grid-column: span ${({ $size }) => $size};

  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.brand.default : theme.colors.text.default)};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  transition: all 0.2s;
  cursor: pointer;
  user-select: none;
  &:first-child {
    padding-left: ${({ theme }) => theme.spacing.s7};
  }
  &:last-child {
    padding-right: ${({ theme }) => theme.spacing.s7};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.brand.default};
  }
`;

export const TableList = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.colors.bg.dark};
  border-radius: ${({ theme }) => `0 0 ${theme.radius.m} ${theme.radius.m}`};
`;

export const TableItem = styled.div`
  border-top: 0.1rem solid ${({ theme }) => theme.colors.border.default};
  display: grid;
  padding: ${({ theme }) => theme.spacing.s4} 0;
  gap: ${({ theme }) => theme.spacing.s5};
  grid-template-columns: repeat(12, 1fr);

  transition: all 0.2s;
  cursor: pointer;

  &:last-child {
    border-radius: ${({ theme }) => `0 0 ${theme.radius.m} ${theme.radius.m}`};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.bg.dark};
    p {
      color: ${({ theme }) => theme.colors.text.default} !important;
    }
  }
`;

export const TableItemCol = styled.div<{ $size: number }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s2};
  grid-column: span ${({ $size }) => $size};

  &:first-child {
    padding-left: ${({ theme }) => theme.spacing.s7};
  }
  &:last-child {
    padding-right: ${({ theme }) => theme.spacing.s7};
  }
`;
