import styled from 'styled-components';
import { HideScrollbar } from '../../GlobalStyle';

export const Container = styled.div`
  padding: 3.2rem 2.4rem 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  border-right: 0.1rem solid ${props => props.theme.colors.border.dark};
`;

export const Head = styled.div`
  padding-bottom: 1.6rem;
  margin: 0 1.3rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  border-bottom: 0.1rem solid ${props => props.theme.colors.gray200};
`;

export const HeadTitle = styled.div`
  margin-bottom: 0.4rem;
`;

export const List = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  overflow-y: auto;
  /* ${HideScrollbar} */
`;

export const Item = styled.div<{ $active: boolean }>`
  padding: 2rem 2.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: ${props => (props.$active ? props.theme.colors.gray100 : 'none')};
  cursor: pointer;
  border-radius: 10rem;

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${props => props.theme.colors.gray100};
  }
  &:hover {
    background: ${props => props.theme.colors.gray100};
  }
`;

export const ItemHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemBody = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 95%;
  gap: 1.2rem;
`;
