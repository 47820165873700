import * as SC from './styles';

export interface ITabItem {
  title: string;
  name: string;
  onClick: () => void;
}

interface ITabsProps {
  tabs: ITabItem[];
  activeTab: string;
}

export default function Tabs({ tabs, activeTab }: ITabsProps) {
  const activeTabIndex = tabs.findIndex(tab => activeTab === tab.name);

  return (
    <SC.TabsWrapper>
      <SC.Tabs $tabCount={tabs.length}>
        {tabs.map((tab, index) => (
          <SC.TabsItem
            onClick={tab.onClick}
            $isWithBorder={![index, index - 1].includes(activeTabIndex)}
            $isActive={activeTab === tab.name}
            key={tab.name}
          >
            {tab.title}
          </SC.TabsItem>
        ))}
        <SC.TabsBg $activeTab={activeTabIndex} $tabCount={tabs.length} />
      </SC.Tabs>
    </SC.TabsWrapper>
  );
}
