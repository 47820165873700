import * as SC from './styles';
import Modal from '../Modal/Modal';
import Icon, { IconName } from '../Icon/Icon';
import { useColors } from '../../hooks/theme';
import Button from '../Button/Button';
import { IErrorData } from '../../redux/error/error.types';
import { Typography } from '../Typography/Typography';

interface IErrorModalProps extends IErrorData {
  onClose: () => void;
}

export default function ErrorModal({ title, message, buttonTitle, buttonAction, onClose }: IErrorModalProps) {
  const colors = useColors();

  function handleButtonClick() {
    if (buttonAction) buttonAction();
    onClose();
  }

  return (
    <Modal onClose={onClose} withoutClose>
      <SC.ErrorBody>
        <Icon name={IconName.XCircle} width={8} height={8} color={colors.red500} />
        <Typography variant="h3">{title}</Typography>
        {message && <Typography variant="subtitle1">{message}</Typography>}
        <SC.ErrorButtonWrapper>
          <Button onClick={handleButtonClick} expand>
            {buttonTitle}
          </Button>
        </SC.ErrorButtonWrapper>
      </SC.ErrorBody>
    </Modal>
  );
}
