import styled from 'styled-components';
import { WithCustomScrollbar } from '../../GlobalStyle';

export const Details = styled.div<{ $isTop: boolean }>`
  padding: ${({ theme }) => theme.spacing.s2};
  position: absolute;
  left: ${({ theme }) => theme.spacing.s2};
  right: ${({ theme }) => theme.spacing.s2};
  ${({ $isTop }) => ($isTop ? 'top: calc(50% + 3rem);' : 'bottom:calc(50% + 2.5rem);')}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s2};

  border-radius: ${({ theme }) => theme.radius.s};
  background: ${({ theme }) => theme.colors.bg.default};
  box-shadow: ${({ theme }) => theme.shadows.light};
`;

export const DetailsInput = styled.input`
  padding: ${({ theme }) => `${theme.spacing.s2} ${theme.spacing.s3}`};
  border-radius: ${({ theme }) => theme.radius.xl};
  background: ${({ theme }) => theme.colors.bg.dark};
  border: none;
  outline: none;
`;

export const DetailsDelete = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing.s3}`};
  margin-bottom: ${({ theme }) => theme.spacing.s1};
`;

export const DetailsColorsTitle = styled.div`
  border-top: 0.1rem solid ${({ theme }) => theme.colors.border.dark};

  padding: ${({ theme }) => `${theme.spacing.s2} ${theme.spacing.s3}`};
  padding-bottom: 0;
`;

export const DetailsColors = styled.div`
  max-height: 17rem;
  overflow: auto;
  ${WithCustomScrollbar};
`;

export const DetailsColorsItem = styled.div<{ $isActive: boolean }>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing.s2} ${theme.spacing.s3}`};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s2};

  cursor: pointer;
  transition: all 0.2s;
  border-radius: ${({ theme }) => theme.radius.xl};
  background: ${({ $isActive, theme }) => ($isActive ? theme.colors.bg.darkest : 'transparent')};

  &:hover {
    background: ${({ theme }) => theme.colors.bg.dark};
  }
`;

export const DetailsColorsItemCircle = styled.div<{ $backgroundColor: string; $borderColor: string }>`
  width: 1.8rem;
  height: 1.8rem;

  background: ${({ $backgroundColor }) => $backgroundColor};
  border: 0.1rem solid ${({ $borderColor }) => $borderColor};
  border-radius: 50%;
`;
