import { convertToCamelCase, convertToSnakeCase } from '@tuler/shared';
import { auth, db } from '../../config/firebase';
import { logger } from '../../logger';
import { sendPasswordResetEmail } from 'firebase/auth';
import axiosInstance from '../../config/axios';
import { arrayRemove, doc, updateDoc } from 'firebase/firestore';
import { collection, deleteDoc, getDocs } from 'firebase/firestore';
import { ICreateUser, IGetUserResponse, IUpdateUser } from './users.types';

export async function createUserRequest({ companyId, user }: ICreateUser) {
  try {
    const res = await axiosInstance.post('/users', convertToSnakeCase({ companyId, user }));
    return res.data.uid;
  } catch (e) {
    throw new Error(`createUserRequest: ${e}`);
  }
}

export async function updateUserRequest({ companyId, user }: IUpdateUser) {
  try {
    const res = await axiosInstance.patch(`/users`, convertToSnakeCase({ companyId, user }));
    return res.data;
  } catch (e) {
    throw new Error(`updateUserRequest: ${e}`);
  }
}

export async function sendPasswordResetEmailRequest(email: string) {
  try {
    await sendPasswordResetEmail(auth, email);
    return { success: true };
  } catch (e) {
    logger.error('sendPasswordResetEmailRequest', e);
    return { success: false };
  }
}

export async function getUserRequest(companyId: string, userId: string): Promise<IGetUserResponse> {
  try {
    const res = await axiosInstance.get(`/users/${companyId}/user/${userId}`);
    return convertToCamelCase(res.data);
  } catch (error) {
    logger.error('getUserRequest', error);
    throw error;
  }
}

export async function getUsersRequest(companyId: string) {
  try {
    const res = await axiosInstance.get(`/users/${companyId}`);
    return convertToCamelCase(res.data.users);
  } catch (e) {
    logger.error('getUsersRequest', e);
  }
}

export async function deleteUsersRequest(ids: string[]) {
  try {
    const res = await axiosInstance.delete('/users', { data: { ids } });
    return res.data.success;
  } catch (e) {
    logger.error('deleteUsersRequest', e);
  }
}

export async function deleteCompanyUsersRequest(userIds: string[]) {
  try {
    const profileCollectionRef = collection(db, 'users');
    const deleteProfilePromises = userIds.map(async userId => {
      const profileDocRef = doc(profileCollectionRef, userId);
      const notificationsCollectionRef = collection(profileDocRef, 'notifications');
      const notificationsQuerySnapshot = await getDocs(notificationsCollectionRef);

      const deleteNotificationsPromises = notificationsQuerySnapshot.docs.map(async notificationDoc => {
        await deleteDoc(notificationDoc.ref);
      });

      await Promise.all(deleteNotificationsPromises);
      await deleteDoc(profileDocRef);
    });

    await Promise.all(deleteProfilePromises);
  } catch (e) {
    logger.error('deleteCompanyUsersRequest', e);
  }
}

export async function removeUserRequest(uid: string, companyId: string) {
  try {
    const configRef = doc(db, 'companies', companyId);
    return await updateDoc(configRef, { users: arrayRemove(uid) });
  } catch (e) {
    logger.error('removeUserRequest', e);
  }
}
