import { PayloadAction, Reducer, createSlice } from '@reduxjs/toolkit';
import { IAssistantTyping, INewMessage, ISessionsState } from './sessions.types';
import { IDashboardSession } from '../../types';
import { IMessage, IMessageWithId, ISessionWithId } from '@tuler/shared';
import { EDateFilter } from '../../common/types';

const initialState: ISessionsState = {
  sessions: [],
  session: {} as IDashboardSession,
  needsAttentionMessage: undefined,
  dateFilter: EDateFilter.today,
};

export const sessionsSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    setAssistantTyping: (state: ISessionsState, action: PayloadAction<boolean>) => {
      state.session.isAssistantTyping = action.payload;
    },
    setAssistantTypingStore: (state: ISessionsState, action: PayloadAction<IAssistantTyping>) => {},
    sendMessage: (state: ISessionsState, action: PayloadAction<INewMessage>) => {},
    resetUserUnseenMessages: (state: ISessionsState, action: PayloadAction<string>) => {},
    setChatSessions: (state: ISessionsState, action: PayloadAction<ISessionsState['sessions']>) => {
      state.sessions = action.payload;
    },
    setSession: (state: ISessionsState, action: PayloadAction<IDashboardSession>) => {
      state.session = action.payload;
    },
    setMessageNeedsAttention: (state: ISessionsState, action: PayloadAction<string>) => {},
    setNeedsAttentionMessageResolved: (state: ISessionsState, action: PayloadAction<string>) => {
      state.session = {
        ...state.session,
        messages: [...state.session.messages].map(message => ({
          ...message,
          needsAttention: message.id === action.payload ? false : message.needsAttention,
        })),
      };
    },
    setNeedsAttentionMessage: (state: ISessionsState, action: PayloadAction<IMessageWithId | undefined>) => {
      state.needsAttentionMessage = action.payload;
    },
    setFilterDate: (state: ISessionsState, action: PayloadAction<EDateFilter>) => {
      state.dateFilter = action.payload;
    },
    setSessionMessages: (state: ISessionsState, action: PayloadAction<{ id: string; messages: IMessageWithId[] }>) => {
      const { id, messages } = action.payload;

      if (state.session.id === id) {
        state.session['messages'] = messages;
      }
    },
    setSessionWithoutMessages: (state: ISessionsState, action: PayloadAction<ISessionWithId>) => {
      const messages = state.session.messages;

      state.session = { ...action.payload, messages };
    },
  },
});

export const {
  sendMessage,
  setChatSessions,
  setSession,
  setMessageNeedsAttention,
  setNeedsAttentionMessageResolved,
  setNeedsAttentionMessage,
  setFilterDate,
  setSessionMessages,
  setSessionWithoutMessages,
  resetUserUnseenMessages,
  setAssistantTyping,
  setAssistantTypingStore,
} = sessionsSlice.actions;

export const sessionReducer: Reducer<ISessionsState> = sessionsSlice.reducer;
