import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { IErrorData, IErrorState } from './error.types';

const initialState: IErrorState = {
  isOpen: false,
  title: '',
  message: '',
  buttonTitle: '',
  buttonAction: undefined,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showErrorModal: (state: IErrorState, action: PayloadAction<IErrorData>) => {
      return { ...state, ...action.payload, isOpen: true };
    },
    closeErrorModal: (state: IErrorState) => {
      state.isOpen = false;
    },
  },
});

export const { showErrorModal, closeErrorModal } = errorSlice.actions;

export const errorReducer: Reducer<IErrorState> = errorSlice.reducer;
