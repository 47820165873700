import { useTranslation } from 'react-i18next';
import { Typography } from '../../components/Typography/Typography';
import Button from '../Button/Button';
import * as SC from './styles';
import InfoBox from '../InfoBox/InfoBox';
import Card from '../Card/Card';
import { useState } from 'react';

interface IResetPasswordProps {
  onReset: () => void;
  isResetSent: boolean;
}

export default function ResetPassword({ onReset, isResetSent }: IResetPasswordProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  function handleReset() {
    onReset();
    setLoading(true);
  }

  return (
    <Card>
      <SC.Body>
        <SC.Col>
          <Typography variant="h4">{t('users.resetPasswordTitle')}</Typography>
          <Typography variant="body">{t('users.resetPasswordDesc')}</Typography>
        </SC.Col>
        <SC.Col>
          {!isResetSent && (
            <Button variant={loading ? 'primary' : 'secondary'} onClick={handleReset} loading={loading}>
              {t('users.resetPasswordButton')}
            </Button>
          )}
        </SC.Col>
      </SC.Body>
      {isResetSent && <InfoBox level="success" message={t('users.resetPasswordSuccessBox')} />}
    </Card>
  );
}
