import styled from 'styled-components';
import { HideScrollbar } from '../../GlobalStyle';

export const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: ${({ theme }) => theme.spacing.s4};
`;

export const Body = styled.div`
  padding: ${({ theme }) => theme.spacing.s7};
  max-height: 100%;
  height: fit-content;
  overflow: auto;

  border-radius: ${({ theme }) => theme.radius.l};
  border: 0.1rem solid ${({ theme }) => theme.colors.border.dark};
  ${HideScrollbar}
`;

export const CompanyDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s4};
`;

export const UserForm = styled.div``;

export const UserFormTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.s3};
  margin-bottom: ${({ theme }) => theme.spacing.s6};
`;
