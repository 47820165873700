import { useDispatch, useSelector } from 'react-redux';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import { IThemeState } from '../../redux/theme/theme.types';
import { getTheme, setEditPrimaryColor, updateTheme } from '../../redux/theme/theme.redux';
import { Typography } from '../../components/Typography/Typography';
import ColorPicker from '../ColorPicker/ColorPicker';
import Button from '../Button/Button';
import { useEffect } from 'react';
import { ICompanyState } from '../../redux/company/company.types';
import { useURLParams } from '../../hooks/navigation';
import Card from '../Card/Card';

export default function CompanyTheme() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { companyId: urlCompanyId } = useURLParams();
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const { primaryColor, editPrimaryColor, isLoading } = useSelector((state: { theme: IThemeState }) => state.theme);
  const showSaveButton = primaryColor !== editPrimaryColor;

  function handleSaveTheme() {
    dispatch(updateTheme(urlCompanyId || companyId));
  }

  function handleChangePrimaryColor(data: string) {
    dispatch(setEditPrimaryColor(data));
  }

  useEffect(() => {
    if (companyId) {
      dispatch(getTheme(companyId));
    }
  }, [companyId]);

  return (
    <Card>
      <SC.ThemeItem>
        <Typography variant="h4" bold>
          {t('theme.primaryColorTitle')}
        </Typography>
        <Typography variant="body">{t('theme.primaryColorDesc')}</Typography>
        <SC.ThemeItemRow>
          <ColorPicker value={editPrimaryColor} onChange={handleChangePrimaryColor} />
          {showSaveButton && (
            <Button onClick={handleSaveTheme} loading={isLoading}>
              {t('theme.save')}
            </Button>
          )}
        </SC.ThemeItemRow>
      </SC.ThemeItem>
    </Card>
  );
}
