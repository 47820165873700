import * as SC from './styles';
import Icon, { IconName } from '../Icon/Icon';
import { useColors } from '../../hooks/theme';
import { Typography } from '../Typography/Typography';

type TFlatButtonVariant = 'secondary' | 'brand';

interface FlatButtonProps {
  text: string;
  icon?: IconName;
  onClick?: () => void;
  variant?: TFlatButtonVariant;
}

export default function FlatButton({ text, icon, variant = 'brand', onClick }: FlatButtonProps) {
  const colors = useColors();
  let iconColor = '';

  switch (variant) {
    case 'secondary':
      iconColor = colors.text.secondary;
      break;
    case 'brand':
      iconColor = colors.brand.default;
      break;
  }

  function handleClick() {
    onClick && onClick();
  }

  return (
    <SC.Button onClick={handleClick}>
      {!!icon && <Icon name={icon} height={2} width={2} color={iconColor} />}
      <Typography variant="placeholder" color={variant}>
        {text}
      </Typography>
    </SC.Button>
  );
}
