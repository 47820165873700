import { css, keyframes, styled } from 'styled-components';

const appearDeleteButton = keyframes`
  0% {
    transform: scale(.5);
    bottom: -27px;
  }
  
  100% {
    transform: scale(1);
    bottom: -22px;
  }
`;

const appearUpload = keyframes`
  0% {
    transform: scale(.5) translateY(10px);
  }
  
  100% {
    transform: scale(1) translateY(0);
  }
`;

const appearUploadedImage = keyframes`
  0% {
    transform: scale(.8);
  }
  
  100% {
    transform: scale(1);
  }
`;

export const Wrapper = styled.label<{ $isEmpty: boolean }>`
  position: relative;
  width: 8.5rem;
  height: 8.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${props => props.theme.colors.gray100};
  border-radius: 1.2rem;
  cursor: ${({ $isEmpty }) => ($isEmpty ? 'pointer' : 'auto')};
`;

export const UploadInput = styled.input`
  display: none;
`;

export const Uploaded = styled.div`
  position: relative;
  width: 8.5rem;
  height: 8.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Image = styled.img`
  width: 8.5rem;
  height: 8.5rem;

  object-fit: cover;
  animation: ${appearUploadedImage} 0.2s ease-in-out 1;
  border-radius: 1.2rem;
`;

export const Actions = styled.div`
  position: absolute;
  bottom: -1.1rem;
  left: calc(50% - 1.4rem);
`;

const actionsBaseStyles = css`
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: ${({ theme }) => theme.colors.white};
  border: 0.1rem solid ${props => props.theme.colors.gray200};

  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.colors.gray100};
  }
  &:active {
    transform: scale(0.95);
  }
`;

export const ActionsButton = styled.button`
  ${actionsBaseStyles}
`;

export const ActionsLabel = styled.label`
  ${actionsBaseStyles}
`;
