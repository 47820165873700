import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as SC from './styles';
import Icon, { IconName } from '../Icon/Icon';
import { usePush, useURLParams } from '../../hooks/navigation';
import { IRoute, ROUTES, TPageName } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { EFeatureFlag, IAuthUserState } from '../../redux/authUser/authUser.types';
import MiniMenu, { IMiniMenuItem, IMiniMenuProps } from '../MiniMenu/MiniMenu';
import { useEffect, useState } from 'react';
import { logout } from '../../redux/authUser/authUser.redux';
import { UserRole } from '../../redux/users/users.types';
import { ISidebarState } from '../../redux/sidebar/sidebar.types';
import { getCompanies, getCompany } from '../../redux/company/company.redux';
import { ICompanyState } from '../../redux/company/company.types';
import { toggleMenu } from '../../redux/sidebar/sidebar.redux';
import { useColors } from '../../hooks/theme';
import { Typography } from '../../components/Typography/Typography';
import { INotificationsState } from '../../redux/notifications/notifications.types';
import { usePermission } from '../../hooks/usePermission';
import useNotifications from '../../hooks/useNotifications';

type TMenuItemGroup = 'user' | 'company';

interface IMenuItem {
  group: TMenuItemGroup;
  name: TPageName;
  icon: IconName;
  featureFlags?: EFeatureFlag;
}

const menuItems: IMenuItem[] = [
  {
    group: 'user',
    name: 'notifications',
    icon: IconName.Bell,
  },
  {
    group: 'company',
    name: 'dashboard',
    icon: IconName.ChartBar,
  },
  {
    featureFlags: EFeatureFlag.AGENT_ENABLED,
    group: 'company',
    name: 'agents',
    icon: IconName.Profile2User,
  },
  {
    featureFlags: EFeatureFlag.CHAT_ENABLED,
    group: 'company',
    name: 'chats',
    icon: IconName.Messages,
  },
  {
    group: 'company',
    name: 'calls',
    icon: IconName.Phone,
  },
  {
    group: 'company',
    name: 'qa',
    icon: IconName.QA,
  },
  {
    featureFlags: EFeatureFlag.CUSTOMERS_ENABLED,
    group: 'company',
    name: 'customers',
    icon: IconName.UserGroup,
  },
  {
    group: 'company',
    name: 'companySettings',
    icon: IconName.Cog,
  },
  {
    group: 'company',
    name: 'integrations',
    icon: IconName.Integrations,
  },
];

function Sidebar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const pushUrl = usePush();
  const colors = useColors();
  const { t } = useTranslation();
  const currentPage = location.pathname.split('/')[1];
  const { user, featureFlags } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const { openedMenus } = useSelector((state: { sidebar: ISidebarState }) => state.sidebar);
  const { companies, company: currentCompany } = useSelector((state: { company: ICompanyState }) => state.company);
  const { unreadNotificationsCount, typeFilter, dateFilter } = useSelector(
    (state: { notifications: INotificationsState }) => state.notifications,
  );
  const { companyId: urlCompanyId } = useURLParams();
  const [searchValue, setSearchValue] = useState('');
  const { isSuperuser } = usePermission();
  const miniMenuItems: IMiniMenuProps[] = user.uid ? [getUserMiniMenu(), ...getCompaniesMiniMenu()] : [];
  const isNotificationsPage = location.pathname === ROUTES.notifications.path;

  useNotifications(user.uid, isNotificationsPage, typeFilter, dateFilter);

  function getMenuItems(group: TMenuItemGroup, companyId?: string): IMiniMenuItem[] {
    return menuItems
      .filter(
        item =>
          item.group === group &&
          ROUTES[item.name].permissions.includes(user.role) &&
          (item.featureFlags ? featureFlags[item.featureFlags] : true),
      )
      .map(item => {
        return {
          title: t(`sidebar.${item.name}MenuItem`),
          icon: item.icon,
          isActive: isLinkActive(ROUTES[item.name], group, companyId),
          onClick: () => handleNavigate(ROUTES[item.name], companyId),
          count: item.name === 'notifications' ? unreadNotificationsCount : undefined,
        };
      });
  }

  function getUserMiniMenu(): IMiniMenuProps {
    return {
      image: user.photoUrl,
      title: user.displayName,
      isOpen: true,
      isOpenable: false,
      hasSearch: true,
      searchValue,
      onSearch: setSearchValue,
      searchPlaceholder: t('sidebar.searchPlaceholder'),
      items: getMenuItems('user'),
      iconMenu: {
        position: 'right',
        buttonIcon: IconName.DotsHorizontal,
        menuItems: [
          // {
          //   iconName: IconName.Moon,
          //   title: t('sidebar.nightModeSubMenuItem'),
          //   onClick: () => {},
          // },
          {
            iconName: IconName.Profile2User,
            title: t('sidebar.profileSubMenuItem'),
            onClick: () => handleNavigate(ROUTES.userProfile),
          },
          {
            iconName: IconName.Logout,
            title: t('sidebar.logoutSubMenuItem'),
            onClick: () => handleLogout(),
          },
        ],
      },
    };
  }

  function getCompaniesMiniMenu(): IMiniMenuProps[] {
    if (isSuperuser) {
      return companies.map(company => ({
        image: company.image,
        title: company.name,
        isOpen: openedMenus.includes(company.id),
        onOpen: () => openMenu(company.id),
        isOpenable: true,
        hasSearch: false,
        isLast: false,
        items: getMenuItems('company', company.id),
      }));
    } else {
      return [
        {
          image: currentCompany.image,
          title: currentCompany.name,
          isOpen: openedMenus.includes(currentCompany.id),
          onOpen: () => openMenu(currentCompany.id),
          isOpenable: true,
          hasSearch: false,
          isLast: true,
          items: getMenuItems('company'),
        },
      ];
    }
  }

  function handleOpenCreateCompany() {
    pushUrl('/create-company');
  }

  function openMenu(companyId: string) {
    dispatch(toggleMenu(companyId));
  }

  function handleLogout() {
    dispatch(logout());
  }

  function handleNavigate(route: IRoute, id?: string) {
    if (id) {
      pushUrl(`${route.path}/?companyId=${id}`);
    } else {
      pushUrl(route.path);
    }
  }

  function isLinkActive(route: IRoute, group: TMenuItemGroup, companyId?: string) {
    const isCurrentPage = currentPage === route.path.split('/')[1];
    if (group === 'company' && isSuperuser) {
      return isCurrentPage && currentCompany.id === companyId;
    } else {
      return isCurrentPage;
    }
  }

  useEffect(() => {
    if (!companies?.length && isSuperuser) {
      dispatch(getCompanies());
    }
  }, [user]);

  useEffect(() => {
    if (urlCompanyId && currentCompany.id !== urlCompanyId) {
      dispatch(getCompany(urlCompanyId));
    }
  }, [urlCompanyId]);

  useEffect(() => {
    if (!isSuperuser && currentCompany.id !== user.companyId && user.companyId) {
      dispatch(getCompany(user.companyId));
    }
  }, [user.companyId]);

  return (
    <SC.Sidebar>
      {miniMenuItems.map(item => (
        <MiniMenu key={item.title} {...item} />
      ))}
      {isSuperuser && (
        <SC.SidebarButton onClick={handleOpenCreateCompany}>
          <Icon name={IconName.Plus} color={colors.gray600} />
          <Typography variant="placeholder">{t('sidebar.createCompanyMenuItem')}</Typography>
        </SC.SidebarButton>
      )}
    </SC.Sidebar>
  );
}

export default Sidebar;
