import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { INavigationState, QueryParams } from './navigation.types';

const initialState: INavigationState = {};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    pushAction: (state: INavigationState, action: PayloadAction<string>) => {},
    replaceAction: (state: INavigationState, action: PayloadAction<string>) => {},
    goBackAction: (state: INavigationState, action: PayloadAction<undefined>) => {},
    pushQuery: (state: INavigationState, action: PayloadAction<QueryParams>) => {},
  },
});

export const { pushAction, replaceAction, goBackAction, pushQuery } = navigationSlice.actions;

export const navigationReducer: Reducer<INavigationState> = navigationSlice.reducer;
