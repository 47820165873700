import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Schema, z } from 'zod';
import { useDispatch } from 'react-redux';
import { addInvalidSaveForm, deleteInvalidSaveForm, showSaveBanner } from '../redux/banner/banner.redux';
import { useEffect, useRef } from 'react';
import { formEmitter } from '../config/formEmitter';

export const useConnectedForm = ({
  id,
  onSubmit,
  validationSchema,
  defaultValues = {},
  ...rest
}: {
  id?: string;
  onSubmit: (data: z.infer<typeof validationSchema>) => void;
  validationSchema: Schema;
  defaultValues?: Record<string, any>;
  [key: string]: any;
}): UseFormReturn<z.infer<typeof validationSchema>> => {
  const dispatch = useDispatch();

  const formMethods = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
    defaultValues,
    ...rest,
  });
  const { formState, handleSubmit, reset } = formMethods;
  const { isDirty, isValid } = formState;
  const saveSubscription: any = useRef(null);
  const discardSubscription: any = useRef(null);
  const saveEvent = id ? `save-${id}` : 'save';
  const discardEvent = id ? `discard-${id}` : 'discard';

  function subscribeEvents() {
    saveSubscription.current = formEmitter.on(saveEvent, handleSubmit(onSubmit));
    discardSubscription.current = formEmitter.on(discardEvent, reset);
  }

  function unsubscribeEvents() {
    if (saveSubscription.current && discardSubscription.current) {
      saveSubscription.current.off(saveEvent);
      discardSubscription.current.off(discardEvent);
      formEmitter.off(saveEvent, saveSubscription.current);
      formEmitter.off(discardEvent, discardSubscription.current);
    }
  }

  function showCloseSaveBanner() {
    const events = formEmitter.list();
    const existsSaveEvents = events.some(event => event.startsWith('save') || event.startsWith('discard'));
    if (existsSaveEvents) {
      dispatch(showSaveBanner(true));
    } else {
      dispatch(showSaveBanner(false));
    }
  }

  useEffect(() => {
    if (isDirty) {
      subscribeEvents();
    } else {
      unsubscribeEvents();
    }
    showCloseSaveBanner();
    return () => {
      unsubscribeEvents();
      showCloseSaveBanner();
    };
  }, [isDirty]);

  useEffect(() => {
    if (!isDirty) return;

    if (isValid) {
      dispatch(deleteInvalidSaveForm(saveEvent));
    } else {
      dispatch(addInvalidSaveForm(saveEvent));
    }

    return () => {
      dispatch(deleteInvalidSaveForm(saveEvent));
    };
  }, [isValid]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      dispatch(showSaveBanner(false));
    }
  }, [formState.isSubmitSuccessful]);

  return formMethods as UseFormReturn<z.infer<typeof validationSchema>>;
};
