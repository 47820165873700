import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { setSort, getCustomer, getCustomers, setCustomer, setCustomers, searchCustomers } from './customers.redux';
import { logger } from '../../logger';
import { PayloadAction } from '@reduxjs/toolkit';
import { CustomerService, CustomersService } from './customers.service';
import { ICustomerWithId, ICustomersState } from './customers.types';
import { ICompanyWithId, ICompanyState } from '../company/company.types';

export function* getCustomerSaga({
  payload: { customerId, companyId },
}: PayloadAction<{ customerId: string; companyId: string }>): SagaIterator {
  try {
    const customerService = new CustomerService(companyId, customerId);
    const customer = yield call(customerService.getCustomer);

    yield put(setCustomer(customer));
  } catch (e) {
    logger.error('getCustomerSaga', e);
  }
}

export function* getCustomersSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  try {
    const customerService = new CustomersService(companyId);
    const customers = yield call(customerService.getCustomers);
    yield put(setCustomers(customers));
  } catch (e) {
    logger.error('getCustomersSaga', e);
  }
}

export function* searchCustomersSaga(): SagaIterator {
  const { id: companyId }: ICompanyWithId = yield select((state: { company: ICompanyState }) => state.company.company);
  const { searchValue }: ICustomersState = yield select((state: { customers: ICustomersState }) => state.customers);
  try {
    const customerService = new CustomersService(companyId);
    let customers: ICustomerWithId[] = [];

    if (searchValue) {
      customers = yield call(customerService.searchCustomers, searchValue);
    } else {
      customers = yield call(customerService.getCustomers);
    }

    yield put(setCustomers(customers));
  } catch (e) {
    logger.error('filterCustomersSaga', e);
  }
}

export function* sortCustomersSaga(): SagaIterator {
  const { id: companyId }: ICompanyWithId = yield select((state: { company: ICompanyState }) => state.company.company);
  const { sortBy, isDescendingOrder }: ICustomersState = yield select((state: { customers: ICustomersState }) => state.customers);
  try {
    const customerService = new CustomersService(companyId);
    const customers = yield call(customerService.getCustomers, sortBy, isDescendingOrder);
    yield put(setCustomers(customers));
  } catch (e) {
    logger.error('filterCustomersSaga', e);
  }
}

export function* initCustomersSagas(): SagaIterator {
  yield takeEvery(getCustomer.toString(), getCustomerSaga);
  yield takeEvery(getCustomers.toString(), getCustomersSaga);
  yield takeEvery(setSort.toString(), sortCustomersSaga);
  yield takeEvery(searchCustomers.toString(), searchCustomersSaga);
}
