import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import { ICompanyState } from '../../redux/company/company.types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteChat, getChats, setDeleteChat } from '../../redux/telegram/telegram.redux';
import { ITelegramChatWithId, ITelegramState } from '../../redux/telegram/telegram.types';
import PromptModal from '../../components/PromptModal/PromptModal';
import { usePush, useURLParams } from '../../hooks/navigation';
import { Typography } from '../../components/Typography/Typography';
import IconButton from '../../components/IconButton/IconButton';
import { IconName } from '../../components/Icon/Icon';
import { ROUTES } from '../../constants/routes';
import Card from '../../components/Card/Card';

export default function TelegramChatsPage() {
  const { t } = useTranslation();
  const { chatId } = useURLParams();
  const pushUrl = usePush();
  const dispatch = useDispatch();
  const { company } = useSelector((state: { company: ICompanyState }) => state.company);
  const { deleteChatData } = useSelector((state: { telegram: ITelegramState }) => state.telegram);
  const { chats } = useSelector((state: { telegram: ITelegramState }) => state.telegram);
  const hasChats = !!chats.length;
  const showChatForm = !!chatId;

  function handleCloseTelegramChatDelete() {
    dispatch(setDeleteChat(undefined));
  }

  function handleDeleteTelegramChat() {
    dispatch(deleteChat());
  }

  function handleChatDelete(id: number, name: string) {
    dispatch(setDeleteChat({ id, name }));
  }

  function handleOpenChat(id?: number) {
    pushUrl(`${ROUTES.telegramChatEdit.path}?chatId=${id ?? 0}`);
  }

  function getChatItem(chat: ITelegramChatWithId) {
    return (
      <SC.ChatItem key={chat.id} onClick={() => handleOpenChat(chat.id)}>
        <Typography variant="caption" color="secondary">
          {chat.chatName}
        </Typography>
        <Typography variant="caption" color="secondary">
          {chat.chatId}
        </Typography>
        <Typography variant="caption" color="secondary">
          {chat.chatAdminId ?? 'N/A'}
        </Typography>
        <IconButton iconName={IconName.Trash} onClick={() => handleChatDelete(chat.id, chat.chatName)} stopPropagation />
      </SC.ChatItem>
    );
  }

  useEffect(() => {
    if (!company.id) return;

    dispatch(getChats(company.id));
  }, [company.id]);

  return (
    <Layout>
      <PageContent
        hasBack
        title={t('telegramChats.title')}
        hasData={hasChats}
        emptyIcon={IconName.Send2}
        emptyTitle={t('telegramChats.emptyTitle')}
        emptyDescription={t('telegramChats.emptyDescription')}
        emptyButtonText={t('telegramChats.emptyButtonText')}
        onClickAction={() => handleOpenChat()}
        actionButtonText={t('telegramChats.addChatButton')}
      >
        <Card noPadding>
          <SC.ListHeader>
            <Typography variant="caption" color="default">
              {t('telegramChats.listHeaderChatName')}
            </Typography>
            <Typography variant="caption" color="default">
              {t('telegramChats.listHeaderChatId')}
            </Typography>
            <Typography variant="caption" color="default">
              {t('telegramChats.listHeaderAdminChat')}
            </Typography>
          </SC.ListHeader>
          <div>{chats.map(item => getChatItem(item))}</div>
        </Card>
      </PageContent>
      {deleteChatData && (
        <PromptModal
          variant="danger"
          title={t('telegramChats.deleteChatTitle')}
          questionKey="telegram.deleteChatQuestion"
          name={deleteChatData.name}
          cancelText={t('telegramChats.deleteChatCancel')}
          submitText={t('telegramChats.deleteChatSubmit')}
          onClose={handleCloseTelegramChatDelete}
          onSubmit={handleDeleteTelegramChat}
        />
      )}
    </Layout>
  );
}
