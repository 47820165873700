import { useDispatch, useSelector } from 'react-redux';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import { ECustomerStatus, ICustomerWithId, ICustomersState, TCustomersSort } from '../../redux/customers/customers.types';
import { ICompanyState } from '../../redux/company/company.types';
import { useEffect } from 'react';
import { getCustomers, searchCustomers, setSort } from '../../redux/customers/customers.redux';
import Layout from '../../components/Layout/Layout';
import { IAuthUserState, UserRole } from '../../redux/authUser/authUser.types';
import { usePush } from '../../hooks/navigation';
import Icon, { IconName } from '../../components/Icon/Icon';
import Badge from '../../components/Badge/Badge';
import Avatar from '../../components/Avatar/Avatar';
import PageContent from '../../components/PageContent/PageContent';
import SearchInput from '../../components/SearchInput/SearchInput';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { Typography } from '../../components/Typography/Typography';

interface IHeaderItem {
  name: TCustomersSort;
  title: string;
  size: number;
}

export default function CustomersPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pushUrl = usePush();
  const { customers, sortBy, isDescendingOrder, searchValue } = useSelector((state: { customers: ICustomersState }) => state.customers);
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const { user } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const hasData = !!customers.length;

  const headerItems: IHeaderItem[] = [
    {
      name: 'firstName',
      title: t('customers.headerName'),
      size: 3,
    },
    {
      name: 'email',
      title: t('customers.headerEmail'),
      size: 4,
    },
    {
      name: 'phone',
      title: t('customers.headerPhone'),
      size: 3,
    },
    {
      name: 'status',
      title: t('customers.headerStatus'),
      size: 2,
    },
  ];

  function handleSort(name: TCustomersSort) {
    dispatch(setSort(name));
  }

  function handleSearch(value: string) {
    dispatch(searchCustomers(value));
  }

  function openCustomer(id?: string) {
    if (user.role === UserRole.superuser) {
      pushUrl(`/customer/?customerId=${id}&companyId=${companyId}`);
    } else {
      pushUrl(`/customer/?customerId=${id}`);
    }
  }

  useEffect(() => {
    if (companyId) {
      dispatch(getCustomers(companyId));
    }
  }, [companyId]);

  function getIcon(name: TCustomersSort) {
    if (name !== sortBy) {
      return <Icon name={IconName.Sort} />;
    } else if (isDescendingOrder) {
      return <Icon name={IconName.SortDown} />;
    } else {
      return <Icon name={IconName.SortUp} />;
    }
  }

  function getItemBadge(status: ECustomerStatus) {
    const text = t(`customers.${status}Status`);

    switch (status) {
      case ECustomerStatus.new:
        return <Badge text={text} variant="brand" />;
      case ECustomerStatus.contacted:
        return <Badge text={text} variant="warning" />;
      case ECustomerStatus.converted:
        return <Badge text={text} variant="success" />;
      case ECustomerStatus.lost:
        return <Badge text={text} variant="error" />;
    }
  }

  function getCustomer(customer: ICustomerWithId) {
    const { id, status, firstName, lastName, phone, email } = customer;
    const fullName = `${lastName}, ${firstName}`;

    return (
      <SC.TableItem key={id} onClick={() => openCustomer(id)}>
        <SC.TableItemCol $size={3}>
          <Avatar name={fullName} />
          <Typography variant="caption">{fullName}</Typography>
        </SC.TableItemCol>
        <SC.TableItemCol $size={4}>
          <Typography variant="caption">{email}</Typography>
        </SC.TableItemCol>
        <SC.TableItemCol $size={3}>
          <Typography variant="caption">{phone}</Typography>
        </SC.TableItemCol>
        <SC.TableItemCol $size={2}>{getItemBadge(status)}</SC.TableItemCol>
      </SC.TableItem>
    );
  }

  return (
    <SC.Wrapper>
      <Layout>
        <PageContent>
          <SC.Wrapper>
            <SC.Header>
              <Typography variant="h1">{t('customers.title')}</Typography>
              <SearchInput onSearch={handleSearch} placeholder={t('customers.searchPlaceholder')} initialValue={searchValue} />
            </SC.Header>
            {hasData ? (
              <SC.Table>
                <SC.TableHeader>
                  {headerItems.map(item => (
                    <SC.TableHeaderItem
                      key={item.name}
                      $isActive={sortBy === item.name}
                      onClick={() => handleSort(item.name)}
                      $size={item.size}
                    >
                      {item.title}
                      {getIcon(item.name)}
                    </SC.TableHeaderItem>
                  ))}
                </SC.TableHeader>
                <SC.TableList>{customers.map(item => getCustomer(item))}</SC.TableList>
              </SC.Table>
            ) : (
              <EmptyContent icon={IconName.UserGroup} title={t('customers.emptyTitle')} description={t('customers.emptyDesc')} />
            )}
          </SC.Wrapper>
        </PageContent>
      </Layout>
    </SC.Wrapper>
  );
}
