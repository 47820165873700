import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  background-image: url('/images/login-frame.svg'), ${({ theme }) => theme.gradients.light300};
  backdrop-filter: blur(20px);
  background-repeat: repeat;
`;

export const Login = styled.div`
  width: 37.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .firebaseui-container {
    width: 100%;
    max-width: 100%;
    padding: 3.2rem 2.4rem 2.4rem;
    background: ${props => props.theme.colors.white};
    box-shadow: ${({ theme }) => theme.shadows.sm};
    border-radius: 0.8rem;
  }
  .firebaseui-card-header {
    padding: 0;
  }
  .firebaseui-title {
    margin-bottom: 1rem;

    text-align: center;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 150%;
    color: ${props => props.theme.colors.gray900};
  }
  .firebaseui-text {
    margin-bottom: 1rem;

    font-weight: 400;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.gray600};
  }
  .firebaseui-card-content {
    margin-top: 3rem;
    padding: 0;
  }
  .firebaseui-card-actions {
    padding: 0;
  }
  .firebaseui-textfield.mdl-textfield .firebaseui-label::after {
    background-color: ${props => props.theme.colors.primary500};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 3rem ${props => props.theme.colors.white} inset !important;
    -webkit-text-fill-color: 'transparent' !important;
  }

  .firebaseui-form-actions {
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }
  .firebaseui-button {
    margin: 0;
  }
  .firebaseui-id-submit {
    margin-left: 0;
    flex-grow: 1;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 150%;

    background: ${props => props.theme.colors.primary500};
    border-radius: 0.8rem;
    &:hover {
      background: ${props => props.theme.colors.primary500};
      filter: brightness(110%);
    }
    &:active {
      transform: scale(0.98);
    }
  }
`;

export const LoginLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 1.3rem;
  margin-bottom: 5rem;
`;
