import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { IActiveUser, ICreateUser, IUpdateUser, IUsersState } from './users.types';

const initialState: IUsersState = {
  activeUserId: '',
  users: [],
  deleteUserModalOpen: false,
  isUserEditLoading: false,
  isLoadingUsers: false,
  activeUser: undefined,
  resetPasswordSentUsers: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers: () => {},
    userCreated: () => {},
    createUser: (state: IUsersState, action: PayloadAction<ICreateUser>) => {},
    updateUser: (state: IUsersState, action: PayloadAction<IUpdateUser>) => {},
    deleteUser: (state: IUsersState) => {
      state.users = [...state.users].filter(user => user.uid !== state.activeUserId);
    },
    setIsLoadingUsers: (state: IUsersState, action: PayloadAction<boolean>) => {
      state.isLoadingUsers = action.payload;
    },
    setUsers: (state: IUsersState, action: PayloadAction<IUsersState['users']>) => {
      state.users = action.payload || [];
    },
    setActiveUserId: (state: IUsersState, action: PayloadAction<string>) => {
      state.activeUserId = action.payload;
    },
    showRemoveConfirmModal: (state: IUsersState, action: PayloadAction<boolean>) => {
      state.deleteUserModalOpen = action.payload;
    },
    setIsUserEditLoading: (state: IUsersState, action: PayloadAction<boolean>) => {
      state.isUserEditLoading = action.payload;
    },
    getActiveUser: (state: IUsersState, action: PayloadAction<string>) => {},
    setActiveUser: (state: IUsersState, action: PayloadAction<IActiveUser>) => {
      state.activeUser = action.payload;
    },
    resetActiveUser: (state: IUsersState) => {
      state.activeUser = undefined;
    },
    sendPasswordResetEmail: (state: IUsersState, action: PayloadAction<string>) => {},
    setResetPasswordEmail: (state: IUsersState, action: PayloadAction<string>) => {
      state.resetPasswordSentUsers = [...state.resetPasswordSentUsers, action.payload];
    },
  },
});

export const {
  getUsers,
  setUsers,
  userCreated,
  createUser,
  updateUser,
  setActiveUserId,
  showRemoveConfirmModal,
  deleteUser,
  setIsUserEditLoading,
  setIsLoadingUsers,
  getActiveUser,
  setActiveUser,
  resetActiveUser,
  sendPasswordResetEmail,
  setResetPasswordEmail,
} = usersSlice.actions;

export const usersReducer: Reducer<IUsersState> = usersSlice.reducer;
