import { ITheme, convertToCamelCase, convertToSnakeCase } from '@tuler/shared';
import { db } from '../../config/firebase';
import { logger } from '../../logger';
import { updateDoc, setDoc, doc, getDoc, deleteDoc } from 'firebase/firestore';

export async function getThemeRequest(id: string) {
  try {
    const themesRef = doc(db, 'themes', id);
    const docSnap = await getDoc(themesRef);

    if (docSnap.exists()) {
      return convertToCamelCase(docSnap.data());
    }
  } catch (e) {
    logger.error('getThemeRequest', e);
  }
}

export async function createThemeRequest(id: string, { primaryColor }: ITheme) {
  try {
    await setDoc(doc(db, 'themes', id), convertToSnakeCase({ primaryColor }));
    return id;
  } catch (e) {
    logger.error('createThemeRequest', e);
  }
}

export async function updateThemeRequest(id: string, { primaryColor }: ITheme) {
  try {
    const themeRef = doc(db, 'themes', id);

    await updateDoc(themeRef, convertToSnakeCase({ primaryColor }));
  } catch (e) {
    logger.error('updateThemeRequest', e);
  }
}

export async function deleteThemeRequest(id: string) {
  try {
    const themeRef = doc(db, 'themes', id);

    await deleteDoc(themeRef);
  } catch (e) {
    logger.error('deleteThemeRequest', e);
  }
}
