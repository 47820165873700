import styled from 'styled-components';
import { TSearchBorder } from './SearchInput';

export const Container = styled.label<{ $border: TSearchBorder; $expand: boolean }>`
  width: ${({ $expand }) => ($expand ? '100%' : '25rem')};
  padding: 0.6rem 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  background: ${props => props.theme.colors.white};

  border-radius: ${({ theme }) => theme.radius.xl};
  ${({ $border, theme }) => {
    switch ($border) {
      case 'all':
        return `border: .1rem solid ${theme.colors.gray300}`;
      case 'bottom':
        return `border-bottom: .1rem solid ${theme.colors.gray300};
                border-radius: 0;`;
    }
  }}
`;

export const Input = styled.input`
  width: 100%;
  height: 1.8rem;

  font-weight: 500;
  font-size: 1.2rem;
  background: none;
  outline: none;
  border: none;
  &::placeholder {
    color: ${props => props.theme.colors.gray500};
    outline: none;
  }
`;
