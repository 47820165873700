import { ReactNode, useEffect } from 'react';
import Loader, { LoaderEnum } from '../Loader/Loader';
import Sidebar from '../Sidebar/Sidebar';
import * as SC from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { ILoaderState } from '../../redux/loader/loader.types';
import ErrorModal from '../ErrorModal/ErrorModal';
import { IErrorState } from '../../redux/error/error.types';
import { closeErrorModal } from '../../redux/error/error.redux';
import { IAuthUserState } from '../../redux/authUser/authUser.types';
import ConfirmationBanner from '../ConfirmationBanner/ConfirmationBanner';
import { IBannerState } from '../../redux/banner/banner.types';
import PromptModal from '../PromptModal/PromptModal';
import { useTranslation } from 'react-i18next';
import { leavePage, showDiscardModal, showProcessingBanner, showTrainBanner } from '../../redux/banner/banner.redux';
import { IDocumentationState } from '../../redux/documentation/documentation.types';
import { trainAgent } from '../../redux/documentation/documentation.redux';
import { formEmitter } from '../../config/formEmitter';
import { TinyEmitter } from 'tiny-emitter';

interface IProps {
  children: ReactNode;
  withPadding?: boolean;
}

export default function Layout({ children, withPadding = false }: IProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isGlobalLoading, isModalLoading, message } = useSelector((state: { loader: ILoaderState }) => state.loader);
  const { user } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const error = useSelector((state: { error: IErrorState }) => state.error);
  const { isDiscardModalVisible, processingBanner, isSaveBannerVisible, isTrainBannerVisible } = useSelector(
    (state: { banner: IBannerState }) => state.banner,
  );
  const isBannerVisible = isDiscardModalVisible || processingBanner.isVisible || isSaveBannerVisible || isTrainBannerVisible;
  const {
    documentationMeta: { isAgentTraining, isScrapingWebsite, changes },
  } = useSelector((state: { documentation: IDocumentationState }) => state.documentation);
  const currentPage = location.pathname.split('/')[1];

  function handleCloseErrorModal() {
    dispatch(closeErrorModal());
  }

  function handleCloseDiscardModal() {
    dispatch(showDiscardModal(false));
  }

  function handleLeavePage() {
    dispatch(leavePage());
  }

  function handleTrainAgent() {
    dispatch(trainAgent());
  }

  useEffect(() => {
    document.title = `Tuler.ai - ${t(`layout.pageTitle-${currentPage}`)}`;
  }, [currentPage]);

  useEffect(() => {
    let trainSubscription: TinyEmitter;

    if (changes.length) {
      trainSubscription = formEmitter.on('train', handleTrainAgent);
      dispatch(showTrainBanner(true));
    } else {
      dispatch(showTrainBanner(false));
    }

    return () => {
      trainSubscription?.off('train', handleTrainAgent);
    };
  }, [!!changes.length]);

  useEffect(() => {
    if (!isScrapingWebsite && !isAgentTraining) {
      dispatch(showProcessingBanner({ isVisible: false, title: '', description: '' }));
    }
  }, [isAgentTraining]);

  if (isGlobalLoading || !user) {
    return <Loader />;
  }

  return (
    <SC.Layout>
      {isModalLoading && <Loader variant={LoaderEnum.Modal} modalText={message} />}
      <Sidebar />
      <SC.Main $withPadding={withPadding} $isBannerVisible={isBannerVisible}>
        {error.isOpen && <ErrorModal {...error} onClose={handleCloseErrorModal} />}
        {children}
        <ConfirmationBanner />
        {isDiscardModalVisible && (
          <PromptModal
            variant="danger"
            title={t('layout.modal.title')}
            description={t('layout.modal.description')}
            cancelText={t('layout.modal.stay')}
            submitText={t('layout.modal.leave')}
            onClose={handleCloseDiscardModal}
            onSubmit={handleLeavePage}
          />
        )}
      </SC.Main>
    </SC.Layout>
  );
}
