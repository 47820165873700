import styled, { keyframes } from 'styled-components';
import { HideScrollbar } from '../../GlobalStyle';

const appearMessage = keyframes`
 0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: ${({ theme }) => `${theme.spacing.s4} ${theme.spacing.s5}`};
`;

export const AudioWrapper = styled.div`
  /* Custom styles for the audio player */
  & .rhap_container {
    border-radius: ${({ theme }) => theme.radius.xs};
    background-color: ${({ theme }) => theme.colors.bg.dark};
    border: 0;
    box-shadow: none;
  }

  & .rhap_play-pause-button {
    color: ${({ theme }) => theme.colors.brand.default};
  }

  & .rhap_progress-bar,
  & .rhap_download-progress {
    background-color: ${({ theme }) => theme.colors.text.secondary};
  }

  & .rhap_progress-indicator,
  & .rhap_progress-filled {
    background-color: ${({ theme }) => theme.colors.brand.default};
  }
`;

export const Messages = styled.div`
  margin-top: ${({ theme }) => theme.spacing.s3};
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  flex: 1;

  overflow-y: scroll;
  background: ${props => props.theme.colors.white};
  ${HideScrollbar}
`;

export const MessageContainer = styled.div<{ $isUser: boolean }>`
  display: flex;
  align-items: ${props => (props.$isUser ? 'flex-start' : 'flex-end')};
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s1};
`;

export const Message = styled.div<{ $isUser: boolean }>`
  max-width: 42rem;
  animation: ${appearMessage} 0.2s linear;
  border-radius: 1.5rem;
  padding: 0.7rem 1.2rem;
  font-size: 1.2rem;
  word-wrap: break-word;
  white-space: pre-line;
  ${props =>
    props.$isUser
      ? `background: ${props.theme.colors.bg.dark}; color: ${props.theme.colors.text.default};`
      : `background: ${props.theme.colors.brand.default}; color: ${props.theme.colors.white};`};
`;
