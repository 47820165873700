import styled from 'styled-components';

export const Menu = styled.div<{ $isLast: boolean }>`
  padding-bottom: ${({ $isLast }) => ($isLast ? 0 : '2rem')};
  margin-bottom: ${({ $isLast }) => ($isLast ? 0 : '2rem')};
  border-bottom: 1px solid ${({ $isLast, theme }) => ($isLast ? 'transparent' : theme.colors.gray200)};
`;

export const MenuHeader = styled.div<{ $isOpenable: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1.2rem;
  align-items: center;
  cursor: ${({ $isOpenable }) => ($isOpenable ? 'pointer' : 'auto')};
`;

export const MenuHeaderIcon = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 50%;
  left: -1.7rem;

  transform: translateY(-50%) ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : '')};
  transition: all 0.3s;
`;

export const MenuSearch = styled.div`
  margin-top: 2rem;
`;

export const MenuList = styled.div<{ $isOpen: boolean }>`
  gap: 0.35rem;
  display: flex;
  flex-direction: column;
  padding: ${({ $isOpen }) => ($isOpen ? '2rem' : '0')} 0 0;
  height: ${({ $isOpen }) => ($isOpen ? '100%' : '0')};
  overflow: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};

  transition: all 0.3s;
`;

export const MenuItem = styled.div`
  padding: ${({ theme }) => theme.spacing.s2} ${({ theme }) => theme.spacing.s3};
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: ${({ theme }) => theme.spacing.s2};

  cursor: pointer;
  border-radius: 17rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray200};

    svg {
      fill: ${({ theme }) => theme.colors.gray900} !important;
    }

    p {
      color: ${({ theme }) => theme.colors.gray900} !important;
    }
  }
`;

export const MenuItemUnread = styled.div`
  height: 2.4rem;
  min-width: 2.4rem;
  padding: 0.3rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  background: ${({ theme }) => theme.colors.error.default};
  border-radius: ${({ theme }) => theme.radius.xl};
`;
