import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { IToast, IToastsState } from './toasts.types';

const initialState: IToastsState = {};

export const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    showToast: (state: IToastsState, action: PayloadAction<IToast>) => {},
    hideToast: (state: IToastsState, action: PayloadAction<{ id: string }>) => {},
    hideAllToasts: () => {},
  },
});

export const { showToast, hideToast, hideAllToasts } = toastsSlice.actions;

export const toastsReducer: Reducer<IToastsState> = toastsSlice.reducer;
