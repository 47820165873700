import { convertToCamelCase, convertToSnakeCase } from '@tuler/shared';
import axiosInstance from '../../config/axios';
import { logger } from '../../logger';
import { IAnswerQACreateRequest, IGetQasResponse, IQA, IQATag, IQAWithId } from './qa.types';
import { QA_PAGE_SIZE } from '../../constants';

export class QAsService {
  public getQAs = async (companyId: string, page: number, search?: string, filterBy?: number): Promise<IGetQasResponse> => {
    try {
      const res = await axiosInstance.get(`qas/${companyId}`, {
        params: convertToSnakeCase({ search, tagFilter: filterBy, pageSize: QA_PAGE_SIZE, page }),
      });
      return convertToCamelCase(res.data as IGetQasResponse);
    } catch (e) {
      logger.error('QAsService.get', e);
      return { success: false };
    }
  };

  public getQA = async (companyId: string, qaId: number) => {
    try {
      const res = await axiosInstance.get(`qas/${companyId}/qa/${qaId}`);
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.get', e);
    }
  };

  public updateQA = async (companyId: string, qa: IQAWithId) => {
    try {
      const res = await axiosInstance.patch(`qas/${companyId}/qa/${qa.id}/update`, convertToSnakeCase({ qa }));
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.create', e);
    }
  };

  public deleteQAs = async (companyId: string, ids: number[]) => {
    try {
      const res = await axiosInstance.post(`qas/${companyId}/qa/delete`, {
        ids,
      });
      return res.data.success;
    } catch (e) {
      logger.error('QAsService.deleteQAs', e);
    }
  };

  public createQA = async (companyId: string, qa: IQA) => {
    try {
      const res = await axiosInstance.post(`qas/${companyId}/qa/create`, convertToSnakeCase({ qa }));
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.create', e);
    }
  };

  public createAnswerQa = async (companyId: string, data: IAnswerQACreateRequest) => {
    try {
      const res = await axiosInstance.post(`qas/${companyId}/qa/create-answer`, convertToSnakeCase(data));
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.create', e);
    }
  };

  public getTags = async (companyId: string) => {
    try {
      const res = await axiosInstance.get(`qas/${companyId}/tags`);
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.getTags', e);
    }
  };

  public createTag = async (companyId: string, label: string) => {
    try {
      const res = await axiosInstance.post(
        `qas/${companyId}/tags/create`,
        convertToSnakeCase({
          label,
        }),
      );
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.createTag', e);
      return {
        success: false,
      };
    }
  };

  public updateTag = async (companyId: string, tag: IQATag) => {
    try {
      const res = await axiosInstance.patch(
        `qas/${companyId}/tags/update`,
        convertToSnakeCase({
          tag,
        }),
      );
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.updateTag', e);
      return {
        success: false,
      };
    }
  };

  public getTagColors = async (companyId: string) => {
    try {
      const res = await axiosInstance.get(`qas/${companyId}/tags/colors`);
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.getTagColors', e);
    }
  };

  public deleteTag = async (companyId: string, id: number) => {
    try {
      const res = await axiosInstance.delete(`qas/${companyId}/tags/${id}`);
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.createTag', e);
      return {
        success: false,
      };
    }
  };

  public getTagOrder = async (companyId: string) => {
    try {
      const res = await axiosInstance.get(`qas/${companyId}/tags/order`);
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.getTagColors', e);
    }
  };

  public updateTagOrder = async (companyId: string, tagOrder: number[]) => {
    try {
      const res = await axiosInstance.post(
        `qas/${companyId}/tags/order`,
        convertToSnakeCase({
          tagOrder,
        }),
      );
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.getTagColors', e);
    }
  };
}
