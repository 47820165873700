import { Saga, SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, put, call, select } from 'redux-saga/effects';

import { closeModalLoading, setPageLoading, showModalLoading } from '../loader/loader.redux';
import { t } from '../../config/i18n';
import { ICompanyState } from '../company/company.types';
import { showToast } from '../toasts/toasts.redux';
import { IGetTelegramChatResponse, IGetTelegramChatsResponse, ITelegramChat, ITelegramChatWithId, ITelegramState } from './telegram.types';
import { createChatRequest, deleteChatRequest, getChatRequest, getChatsRequest, updateChatRequest } from './telegram.service';
import {
  createChat,
  deleteChat,
  getActiveChat,
  getChats,
  setActiveChat,
  setChats,
  setDeleteChat,
  setIsChatLoading,
  updateChat,
} from './telegram.redux';
import { IBaseResponse } from '../../types';
import { ROUTES } from '../../constants/routes';
import { replace } from 'redux-first-history';

export function* getChatsSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  yield put(setPageLoading(true));
  const { success, chats }: IGetTelegramChatsResponse = yield call(getChatsRequest, companyId);

  if (success) {
    yield put(setChats(chats ?? []));
  } else {
    yield put(
      showToast({
        type: 'error',
        message: t('telegramChats.getChatsFailed'),
      }),
    );
  }
  yield put(setPageLoading(false));
}

export function* getActiveChatSaga({ payload: id }: PayloadAction<number>): SagaIterator {
  yield put(setPageLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success, chat }: IGetTelegramChatResponse = yield call(getChatRequest, companyId, id);

  if (success && chat) {
    yield put(setActiveChat(chat));
  } else {
    yield put(
      showToast({
        type: 'error',
        message: t('telegramChats.getFailed'),
      }),
    );
  }
  yield put(setPageLoading(false));
}

export function* createChatSaga({ payload: chat }: PayloadAction<ITelegramChat>): SagaIterator {
  yield put(setIsChatLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success }: IBaseResponse = yield call(createChatRequest, companyId, chat);

  yield put(setIsChatLoading(false));
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('telegramChats.created') : t('telegramChats.createFailed'),
    }),
  );
  yield put(replace(ROUTES.telegramChats.path));
}

export function* updateChatSaga({ payload: chat }: PayloadAction<ITelegramChatWithId>): SagaIterator {
  yield put(setIsChatLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success }: IBaseResponse = yield call(updateChatRequest, companyId, chat);

  yield put(setIsChatLoading(false));
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('telegramChats.updated') : t('telegramChats.updateFailed'),
    }),
  );
  yield put(replace(ROUTES.telegramChats.path));
}

export function* deleteChatSaga(): SagaIterator {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { deleteChatData } = yield select((state: { telegram: ITelegramState }) => state.telegram);
  if (!deleteChatData) return;
  yield put(setDeleteChat(undefined));
  yield put(showModalLoading(t('telegramChats.deleteLoading')));
  const { success }: IBaseResponse = yield call(deleteChatRequest, companyId, deleteChatData.id);

  yield put(getChats(companyId));
  yield put(closeModalLoading());

  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('telegramChats.deleted') : t('telegramChats.deleteFailed'),
    }),
  );
}

export function* initTelegramSagas(): SagaIterator {
  yield takeEvery(getChats.toString(), getChatsSaga);
  yield takeEvery(createChat.toString(), createChatSaga);
  yield takeEvery(getActiveChat.toString(), getActiveChatSaga);
  yield takeEvery(updateChat.toString(), updateChatSaga);
  yield takeEvery(deleteChat.toString(), deleteChatSaga);
}
