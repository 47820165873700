import { Typography } from '../../components/Typography/Typography';
import Icon, { IconName } from '../Icon/Icon';
import IconMenu, { IIconMenuProps } from '../IconMenu/IconMenu';
import * as SC from './styles';
import { useColors } from '../../hooks/theme';
import SearchInput from '../SearchInput/SearchInput';
import Avatar from '../Avatar/Avatar';

export interface IMiniMenuItem {
  icon: IconName;
  title: string;
  isActive: boolean;
  onClick: () => void;
  count?: number;
}

export interface IMiniMenuProps {
  title: string;
  image?: string;
  isOpen: boolean;
  onOpen?: () => void;
  isOpenable: boolean;
  hasSearch: boolean;
  searchValue?: string;
  onSearch?: (value: string) => void;
  searchPlaceholder?: string;
  isLast?: boolean;
  iconMenu?: IIconMenuProps;
  items: IMiniMenuItem[];
}

export default function MiniMenu({
  title,
  image,
  isOpen,
  onOpen,
  isOpenable,
  hasSearch,
  searchValue,
  onSearch,
  searchPlaceholder,
  iconMenu,
  items,
  isLast = false,
}: IMiniMenuProps) {
  const colors = useColors();

  function toggleMenu() {
    if (onOpen) onOpen();
  }

  return (
    <SC.Menu $isLast={isLast}>
      <SC.MenuHeader $isOpenable={isOpenable} onClick={toggleMenu}>
        {isOpenable && (
          <SC.MenuHeaderIcon $isOpen={isOpen}>
            <Icon name={IconName.RoundedTriangle} width={0.9} height={0.9} />
          </SC.MenuHeaderIcon>
        )}
        <Avatar image={image} name={title} />

        <Typography variant="h4" ellipsis>
          {title}
        </Typography>
        {iconMenu && (
          <div onClick={e => e.stopPropagation()}>
            <IconMenu {...iconMenu} />
          </div>
        )}
      </SC.MenuHeader>
      {hasSearch && (
        <SC.MenuSearch>
          <SearchInput onSearch={onSearch!} placeholder={searchPlaceholder} />
        </SC.MenuSearch>
      )}
      <SC.MenuList $isOpen={isOpen}>
        {items.map(item => (
          <SC.MenuItem key={item.title + title} onClick={item.onClick}>
            <Icon name={item.icon} color={item.isActive ? colors.brand.default : colors.text.secondary} />
            <Typography variant="subtitle1" color={item.isActive ? 'brand' : 'secondary'}>
              {item.title}
            </Typography>
            {!!item.count && <SC.MenuItemUnread>{item.count}</SC.MenuItemUnread>}
          </SC.MenuItem>
        ))}
      </SC.MenuList>
    </SC.Menu>
  );
}
