import { keyframes, styled } from 'styled-components';

const appearModal = keyframes`
 0% {
    transform: translateY(5rem);
  }
  100% {
    transform:translateY(0);
  }
`;

const appearModalBackground = keyframes`
 0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
`;

export const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  animation: ${appearModalBackground} 0.2s linear;
  background: ${props => props.theme.colors.gray900};
  opacity: 0.5;
`;

export const ModalContent = styled.div<{ $size: string; $height?: string }>`
  position: relative;
  width: ${props => props.$size};
  max-height: ${({ $height }) => ($height ? $height : '100%')};
  height: ${({ $height }) => ($height ? $height : 'auto')};
  display: flex;
  flex-direction: column;

  animation: ${appearModal} 0.2s linear;
  background: ${props => props.theme.colors.white};
  border: 0.1rem solid ${props => props.theme.colors.gray200};
  border-radius: 2rem;
`;

export const ModalHeader = styled.div`
  padding: 1.7rem 3rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: space-between;

  background: ${props => props.theme.colors.white};
  border-bottom: 0.1rem solid ${props => props.theme.colors.gray200};
  border-radius: 2rem 2rem 0 0;
`;

export const ModalHeaderClose = styled.button`
  padding: 0;

  background: none;
  outline: none;
  border: 0;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.98);
  }
`;

export const ModalBody = styled.div`
  position: relative;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
`;

export const ModalBodySidebar = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;

  border-right: 0.1rem solid ${({ theme }) => theme.colors.gray200};
`;

export const ModalBodyChildren = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.4rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2.5rem;
    border: 0.4rem solid ${props => props.theme.colors.gray200};
  }
`;

export const ModalBodySidebarItem = styled.button<{ $isActive: boolean }>`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.primary500 : theme.colors.gray600)};

  background: none;
  border: none;
  cursor: pointer;
`;

export const ModalBodyClose = styled.button`
  position: absolute;
  top: 3.5rem;
  right: 3.2rem;

  background: none;
  border: none;
  cursor: pointer;
`;
