import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  closeDeleteQaModal,
  closeDeleteTagModal,
  createTag,
  deleteQAs,
  deleteTag,
  deleteTagFromQAs,
  getQAList,
  getTagColors,
  getTagOrder,
  getTags,
  saveQaAnswer,
  saveQA,
  setQAList,
  setTagColors,
  setTagOrder,
  setTags,
  updateQA,
  updateTag,
  updateTagOrder,
  updateTagsListItem,
  setActiveQa,
  getQaById,
  setPageCount,
  resetFilter,
  setFilterPage,
} from './qa.redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { closeModalLoading, setPageLoading, showModalLoading } from '../loader/loader.redux';
import { t } from 'i18next';
import { ICompanyState } from '../company/company.types';
import { IQAWithId, IQAState, IQATag, IAnswerQACreate, IGetQasResponse } from './qa.types';
import { showToast } from '../toasts/toasts.redux';
import { QAsService } from './qa.service';
import { getActiveNotification, setActiveNotification } from '../notifications/notifications.redux';
import { pushQuery, replaceAction } from '../navigation/navigation.redux';
import { ROUTES } from '../../constants/routes';
import { INotificationsState } from '../notifications/notifications.types';
import { QA_PAGE_SIZE } from '../../constants';

export function* getQAListSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  yield put(setPageLoading(true));
  const qasService = new QAsService();
  const { filter }: IQAState = yield select((state: { qa: IQAState }) => state.qa);
  const data: IGetQasResponse = yield call(
    qasService.getQAs,
    companyId,
    filter.page,
    filter.search || undefined,
    filter.tag ? filter.tag.id : undefined,
  );

  if (data.success) {
    yield put(setQAList(data?.qas ?? []));
    const pageCount = Math.ceil((data?.totalCount ?? 0) / QA_PAGE_SIZE);
    yield put(setPageCount(pageCount));
  }
  yield put(setPageLoading(false));
}

export function* getQaByIdSaga({ payload: { companyId, qaId } }: PayloadAction<{ companyId: string; qaId: number }>): SagaIterator {
  yield put(setPageLoading(true));
  const { activeNotification }: INotificationsState = yield select((state: { notifications: INotificationsState }) => state.notifications);
  const qasService = new QAsService();
  const data = yield call(qasService.getQA, companyId, qaId);

  if (data.success) {
    yield put(setActiveQa(data.qa));
  }

  if (!data.success && activeNotification) {
    yield put(pushQuery({ id: '0' }));
    yield put(showToast({ type: 'error', message: t('qa.notificationQADeleted') }));
    yield put(setActiveNotification({ ...activeNotification, qaId: 0 }));
  }

  yield put(setPageLoading(false));
}

export function* getTagsSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  const qasService = new QAsService();
  const data = yield call(qasService.getTags, companyId);

  if (data.success) {
    yield put(setTags(data.tags ?? []));
  }
}

export function* getTagColorsSaga(): SagaIterator {
  const { id } = yield select((state: { company: ICompanyState }) => state.company.company);
  const qasService = new QAsService();
  const data = yield call(qasService.getTagColors, id);

  if (data.success) {
    yield put(setTagColors(data.colors ?? []));
  }
}

export function* saveQASaga({ payload: qa }: PayloadAction<IQAWithId>) {
  yield put(showModalLoading(qa.id ? t('qa.editQALoading') : t('qa.createQALoading')));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const qasService = new QAsService();
  let success: boolean = false;
  let data: Record<string, any>;
  const isNewQA = qa.id === 0;

  if (isNewQA) {
    const { id, ...qaWithoutId } = qa;
    data = yield call(qasService.createQA, companyId, qaWithoutId);
    success = data?.success;
    if (success) {
      yield put(updateQA({ oldId: id, qa: { ...qa, id: data.qa.id } }));
    }
  } else {
    data = yield call(qasService.updateQA, companyId, qa);
    success = data?.success;
    if (success) {
      yield put(updateQA({ qa }));
    }
  }
  yield put(resetFilter());
  yield put(closeModalLoading());

  if (success) {
    yield put(
      showToast({
        message: isNewQA ? t('qa.created') : t('qa.updated'),
      }),
    );
  } else {
    yield put(
      showToast({
        type: 'error',
        message: isNewQA ? t('qa.createFailed') : t('qa.updateFailed'),
      }),
    );
  }
  yield put(replaceAction(ROUTES.qa.path));
}

export function* saveQaAnswerSaga({ payload: { qa, notificationId } }: PayloadAction<IAnswerQACreate>): SagaIterator {
  yield put(showModalLoading(t('qa.createQALoading')));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const qasService = new QAsService();

  const { ...qaWithoutId } = qa;

  const data = yield call(qasService.createAnswerQa, companyId, {
    qa: qaWithoutId,
    notificationId,
  });
  const success = data.success;

  if (success) {
    yield put(getActiveNotification(notificationId));
  }
  yield put(closeModalLoading());
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('qa.created') : t('qa.createFailed'),
    }),
  );
}

export function* deleteQASaga(): SagaIterator {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { selectedQAIds, qAList }: IQAState = yield select((state: { qa: IQAState }) => state.qa);
  const qasService = new QAsService();

  yield put(closeDeleteQaModal());
  yield put(showModalLoading(t('qa.deleteQALoading')));

  const success = yield call(qasService.deleteQAs, companyId, selectedQAIds);

  if (success) {
    yield put(setFilterPage(1));
    yield put(getQAList(companyId));
  }

  yield put(closeModalLoading());

  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('qa.deleted') : t('qa.deleteFailed'),
    }),
  );
}

export function* createTagSaga({
  payload: { name, callback },
}: PayloadAction<{ name: string; callback?: (id: number) => void }>): SagaIterator {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { tags, tagOrder } = yield select((state: { qa: IQAState }) => state.qa);
  const qasService = new QAsService();
  const data = yield call(qasService.createTag, companyId, name);

  if (data.success) {
    yield put(setTags([...tags, data.tag]));
    yield put(setTagOrder([...tagOrder, data.tag.id]));
    if (callback) {
      yield call(callback, data.tag.id);
    }
  }
}

export function* updateTagSaga({ payload: tag }: PayloadAction<IQATag>): SagaIterator {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const qasService = new QAsService();
  yield put(updateTagsListItem(tag));
  yield call(qasService.updateTag, companyId, tag);
}

export function* deleteTagSaga({ payload: id }: PayloadAction<number>): SagaIterator {
  yield put(closeDeleteTagModal());
  const { tags, tagOrder }: IQAState = yield select((state: { qa: IQAState }) => state.qa);
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const qasService = new QAsService();
  const data = yield call(qasService.deleteTag, companyId, id);

  if (data.success) {
    yield put(deleteTagFromQAs(id));
    yield put(setTagOrder(tagOrder.filter((tag: number) => tag !== id)));
    yield put(setTags(tags.filter((tag: IQATag) => tag.id !== id)));
  }
}

export function* getTagOrderSaga({ payload }: PayloadAction<string>): SagaIterator {
  const qasService = new QAsService();
  const data = yield call(qasService.getTagOrder, payload);

  if (data.success) {
    yield put(setTagOrder(data.tagOrder));
  }
}

export function* updateTagOrderSaga({ payload: { id, order } }: PayloadAction<{ id: number; order: number }>): SagaIterator {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { tagOrder }: IQAState = yield select((state: { qa: IQAState }) => state.qa);
  const filteredTagOrder = tagOrder.filter((tagId: any) => tagId !== id);
  filteredTagOrder.splice(order, 0, id);

  const qasService = new QAsService();
  const data = yield call(qasService.updateTagOrder, companyId, filteredTagOrder);

  if (data.success) {
    yield put(setTagOrder(filteredTagOrder));
  }
}

export function* initQASagas(): SagaIterator {
  yield takeEvery(saveQA.toString(), saveQASaga);
  yield takeEvery(getTags.toString(), getTagsSaga);
  yield takeEvery(getTagColors.toString(), getTagColorsSaga);
  yield takeEvery(getQAList.toString(), getQAListSaga);
  yield takeEvery(getQaById.toString(), getQaByIdSaga);
  yield takeEvery(deleteQAs.toString(), deleteQASaga);
  yield takeEvery(createTag.toString(), createTagSaga);
  yield takeEvery(updateTag.toString(), updateTagSaga);
  yield takeEvery(deleteTag.toString(), deleteTagSaga);
  yield takeEvery(getTagOrder.toString(), getTagOrderSaga);
  yield takeEvery(updateTagOrder.toString(), updateTagOrderSaga);
  yield takeEvery(saveQaAnswer.toString(), saveQaAnswerSaga);
}
