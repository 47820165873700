import { IQAWithId } from '../qa/qa.types';

export interface IDocumentationState {
  text: IText;
  qAList: IQAWithId[];
  deleteQAId: string;
  openedQAIds: string[];
  isDeleteQAModalOpen: boolean;
  links: ILinks;
  isDeleteAllLinksModalOpen: boolean;
  ignoreWords: string[];
  documentationMeta: IDocumentationMeta;
}

export interface IDocumentationMeta {
  isScrapingWebsite: boolean;
  isProcessingDocument: boolean;
  isAgentTraining: boolean;
  changes: IDocumentationMetaChange[];
}

export interface IDocumentationMetaChange {
  type: EDocumentation;
  id: string;
  action: EDocumentationAction;
}

export interface IText {
  content: string;
  charCount: number;
}

export type ILinks = Record<string, ILink>;

export interface ILink {
  id: string;
  url: string;
  charCount: number;
}

export enum EDocumentation {
  Document = 'document',
  QA = 'qa',
  Website = 'website',
  Text = 'text',
}

export enum EDocumentationAction {
  Add = 'add',
  Delete = 'delete',
  Update = 'update',
}
