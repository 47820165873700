import styled from 'styled-components';
import { HideScrollbar } from '../../GlobalStyle';

export const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  flex: 1;
  overflow-y: scroll;
  padding: 3.2rem 2.4rem 0;
  scrollbar-width: none;
  background: ${props => props.theme.colors.white};
  ${HideScrollbar}
`;
