import { useEffect, useRef, useState } from 'react';
import { EmailAuthProvider, onAuthStateChanged, GoogleAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import { ROUTES } from '../../constants/routes';
import * as SC from './styles';
import Image from '../../components/Image/Image';
import { auth } from '../../config/firebase';
import { useColors } from '../../hooks/theme';
import { Typography } from '../../components/Typography/Typography';

interface IProps {
  emailLogin?: boolean;
}

function AuthPage({ emailLogin = false }: IProps) {
  const colors = useColors();
  const uiConfig: firebaseui.auth.Config = {
    signInSuccessUrl: ROUTES.dashboard.path,
    signInOptions: [
      // {
      //   provider: GoogleAuthProvider.PROVIDER_ID, // TODO enable when we let people sign up
      // },
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        disableSignUp: { status: true }, // TODO enable when we let people sign up
        buttonColor: colors.primary500,
        fullLabel: 'Email Sign In/Up',
      },
    ],
  };

  const [userSignedIn, setUserSignedIn] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    // Get or Create a firebaseUI instance.
    const firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(auth, user => {
      if (!user && userSignedIn) firebaseUiWidget.reset();
      setUserSignedIn(!!user);
    });

    // Render the firebaseUi Widget.
    firebaseUiWidget.start(elementRef.current as unknown as string, uiConfig);

    return () => {
      unregisterAuthObserver();
      firebaseUiWidget.reset();
    };
  }, [firebaseui, uiConfig]);

  // TODO uncomment when we enable users to sign up
  // useEffect(() => {
  //   const el = document.getElementById('firebase-ui-react-parent');

  //   const authUIObserver = new MutationObserver(function () {
  //     const texts = document.querySelectorAll('.firebaseui-idp-text-long, .firebaseui-title');
  //     texts.forEach((item, i) => {
  //       if (item?.textContent?.includes('Sign in')) {
  //         item.textContent = item.textContent.replace('Sign in with email', 'Sign In / Sign Up');
  //       }
  //     });
  //   });

  //   if (el) {
  //     authUIObserver.observe(el, { attributes: true, childList: true, subtree: true });
  //   }
  // }, []);

  return (
    <ScreenWrapper authRequired={false} skipQuoteCheck>
      <SC.Wrapper>
        <SC.Login id="firebase-ui-react-parent" ref={elementRef}>
          <SC.LoginLogo>
            <Image src="/icons/logo.png" alt="logo" height={4.2} width={4.2} />
            <Typography variant="h1">TULER</Typography>
          </SC.LoginLogo>
        </SC.Login>
      </SC.Wrapper>
    </ScreenWrapper>
  );
}

export default AuthPage;
