import { UserRole } from '../redux/users/users.types';

export interface IRoute {
  path: string;
  /**
   * permissions - it's important that we be explicit about defining WHO WE ALLOW rather that who
   * we do not allow to access routes. This will prevent us from accidentally giving access to a route to
   * a user that we did not intend to.
   */
  permissions: UserRole[];
}

export type TPageName =
  | 'dashboard'
  | 'login'
  | 'uploaderLogin'
  | 'intro'
  | 'camera'
  | 'images'
  | 'item'
  | 'quote'
  | 'chats'
  | 'documentation'
  | 'agents'
  | 'notifications'
  | 'companySettings'
  | 'userProfile'
  | 'customers'
  | 'customer'
  | 'createCompany'
  | 'qa'
  | 'newQA'
  | 'calls'
  | 'integrations'
  | 'telegramChats'
  | 'telegramChatEdit'
  | 'telegramNotifications'
  | 'aircall'
  | 'trello'
  | 'smartmoving';

export const ROUTES: Record<TPageName, IRoute> = {
  dashboard: { path: '/dashboard', permissions: [UserRole.admin, UserRole.default, UserRole.superuser] },
  login: { path: '/', permissions: [] },
  uploaderLogin: { path: '/uploader-login', permissions: [UserRole.superuser] },
  intro: { path: '/intro', permissions: [UserRole.superuser] },
  camera: { path: '/camera', permissions: [UserRole.superuser] },
  images: { path: '/images', permissions: [UserRole.superuser] },
  item: { path: '/item', permissions: [UserRole.superuser] },
  quote: { path: '/quote', permissions: [UserRole.superuser] },
  chats: { path: '/chats', permissions: [UserRole.superuser, UserRole.admin] },
  documentation: { path: '/documentation', permissions: [UserRole.admin, UserRole.superuser] },
  agents: { path: '/agents', permissions: [UserRole.admin, UserRole.superuser] },
  notifications: { path: '/notifications', permissions: [UserRole.superuser, UserRole.admin] },
  customers: { path: '/customers', permissions: [UserRole.superuser, UserRole.admin] },
  customer: { path: '/customer', permissions: [UserRole.superuser, UserRole.admin] },
  companySettings: { path: '/company-settings', permissions: [UserRole.superuser, UserRole.admin] },
  userProfile: { path: '/profile', permissions: [UserRole.superuser, UserRole.admin, UserRole.default] },
  createCompany: { path: '/create-company', permissions: [UserRole.superuser] },
  qa: { path: '/qa', permissions: [UserRole.superuser, UserRole.admin, UserRole.default] },
  newQA: { path: '/qa/edit', permissions: [UserRole.superuser, UserRole.admin] },
  calls: { path: '/calls', permissions: [UserRole.superuser, UserRole.admin] },
  integrations: { path: '/integrations', permissions: [UserRole.superuser, UserRole.admin] },
  aircall: { path: '/integrations/aircall', permissions: [UserRole.superuser, UserRole.admin] },
  smartmoving: { path: '/integrations/smartmoving', permissions: [UserRole.superuser, UserRole.admin] },
  trello: { path: '/integrations/trello', permissions: [UserRole.superuser, UserRole.admin] },
  telegramChats: { path: '/integrations/telegram-chats', permissions: [UserRole.superuser, UserRole.admin] },
  telegramChatEdit: { path: '/integrations/telegram-chats/edit', permissions: [UserRole.superuser, UserRole.admin] },
  telegramNotifications: { path: '/integrations/telegram-notifications', permissions: [UserRole.superuser, UserRole.admin] },
};
