import { MutableRefObject, useEffect, useRef, useState } from 'react';
import ChatMessage from '../ChatMessage/ChatMessage';
import * as SC from './styles';
import { IMessageWithId, UserEnum } from '@tuler/shared';
import ChatMessageInput from '../ChatMessageInput/ChatMessageInput';
import { setAssistantTypingStore } from '../../redux/sessions/sessions.redux';
import { useDispatch } from 'react-redux';

interface IProps {
  sessionId: string;
  messages: IMessageWithId[];
  isUserTyping: boolean;
  isAssistantTyping: boolean;
  scrollToTop?: (boolean | number)[];
  isAssistantView?: boolean;
  hasUnseenMessages: boolean;
  onSetMessageNeedsAttention: (id: string) => void;
  onAnswerMessage: (message: IMessageWithId) => void;
  onSendMessage: (sessionId: string, message: string) => void;
  onResetUnseenMessages: (sessionId: string) => void;
}

export default function ChatMessages({
  sessionId,
  messages,
  isUserTyping,
  isAssistantTyping,
  scrollToTop = [],
  hasUnseenMessages,
  onSetMessageNeedsAttention,
  onAnswerMessage,
  onSendMessage,
  onResetUnseenMessages,
}: IProps) {
  const dispatch = useDispatch();
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [message, setMessage] = useState('');
  const prevMessageLengthRef = useRef<number>(0);

  function handleChangeMessage(message: string) {
    setMessage(message);
  }

  function handleSendMessage() {
    onSendMessage(sessionId, message);
    setMessage('');
  }

  function handleResetUnseenMessages() {
    hasUnseenMessages && onResetUnseenMessages(sessionId);
  }

  function getMessages() {
    return messages.map(msg => {
      const isAssistant = msg.role === UserEnum.assistant;

      return (
        <ChatMessage
          key={msg.createdAt}
          isAssistant={isAssistant}
          message={msg}
          onSetMessageNeedsAttention={onSetMessageNeedsAttention}
          onAnswerMessage={onAnswerMessage}
        />
      );
    });
  }

  useEffect(() => {
    const chatWindow = ref.current;
    if (chatWindow !== null) {
      chatWindow.scrollTop = chatWindow.scrollHeight;
    }
  }, scrollToTop);

  useEffect(() => {
    handleResetUnseenMessages();
  }, [sessionId]);

  useEffect(() => {
    // set assistant typing logic
    let typingTimeout: NodeJS.Timeout | undefined;

    function handleTypingTimeout() {
      dispatch(setAssistantTypingStore({ sessionId, isTyping: false }));
      clearTimeout(typingTimeout as NodeJS.Timeout);
    }

    function handleTyping() {
      if (!isAssistantTyping) {
        dispatch(setAssistantTypingStore({ sessionId, isTyping: true }));
      }
      if (typingTimeout) clearTimeout(typingTimeout);
      typingTimeout = setTimeout(handleTypingTimeout, 3000);
    }

    if (message.length !== prevMessageLengthRef.current) {
      prevMessageLengthRef.current = message.length;
      if (typingTimeout) clearTimeout(typingTimeout);
      handleTyping();
    }

    return () => {
      if (typingTimeout) clearTimeout(typingTimeout);
    };
  }, [message.length, sessionId]);

  useEffect(() => {
    // remove assistant typing on page reload
    const handleUnload = () => {
      dispatch(setAssistantTypingStore({ sessionId, isTyping: false }));
    };
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, [sessionId]);

  useEffect(() => {
    // remove assistant typing on session change
    return () => {
      dispatch(setAssistantTypingStore({ sessionId, isTyping: false }));
    };
  }, []);

  return (
    <SC.Container ref={ref} onClick={handleResetUnseenMessages}>
      {getMessages()}

      {isUserTyping && <ChatMessage isAssistant={false} typing />}
      <ChatMessageInput message={message} onSubmit={handleSendMessage} onChange={handleChangeMessage} sessionId={sessionId} />
    </SC.Container>
  );
}
