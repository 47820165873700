import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import * as SC from './styles';
import Modal from '../Modal/Modal';
import UploadImage from '../UploadImage/UploadImage';
import Input from '../Input/Input';
import Button from '../Button/Button';
import RadioField from '../RadioField/RadioField';
import { Schema } from 'zod';
import { FormField } from '../../common/types';

interface IProps {
  title: string;
  cancelButtonText?: string;
  submitButtonText: string;
  fields: FormField[];
  validationSchema: Schema;
  onClose?: () => void;
  onSubmit: (data: any) => void;
  isLoading?: boolean;
}

function ModalForm({ title, cancelButtonText, submitButtonText, fields, validationSchema, onClose, onSubmit, isLoading = false }: IProps) {
  const imageField = fields.find(field => field.type === 'image');
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
    defaultValues: fields.reduce((acc, field) => ({ ...acc, [field.name]: field.defaultValue }), {}),
    mode: 'onBlur',
  });
  const image = imageField?.name ? getValues(imageField?.name) : null;

  function getFields() {
    return fields.map(field => {
      if (field.type === 'image') return;

      if (field.options) {
        return (
          <RadioField
            key={field.name}
            required={field.required}
            disabled={field.disabled}
            name={field.name}
            label={field.label}
            options={field.options}
            control={control}
            error={errors[field.name]}
          />
        );
      }

      return (
        <Input
          key={field.name}
          required={field.required}
          disabled={isLoading ? isLoading : field.disabled}
          name={field.name}
          label={field.label}
          type={field.type}
          control={control}
          error={errors[field.name]}
          placeholder={field.placeholder}
        />
      );
    });
  }

  return (
    <Modal title={title} onClose={onClose}>
      <SC.Form>
        {!!imageField && <UploadImage name={imageField.name} control={control} image={image} setValue={setValue} />}
        {getFields()}
        <SC.FormActions>
          {cancelButtonText && (
            <Button onClick={onClose} variant="secondary">
              {cancelButtonText}
            </Button>
          )}
          <Button onClick={handleSubmit(onSubmit)} expand={!cancelButtonText} disabled={!isDirty} loading={isLoading}>
            {submitButtonText}
          </Button>
        </SC.FormActions>
      </SC.Form>
    </Modal>
  );
}

export default ModalForm;
