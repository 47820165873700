import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import { IAgent } from '@tuler/shared/lib/types';
import { IAgentForm, IAgentsState } from '../../redux/agents/agents.types';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAgent,
  enableAgent,
  getAgents,
  resetEditAgentId,
  saveAgent,
  setEditAgentId,
  showAgentModal,
  showDeleteAgentModal,
} from '../../redux/agents/agents.redux';
import { agentValidation } from '../../schemas';
import PromptModal from '../../components/PromptModal/PromptModal';
import Agents from '../../components/Agents/Agents';
import PageContent from '../../components/PageContent/PageContent';
import { ICompanyState } from '../../redux/company/company.types';
import ModalForm from '../../components/ModalForm/ModalForm';
import { FormField } from '../../common/types';

export default function AgentsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { agentModalOpen, isDeleteAgentModalOpen, agents, editAgentId } = useSelector((state: { agents: IAgentsState }) => state.agents);
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const { image: agentImage, name, personality, greeting } = agents.find(agent => agent.id === editAgentId) || ({} as IAgent);
  const hasAgents = agents.length > 0;

  const agentFormFields: FormField[] = [
    {
      name: 'image',
      defaultValue: agentImage || '',
      label: t('agents.uploadAgentImageTitle'),
      type: 'image',
      required: false,
    },
    {
      name: 'name',
      defaultValue: name || '',
      label: t('agents.addAgentName'),
      placeholder: t('agents.addAgentNamePlaceholder'),
      required: true,
    },
    {
      name: 'personality',
      defaultValue: personality || '',
      label: t('agents.addAgentPersonality'),
      placeholder: t('agents.addAgentPersonalityPlaceholder'),
      required: true,
    },
    {
      name: 'greeting',
      defaultValue: greeting || '',
      label: t('agents.addAgentGreeting'),
      placeholder: t('agents.addAgentGreetingPlaceholder'),
      type: 'textarea',
      required: true,
    },
  ];

  function getAgentName(id: string) {
    const agent = agents.find(agent => agent.id === id);
    return `${agent?.name}`;
  }

  function handleEditAgent(data: IAgentForm) {
    dispatch(saveAgent(data));
  }

  function handleDeleteAgent() {
    dispatch(deleteAgent());
  }

  function handleOpenDeleteAgentModal(id: string) {
    dispatch(setEditAgentId(id));
    dispatch(showDeleteAgentModal(true));
  }

  function handleCloseDeleteAgentModal() {
    dispatch(showDeleteAgentModal(false));
  }

  function handleEnableAgent(id: string, value: boolean) {
    dispatch(enableAgent({ enable: value, id }));
  }

  function handleShowAgentModal(id?: string) {
    if (agentModalOpen) {
      dispatch(resetEditAgentId());
    }

    if (id) {
      dispatch(setEditAgentId(id));
    }

    dispatch(showAgentModal(!agentModalOpen));
  }

  useEffect(() => {
    dispatch(getAgents(companyId));
  }, [companyId]);

  return (
    <Layout>
      {agentModalOpen && (
        <ModalForm
          title={editAgentId ? t('agents.editAgent') : t('agents.addAgent')}
          fields={agentFormFields}
          onClose={handleShowAgentModal}
          onSubmit={handleEditAgent}
          validationSchema={agentValidation}
          submitButtonText={editAgentId ? t('agents.editAgentFromSubmit') : t('agents.addAgentFromSubmit')}
        />
      )}

      {isDeleteAgentModalOpen && (
        <PromptModal
          variant="danger"
          questionKey="agents.deleteAgentQuestion"
          name={getAgentName(editAgentId)}
          title={t('agents.deleteAgentTitle')}
          inputTitle={t('agents.deleteAgentInputTitle')}
          inputError={t('agents.deleteAgentInputError')}
          inputPlaceholder={t('agents.deleteAgentInputPlaceholder')}
          cancelText={t('agents.deleteAgentCancel')}
          submitText={t('agents.deleteAgentSubmit')}
          onClose={handleCloseDeleteAgentModal}
          onSubmit={handleDeleteAgent}
        />
      )}
      <PageContent
        title={t('agents.title')}
        hasData={hasAgents}
        actionButtonText={t('agents.createAgent')}
        emptyTitle={t('agents.agentsEmptyTitle')}
        emptyButtonText={t('agents.agentsEmptyButton')}
        onClickAction={() => handleShowAgentModal()}
      >
        <Agents
          agents={agents}
          onShowAgentModal={handleShowAgentModal}
          onOpenDeleteAgentModal={handleOpenDeleteAgentModal}
          onEnableAgent={handleEnableAgent}
        />
      </PageContent>
    </Layout>
  );
}
