import React from 'react';
import * as SC from './styles';

interface ImageProps {
  src: string;
  alt: string;
  height?: number;
  width?: number;
}

function Image({ src, alt, height, width }: ImageProps) {
  return <SC.Image src={src} alt={alt} height={height} width={width} />;
}

export default Image;
