import { ICustomerWithId, TCustomersSort } from './customers.types';
import FirestoreService from '../../services/firestore.service';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';

export class CustomerService extends FirestoreService {
  constructor(companyId: string, customerId: string) {
    super('companies', companyId, 'customers', customerId);
  }

  public getCustomer = async (): Promise<ICustomerWithId> => {
    try {
      return (await this.getDoc()) as ICustomerWithId;
    } catch (e) {
      throw new Error(`getCustomer: ${e}`);
    }
  };
}

export class CustomersService extends FirestoreService {
  constructor(companyId: string) {
    super('companies', companyId, 'customers');
  }

  public searchCustomers = async (searchValue: string): Promise<ICustomerWithId[]> => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      const isEmail = emailRegex.test(searchValue);

      if (isEmail) {
        return (await this.queryByField('email', searchValue)) as ICustomerWithId[];
      }

      if (isValidPhoneNumber(searchValue, 'US')) {
        const phone = parsePhoneNumber(searchValue, 'US')?.nationalNumber || '';
        return (await this.queryByField('phone', phone)) as ICustomerWithId[];
      }

      return [];
    } catch (e) {
      throw new Error(`searchCustomers: ${e}`);
    }
  };

  public getCustomers = async (sortBy?: TCustomersSort, descending?: boolean): Promise<ICustomerWithId[]> => {
    try {
      return sortBy
        ? ((await this.queryAll({ field: sortBy, direction: descending ? 'desc' : 'asc' })) as ICustomerWithId[])
        : ((await this.queryAll({ field: 'updated_at', direction: 'asc' })) as ICustomerWithId[]);
    } catch (e) {
      throw new Error(`getCustomers: ${e}`);
    }
  };
}
