import { styled } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 1fr;

  height: 100%;
  transition: all 0.3s;
  overflow: hidden;
`;

export const UploadDocument = styled.div`
  width: 100%;
  padding: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.s4};

  border-radius: ${props => props.theme.radius.m};
  cursor: pointer;
  transition: all 1s;

  &:hover {
    background: ${props => props.theme.colors.bg.darkest};
  }
`;

export const UploadDocumentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s1};

  text-align: center;
`;
