import { ToastOptions, toast } from 'react-toastify';
import { SagaIterator } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideAllToasts, hideToast, showToast } from './toasts.redux';
import { IToast } from './toasts.types';

export function* showToastSaga({ payload }: PayloadAction<IToast>): SagaIterator {
  const { message, type = 'success' } = payload;
  const id = payload.id || message;

  const toastOptions: ToastOptions = {
    position: 'top-right',
    toastId: id,
    closeButton: true,
    autoClose: 3000,
    hideProgressBar: true,
  };

  if (type === 'error') {
    yield call(toast.error, message, toastOptions);
  }

  if (type === 'success') {
    yield call(toast.success, message, toastOptions);
  }
}

export function* hideToastSaga({ payload }: PayloadAction<{ id: string }>): SagaIterator {
  yield call(toast.dismiss, payload.id);
}

export function* hideAllToastsSage(): SagaIterator {
  yield call(toast.dismiss);
}

export function* initToastsSagas(): SagaIterator {
  yield takeEvery(showToast.toString(), showToastSaga);
  yield takeEvery(hideToast.toString(), hideToastSaga);
  yield takeEvery(hideAllToasts.toString(), hideAllToastsSage);
}
