import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.s6};
  transition: all 0.2s;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.s5};
`;

export const HeaderInfo = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s3};
`;

export const HeaderInfoIcon = styled.div`
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.s3};
  border-radius: ${({ theme }) => theme.radius.xl};

  box-shadow: ${({ theme }) => theme.shadows.light};
  background: ${({ theme }) => theme.colors.bg.default};
`;

export const HeaderInfoCol = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s1};
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s3};
`;

export const Form = styled.div`
  padding-top: ${({ theme }) => theme.spacing.s6};
  padding-bottom: ${({ theme }) => theme.spacing.s2};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s6};
`;

export const HeaderInfoTags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.s2};
`;

export const HeaderInfoTag = styled.div<{ $textColor: string; $backgroundColor: string }>`
  padding: ${({ theme }) => `${theme.spacing.s1} ${theme.spacing.s2}`};

  font-size: 1.2rem;
  font-weight: 600;
  line-height: 150%;
  color: ${({ $textColor }) => $textColor};

  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.radius.xl};
`;
