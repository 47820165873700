import styled from 'styled-components';
import { TCardPadding, TCardRadius } from './Card';

export const Card = styled.div<{ $noPadding: boolean; $isFullHeight: boolean; $padding: TCardPadding; $radius: TCardRadius }>`
  padding: ${({ theme, $noPadding, $padding }) => {
    const paddings = {
      small: theme.spacing.s3,
      medium: theme.spacing.s5,
      large: theme.spacing.s7,
    };
    return $noPadding ? '0' : paddings[$padding];
  }};
  height: ${pros => (pros.$isFullHeight ? '100%' : 'fit-content')};
  display: ${pros => (pros.$isFullHeight ? 'grid' : 'block')};
  grid-template-rows: ${pros => (pros.$isFullHeight ? '1fr' : 'auto')};

  background: ${props => props.theme.colors.bg.default};
  border-radius: ${({ theme, $padding }) => {
    const radiuses = {
      small: theme.radius.s,
      medium: theme.radius.m,
      large: theme.radius.l,
    };
    return radiuses[$padding];
  }};
`;
