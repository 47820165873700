import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
`;

export const FormActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1.4rem;
`;
