import { Schema } from 'zod';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as SC from './styles';
import Input from '../Input/Input';
import { TextSchemaType } from '../../schemas';
import { useConnectedForm } from '../../hooks/useConnectedForm';

interface ITextEditorProps {
  defaultValue?: string;
  validationSchema: Schema;
  onSubmit: (data: TextSchemaType) => void;
}

export default function TextEditor({ defaultValue, validationSchema, onSubmit }: ITextEditorProps) {
  const { t } = useTranslation();
  const { control, reset } = useConnectedForm({
    onSubmit,
    id: 'text',
    validationSchema,
    defaultValues: {
      content: defaultValue,
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    reset({ content: defaultValue });
  }, [defaultValue, reset]);

  return (
    <SC.Wrapper>
      <SC.ScrollableWrapper>
        <Input
          placeholder={t('documentation.text.editorPlaceholder')}
          type="textarea"
          name={'content'}
          control={control}
          isLight
          isFullHeight
          isWithoutBorder
        />
      </SC.ScrollableWrapper>
    </SC.Wrapper>
  );
}
