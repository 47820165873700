import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { IBannerState } from './banner.types';

const initialState: IBannerState = {
  isTrainBannerVisible: false,
  isSaveBannerVisible: false,
  isDiscardModalVisible: false,
  invalidSaveForms: [],
  processingBanner: {
    isVisible: false,
    title: '',
    description: '',
  },
};

export const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    showDiscardModal: (state: IBannerState, action: PayloadAction<boolean>) => {
      state.isDiscardModalVisible = action.payload;
    },
    showTrainBanner: (state: IBannerState, action: PayloadAction<boolean>) => {
      state.isTrainBannerVisible = action.payload;
    },
    showSaveBanner: (state: IBannerState, action: PayloadAction<boolean>) => {
      state.isSaveBannerVisible = action.payload;
    },
    showProcessingBanner: (state: IBannerState, action: PayloadAction<IBannerState['processingBanner']>) => {
      state.processingBanner = action.payload;
    },
    addInvalidSaveForm: (state: IBannerState, action: PayloadAction<string>) => {
      state.invalidSaveForms = [...state.invalidSaveForms, action.payload];
    },
    deleteInvalidSaveForm: (state: IBannerState, action: PayloadAction<string>) => {
      state.invalidSaveForms = state.invalidSaveForms.filter(form => form !== action.payload);
    },
    discardChanges: () => {},
    leavePage: (state: IBannerState, action: PayloadAction<undefined>) => {},
    triggerBannerAction: (state: IBannerState, action: PayloadAction<string>) => {},
  },
});

export const {
  showSaveBanner,
  showDiscardModal,
  discardChanges,
  leavePage,
  showTrainBanner,
  showProcessingBanner,
  triggerBannerAction,
  addInvalidSaveForm,
  deleteInvalidSaveForm,
} = bannerSlice.actions;

export const bannerReducer: Reducer<IBannerState> = bannerSlice.reducer;
