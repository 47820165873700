import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import PromptModal from '../../components/PromptModal/PromptModal';
import Documents from '../../components/Documents/Documents';
import * as SC from './styles';
import { capitalize } from '../../common';
import WebScrapper from '../../components/WebScrapper/WebScrapper';
import { IDocumentationState } from '../../redux/documentation/documentation.types';
import {
  getText,
  saveText,
  showDeleteQaModal,
  closeDeleteQaModal,
  deleteQA,
  getQAList,
  scrapWebsite,
  getLinks,
  setDeleteAllLinksModalOpen,
  deleteAllLinks,
  deleteLink,
  scrapeSitemap,
  addIgnoreWord,
  deleteIgnoreWord,
} from '../../redux/documentation/documentation.redux';
import { useEffect } from 'react';
import TextEditor from '../../components/TextEditor/TextEditor';
// import QAList from '../../components/QAList/QAList';
import { TextSchemaType, textValidation, urlSchemaType } from '../../schemas';
import { useDocumentationMeta } from '../../hooks/useDocumentationMeta';
import { ROUTES } from '../../constants/routes';
import PageContent from '../../components/PageContent/PageContent';
import { ICompanyState } from '../../redux/company/company.types';
import { pushAction } from '../../redux/navigation/navigation.redux';
import { Typography } from '../../components/Typography/Typography';
import { useURLParams } from '../../hooks/navigation';
import Tabs, { ITabItem } from '../../components/Tabs/Tabs';

enum DataTabEnum {
  Documents = 'documents',
  Text = 'text',
  QA = 'qa',
  // Website = 'website',
}

export default function DocumentationPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { docTab, companyId: urlCompanyId } = useURLParams();
  const activeTab = (docTab || DataTabEnum.Documents) as DataTabEnum;
  const { id } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const companyId = urlCompanyId ? urlCompanyId : id;
  const {
    text,
    qAList,
    isDeleteQAModalOpen,
    links,
    isDeleteAllLinksModalOpen,
    ignoreWords,
    documentationMeta: { isScrapingWebsite, isProcessingDocument, changes },
  } = useSelector((state: { documentation: IDocumentationState }) => state.documentation);
  useDocumentationMeta(companyId);

  const tabs: ITabItem[] = Object.values(DataTabEnum).map(tab => ({
    onClick: () => handleTabClick(tab),
    title: t(`documentation.tabTitle${capitalize(tab)}`),
    name: tab,
  }));

  function handleSaveText(data: TextSchemaType) {
    dispatch(saveText(data.content));
  }

  function handleDeleteQA() {
    dispatch(deleteQA());
  }

  function handleCloseDeleteQA() {
    dispatch(closeDeleteQaModal());
  }

  function handleScrapWebsite(data: urlSchemaType) {
    dispatch(scrapWebsite(data.url));
  }

  function handleScrapeSitemap(data: urlSchemaType) {
    dispatch(scrapeSitemap(data.url));
  }

  function handleOpenDeleteAllLinksModal() {
    dispatch(setDeleteAllLinksModalOpen(true));
  }

  function handleCloseDeleteAllLinksModal() {
    dispatch(setDeleteAllLinksModalOpen(false));
  }

  function handleDeleteAllLinks() {
    dispatch(deleteAllLinks());
  }

  function handleDeleteLink(id: string) {
    dispatch(deleteLink(id));
  }

  function handleAddIgnoreWord(word: string) {
    dispatch(addIgnoreWord(word));
  }

  function handleDeleteIgnoreWord(word: string) {
    dispatch(deleteIgnoreWord(word));
  }

  useEffect(() => {
    if (companyId) {
      dispatch(getLinks(companyId));
    }
  }, [isScrapingWebsite]);

  useEffect(() => {
    if (companyId) {
      dispatch(getText(companyId));
      dispatch(getQAList(companyId));
      dispatch(getLinks(companyId));
    }
  }, [companyId]);

  function handleTabClick(tab: string) {
    if (activeTab === tab) return;
    let newPath = `${ROUTES.documentation.path}?docTab=${tab}`;
    if (urlCompanyId) {
      newPath += `&companyId=${urlCompanyId}`;
    }
    dispatch(pushAction(newPath));
  }

  function getPage() {
    switch (activeTab) {
      case DataTabEnum.Documents:
        return <></>;
      case DataTabEnum.Text:
        return <TextEditor onSubmit={handleSaveText} defaultValue={text.content} validationSchema={textValidation} />;
      // case DataTabEnum.Website:
      //   return (
      //     <WebScrapper
      //       onScrapWebsite={handleScrapWebsite}
      //       onScrapeSitemap={handleScrapeSitemap}
      //       links={links}
      //       disabled={isScrapingWebsite}
      //       onDeleteAllLinks={handleOpenDeleteAllLinksModal}
      //       onDeleteLink={handleDeleteLink}
      //       ignoreWords={ignoreWords}
      //       onAddIgnoreWord={handleAddIgnoreWord}
      //       onDeleteIgnoreWord={handleDeleteIgnoreWord}
      //     />
      //   );
      case DataTabEnum.QA:
        // return <QAList />;
        return null;
      default:
        return <></>;
    }
  }

  return (
    <Layout>
      {isDeleteQAModalOpen && (
        <PromptModal
          variant="danger"
          title={t('documentation.qa.deleteTitle')}
          questionKey="documentation.qa.deleteQuestion"
          name={''}
          cancelText={t('documentation.qa.deleteCancel')}
          submitText={t('documentation.qa.deleteSubmit')}
          onClose={handleCloseDeleteQA}
          onSubmit={handleDeleteQA}
        />
      )}
      {isDeleteAllLinksModalOpen && (
        <PromptModal
          variant="danger"
          questionKey="documentation.website.deleteAllLinksQuestion"
          name={''}
          title={t('documentation.website.deleteAllLinksTitle')}
          cancelText={t('documentation.website.deleteAllLinksCancel')}
          submitText={t('documentation.website.deleteAllLinksSubmit')}
          onClose={handleCloseDeleteAllLinksModal}
          onSubmit={handleDeleteAllLinks}
        />
      )}
      <PageContent>
        <SC.Inner>
          <SC.Header>
            <SC.HeaderRow>
              <Typography variant="h1" bold>
                {t('documentation.title')}
              </Typography>
            </SC.HeaderRow>
            <Tabs tabs={tabs} activeTab={activeTab} />
          </SC.Header>

          {getPage()}
        </SC.Inner>
      </PageContent>
    </Layout>
  );
}
