import { InputType } from '../components/Input/types';
import { IRadioFieldOption } from '../components/RadioField/RadioField';
import { Schema } from 'zod';

export interface FormField {
  name: string;
  defaultValue?: string | number;
  label: string;
  placeholder?: string;
  options?: IRadioFieldOption[];
  required?: boolean;
  validator?: Schema;
  disabled?: boolean;
  type?: InputType;
  uniqueEmail?: boolean;
}

export enum EDateFilter {
  today = 'today',
  yesterday = 'yesterday',
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
}
