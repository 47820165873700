import { useDispatch } from 'react-redux';
import { replaceAction } from '../../redux/navigation/navigation.redux';
import { ROUTES } from '../../constants/routes';
import * as SC from './styles';
import Button from '../../components/Button/Button';
import { IconName } from '../../components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../components/Typography/Typography';

export default function ErrorPage() {
  const { t } = useTranslation();

  function handleGoToHome() {
    window.location.replace(ROUTES.dashboard.path);
  }

  return (
    <SC.Wrapper>
      <SC.Content>
        <SC.Error>404</SC.Error>
        <SC.Text>
          <Typography variant="h1">{t('errorPage.title')}</Typography>
        </SC.Text>
        <Button onClick={handleGoToHome} icon={IconName.Home2} expand>
          {t('errorPage.button')}
        </Button>
      </SC.Content>
    </SC.Wrapper>
  );
}
