import * as SC from './styles';
import Button from '../Button/Button';
import Icon, { IconName } from '../Icon/Icon';
import { useColors } from '../../hooks/theme';
import { Typography } from '../Typography/Typography';

interface IEmptyContentProps {
  title: string;
  icon?: IconName;
  description?: string;
  buttonText?: string;
  showAction?: boolean;
  buttonIconName?: IconName;
  onButtonClick?: () => void;
}

function EmptyContent({
  title,
  icon,
  description,
  buttonText,
  showAction = true,
  buttonIconName = IconName.Plus,
  onButtonClick,
}: IEmptyContentProps) {
  const colors = useColors();

  return (
    <SC.EmptyContent>
      {icon && <Icon name={icon} width={8} height={8} color={colors.gray600} />}
      <SC.EmptyContentText>
        <Typography variant="h4">{title}</Typography>
        {showAction && description && <Typography variant="caption">{description}</Typography>}
      </SC.EmptyContentText>

      {showAction && buttonText && onButtonClick && (
        <Button icon={buttonIconName} onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </SC.EmptyContent>
  );
}
export default EmptyContent;
