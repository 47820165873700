import styled from 'styled-components';

export const ThemeItem = styled.div`
  width: min(100%, 53rem);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ThemeItemRow = styled.div`
  margin-top: 1.2rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;
