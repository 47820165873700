import { Reducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDeleteChatData, ITelegramChat, ITelegramChatWithId, ITelegramState } from './telegram.types';

const initialState: ITelegramState = {
  chats: [],
  activeChat: undefined,
  deleteChatData: undefined,
  isChatLoading: false,
};

export const telegramSlice = createSlice({
  name: 'telegram',
  initialState,
  reducers: {
    getChats: (state: ITelegramState, action: PayloadAction<string>) => {},
    setChats: (state: ITelegramState, action: PayloadAction<ITelegramChatWithId[]>) => {
      state.chats = action.payload;
    },
    getActiveChat: (state: ITelegramState, action: PayloadAction<number>) => {},
    setActiveChat: (state: ITelegramState, action: PayloadAction<ITelegramChatWithId | undefined>) => {
      state.activeChat = action.payload;
    },
    createChat: (state: ITelegramState, action: PayloadAction<ITelegramChat>) => {},
    updateChat: (state: ITelegramState, action: PayloadAction<ITelegramChatWithId>) => {},
    setDeleteChat: (state: ITelegramState, action: PayloadAction<IDeleteChatData | undefined>) => {
      state.deleteChatData = action.payload;
    },
    deleteChat: () => {},
    setIsChatLoading: (state: ITelegramState, action: PayloadAction<boolean>) => {
      state.isChatLoading = action.payload;
    },
  },
});

export const { getChats, setChats, getActiveChat, setActiveChat, createChat, updateChat, setDeleteChat, deleteChat, setIsChatLoading } =
  telegramSlice.actions;

export const telegramReducer: Reducer<ITelegramState> = telegramSlice.reducer;
