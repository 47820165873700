import React from 'react';
import * as SC from './styles';
import Icon, { IconName } from '../Icon/Icon';
import { TTypographyColor, Typography } from '../../components/Typography/Typography';

interface IActionButtonProps<T> {
  id: T;
  iconName: IconName;
  text: string;
  color?: TTypographyColor;
  onClick: (id: T) => void;
  disabled?: boolean;
}

export default function ActionButton<T>({ id, iconName, color, text, onClick, disabled = false }: IActionButtonProps<T>) {
  return (
    <SC.ActionButton onClick={() => onClick(id)} $disabled={disabled}>
      <Icon name={iconName} color={color} />
      <Typography variant="caption" color={color}>
        {text}
      </Typography>
    </SC.ActionButton>
  );
}
