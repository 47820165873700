import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { EFeatureFlag, IAuthUser, IAuthUserState, TFeatureConfig, UserRole } from './authUser.types';
import { AccountValidationType } from '../../schemas';

const enumToObject = (enumObj: any) => Object.keys(enumObj).reduce((obj, key) => ({ ...obj, [key]: false }), {});

const initialState: IAuthUserState = {
  user: {
    uid: '',
    photoUrl: '',
    displayName: '',
    email: '',
    role: UserRole.default,
    companyId: '',
    token: '',
  },
  featureFlags: enumToObject(EFeatureFlag),
  isResetPasswordEmailSent: false,
  isEditUserLoading: false,
};

export const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    logout: () => {
      return initialState;
    },
    setAuthUser: (state: IAuthUserState, action: PayloadAction<IAuthUser>) => {
      state.user = { ...state.user, ...action.payload };
    },
    updateAuthUser: (state: IAuthUserState, action: PayloadAction<AccountValidationType>) => {},
    setIsUserEditLoading: (state: IAuthUserState, action: PayloadAction<boolean>) => {
      state.isEditUserLoading = action.payload;
    },
    setFeatureFlags: (state: IAuthUserState, action: PayloadAction<TFeatureConfig>) => {
      state.featureFlags = action.payload;
    },
  },
});

export const { setAuthUser, logout, updateAuthUser, setIsUserEditLoading, setFeatureFlags } = authUserSlice.actions;

export const authUserReducer: Reducer<IAuthUserState> = authUserSlice.reducer;
