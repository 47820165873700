import { styled } from 'styled-components';

export const Agents = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const AgentsList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 1.4rem));
  justify-content: space-around;
  gap: 2.2rem;
`;

export const AgentsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const AgentsItemImage = styled.img`
  width: 100%;
  height: 30rem;

  object-fit: cover;
  border-radius: 1.2rem;
  @media (max-width: 1440px) {
    height: 18.8rem;
  }
`;

export const AgentsItemImageNull = styled.div`
  width: 100%;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${props => props.theme.colors.gray100};
  border-radius: 1.2rem;
  @media (max-width: 1440px) {
    height: 18.8rem;
  }
`;

export const AgentsItemHead = styled.div`
  margin: 0.8rem 0 0;
  display: grid;
  gap: 10px;
  align-items: center;
  grid-template-columns: 1fr auto;
`;

export const AgentsItemBody = styled.div`
  display: grid;
  gap: 10px;
  align-items: center;
  grid-template-columns: 1fr auto;
`;
