import { TinyEmitter } from 'tiny-emitter';

class EmitterWrapper {
  private emitter: TinyEmitter;
  private events: Set<string>;

  constructor() {
    this.emitter = new TinyEmitter();
    this.events = new Set();
  }

  on = (event: string, handler: (...args: any[]) => void) => {
    const subscription = this.emitter.on(event, handler);
    this.events.add(event);
    return subscription;
  };

  off = (event: string, handler: (...args: any[]) => void) => {
    this.emitter.off(event, handler);
    this.events.delete(event);
  };

  emit = (event: string, ...args: any[]) => {
    this.emitter.emit(event, ...args);
  };

  list = () => {
    return Array.from(this.events);
  };
}

export const formEmitter = new EmitterWrapper();
