import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import { setPageLoading } from '../loader/loader.redux';
import { t } from '../../config/i18n';
import { ICompanyState } from '../company/company.types';
import { showToast } from '../toasts/toasts.redux';
import {
  IGetTelegramNotificationResponse,
  ITelegramNotificationWithId,
  ITrelloWithId,
  ISmartmovingWithId,
  IGetTrelloResponse,
  IGetSmartmovingResponse,
  ITelegramNotification,
  ITrello,
  ISmartmoving,
  IGetAircallResponse,
  IAircall,
  IAircallWithId,
} from './integrations.types';
import {
  getTelegramNotificationRequest,
  createTelegramNotificationRequest,
  updateTelegramNotificationRequest,
  getTrelloRequest,
  createTrelloRequest,
  updateTrelloRequest,
  getSmartmovingRequest,
  createSmartmovingRequest,
  updateSmartmovingRequest,
  getAircallRequest,
  createAircallRequest,
  updateAircallRequest,
} from './integrations.service';
import {
  getActiveTelegramNotification,
  setActiveTelegramNotification,
  createTelegramNotification,
  updateTelegramNotification,
  getActiveTrello,
  setActiveTrello,
  createTrello,
  updateTrello,
  getActiveSmartmoving,
  setActiveSmartmoving,
  createSmartmoving,
  updateSmartmoving,
  setIsSmartmovingLoading,
  setIsTrelloLoading,
  setIsTelegramNotificationLoading,
  getActiveAircall,
  createAircall,
  updateAircall,
  setActiveAircall,
  setIsAircallLoading,
} from './integrations.redux';
import { IBaseResponse } from '../../types';

// TelegramNotification
export function* getActiveTelegramNotificationSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  yield put(setIsTelegramNotificationLoading(true));
  const { success, telegramNotification }: IGetTelegramNotificationResponse = yield call(getTelegramNotificationRequest, companyId);

  if (success) {
    yield put(setActiveTelegramNotification(telegramNotification));
  } else {
    // yield put(
    //   showToast({
    //     type: 'error',
    //     message: t('telegramNotification.getFailed'),
    //   }),
    // );
  }
  yield put(setIsTelegramNotificationLoading(false));
}

export function* createTelegramNotificationSaga({ payload: telegramNotification }: PayloadAction<ITelegramNotification>): SagaIterator {
  yield put(setIsTelegramNotificationLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success }: IBaseResponse = yield call(createTelegramNotificationRequest, companyId, telegramNotification);

  yield put(getActiveTelegramNotification(companyId));
  yield put(setIsTelegramNotificationLoading(false));
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('telegramNotifications.created') : t('telegramNotifications.createFailed'),
    }),
  );
}

export function* updateTelegramNotificationSaga({ payload: notification }: PayloadAction<ITelegramNotificationWithId>): SagaIterator {
  yield put(setIsTelegramNotificationLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success }: IBaseResponse = yield call(updateTelegramNotificationRequest, companyId, notification);

  yield put(getActiveTelegramNotification(companyId));
  yield put(setIsTelegramNotificationLoading(false));
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('telegramNotifications.updated') : t('telegramNotifications.updateFailed'),
    }),
  );
}

// Trello

export function* getActiveTrelloSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  yield put(setIsTrelloLoading(true));
  const { success, trello }: IGetTrelloResponse = yield call(getTrelloRequest, companyId);

  if (success) {
    yield put(setActiveTrello(trello));
  } else {
    // yield put(
    //   showToast({
    //     type: 'error',
    //     message: t('trello.getFailed'),
    //   }),
    // );
  }
  yield put(setIsTrelloLoading(false));
}

export function* createTrelloSaga({ payload: trello }: PayloadAction<ITrello>): SagaIterator {
  yield put(setIsTrelloLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success }: IBaseResponse = yield call(createTrelloRequest, companyId, trello);

  yield put(getActiveTrello(companyId));
  yield put(setIsTrelloLoading(false));
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('trello.created') : t('trello.createFailed'),
    }),
  );
}

export function* updateTrelloSaga({ payload: trello }: PayloadAction<ITrelloWithId>): SagaIterator {
  yield put(setIsTrelloLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success }: IBaseResponse = yield call(updateTrelloRequest, companyId, trello);

  yield put(getActiveTrello(companyId));
  yield put(setIsTrelloLoading(false));
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('trello.updated') : t('trello.updateFailed'),
    }),
  );
}

// Smartmoving

export function* getActiveSmartmovingSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  yield put(setIsSmartmovingLoading(true));
  const { success, smartmoving }: IGetSmartmovingResponse = yield call(getSmartmovingRequest, companyId);

  if (success) {
    yield put(setActiveSmartmoving(smartmoving));
  } else {
    // yield put(
    //   showToast({
    //     type: 'error',
    //     message: t('smartmoving.getFailed'),
    //   }),
    // );
  }
  yield put(setIsSmartmovingLoading(false));
}

export function* createSmartmovingSaga({ payload: smartmoving }: PayloadAction<ISmartmoving>): SagaIterator {
  yield put(setIsSmartmovingLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success }: IBaseResponse = yield call(createSmartmovingRequest, companyId, smartmoving);

  yield put(getActiveSmartmoving(companyId));
  yield put(setIsSmartmovingLoading(false));
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('smartmoving.created') : t('smartmoving.createFailed'),
    }),
  );
}

export function* updateSmartmovingSaga({ payload: smartmoving }: PayloadAction<ISmartmovingWithId>): SagaIterator {
  yield put(setIsSmartmovingLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success }: IBaseResponse = yield call(updateSmartmovingRequest, companyId, smartmoving);

  yield put(getActiveSmartmoving(companyId));
  yield put(setIsSmartmovingLoading(false));
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('smartmoving.updated') : t('smartmoving.updateFailed'),
    }),
  );
}

// Aircall

export function* getActiveAircallSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  yield put(setIsAircallLoading(true));
  const { success, aircall }: IGetAircallResponse = yield call(getAircallRequest, companyId);

  if (success) {
    yield put(setActiveAircall(aircall));
  } else {
    // yield put(
    //   showToast({
    //     type: 'error',
    //     message: t('aircall.getFailed'),
    //   }),
    // );
  }
  yield put(setIsAircallLoading(false));
}

export function* createAircallSaga({ payload: aircall }: PayloadAction<IAircall>): SagaIterator {
  yield put(setIsAircallLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success }: IBaseResponse = yield call(createAircallRequest, companyId, aircall);

  yield put(getActiveAircall(companyId));
  yield put(setIsAircallLoading(false));
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('aircall.created') : t('aircall.createFailed'),
    }),
  );
}

export function* updateAircallSaga({ payload: aircall }: PayloadAction<IAircallWithId>): SagaIterator {
  yield put(setIsAircallLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success }: IBaseResponse = yield call(updateAircallRequest, companyId, aircall);

  yield put(getActiveAircall(companyId));
  yield put(setIsAircallLoading(false));
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('aircall.updated') : t('aircall.updateFailed'),
    }),
  );
}

export function* initIntegrationsSagas(): SagaIterator {
  yield takeEvery(getActiveTelegramNotification.toString(), getActiveTelegramNotificationSaga);
  yield takeEvery(createTelegramNotification.toString(), createTelegramNotificationSaga);
  yield takeEvery(updateTelegramNotification.toString(), updateTelegramNotificationSaga);

  yield takeEvery(getActiveTrello.toString(), getActiveTrelloSaga);
  yield takeEvery(createTrello.toString(), createTrelloSaga);
  yield takeEvery(updateTrello.toString(), updateTrelloSaga);

  yield takeEvery(getActiveSmartmoving.toString(), getActiveSmartmovingSaga);
  yield takeEvery(createSmartmoving.toString(), createSmartmovingSaga);
  yield takeEvery(updateSmartmoving.toString(), updateSmartmovingSaga);

  yield takeEvery(getActiveAircall.toString(), getActiveAircallSaga);
  yield takeEvery(createAircall.toString(), createAircallSaga);
  yield takeEvery(updateAircall.toString(), updateAircallSaga);
}
