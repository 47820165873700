import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  createUser,
  deleteUser,
  getUsers,
  setUsers,
  showRemoveConfirmModal,
  updateUser,
  setActiveUserId,
  setIsUserEditLoading,
  setIsLoadingUsers,
  getActiveUser,
  setActiveUser,
  sendPasswordResetEmail,
  setResetPasswordEmail,
} from './users.redux';
import {
  createUserRequest,
  deleteUsersRequest,
  getUserRequest,
  getUsersRequest,
  removeUserRequest,
  sendPasswordResetEmailRequest,
  updateUserRequest,
} from './users.service';
import { ICreateUser, IGetUserResponse, IUpdateUser, IUsersState, UserRole } from './users.types';
import { logger } from '../../logger';
import { PayloadAction } from '@reduxjs/toolkit';
import { closeModalLoading, showModalLoading } from '../loader/loader.redux';
import { deleteImagesRequest, uploadImageRequest } from '../shared-services/image.service';
import { t } from 'i18next';
import { IAuthUserState } from '../authUser/authUser.types';
import { setAuthUser } from '../authUser/authUser.redux';
import { ICompanyState } from '../company/company.types';
import { showToast } from '../toasts/toasts.redux';
import { showSaveBanner } from '../banner/banner.redux';
import { ROUTES } from '../../constants/routes';
import { replace } from 'redux-first-history';

export function* getUsersSaga(): SagaIterator {
  yield put(setIsLoadingUsers(true));
  try {
    const { id } = yield select((state: { company: ICompanyState }) => state.company.company);
    const users = yield call(getUsersRequest, id);
    yield put(setUsers(users));
  } catch (e) {
    logger.error('getUsersSaga', e);
  }
  yield put(setIsLoadingUsers(false));
}

export function* getActiveUserSaga({ payload: userId }: PayloadAction<string>): SagaIterator {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success, user }: IGetUserResponse = yield call(getUserRequest, companyId, userId);

  if (success && user) {
    yield put(setActiveUser({ uid: userId, displayName: user.displayName, photoUrl: user.photoUrl, exists: true }));
  } else {
    yield put(setActiveUser({ uid: userId, exists: false }));
  }
}

export function* createUserSaga({ payload: { companyId, user } }: PayloadAction<ICreateUser>): SagaIterator {
  yield put(setIsUserEditLoading(true));
  try {
    yield call(createUserRequest, { companyId, user: { ...user, photoUrl: user.photoUrl ? user.photoUrl : undefined } });

    if (user.role !== UserRole.default) {
      yield call(sendPasswordResetEmailRequest, user.email);
    }
    yield put(showSaveBanner(false));

    yield put(getUsers());
    yield put(showToast({ message: t('users.createUserSuccess') }));
    yield put(replace(`${ROUTES.companySettings.path}?tab=users&companyId=${companyId}`));
  } catch (e) {
    logger.error('createUserSaga', e);
  }
  yield put(setIsUserEditLoading(false));
}

export function* deleteUserSaga(): SagaIterator {
  yield put(showRemoveConfirmModal(false));
  yield put(showModalLoading(t('users.deleteUserLoading')));
  let success = false;
  try {
    const { activeUserId } = yield select((state: { users: IUsersState }) => state.users);
    const {
      company: { id: companyId },
    } = yield select((state: { company: ICompanyState }) => state.company);

    success = yield call(deleteUsersRequest, [activeUserId]);
    yield call(removeUserRequest, activeUserId, companyId);
    yield put(setActiveUserId(''));
  } catch (e) {
    logger.error('deleteUserSaga', e);
  }

  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('users.deleteUserSuccess') : t('users.deleteUserFailed'),
    }),
  );

  yield put(closeModalLoading());
}

export function* updateUserSaga({ payload: { companyId, user } }: PayloadAction<IUpdateUser>): SagaIterator {
  yield put(setIsUserEditLoading(true));
  try {
    const { uid: authUserUid } = yield select((state: { authUser: IAuthUserState }) => state.authUser.user);
    const { photoUrl: oldPhotoUrl } = yield select((state: { users: IUsersState }) =>
      state.users.users.find(item => item.uid === user.uid),
    );
    const isPhotoUrlChanged = oldPhotoUrl !== user.photoUrl;
    let newPhotoUrl = oldPhotoUrl;

    if (isPhotoUrlChanged && !!user.photoUrl) {
      newPhotoUrl = (yield call(uploadImageRequest, companyId as string, 'users', user.uid, user.photoUrl)).image;
    }

    if (isPhotoUrlChanged && !user.photoUrl && oldPhotoUrl?.includes('http')) {
      yield call(deleteImagesRequest, [oldPhotoUrl]);
      newPhotoUrl = '';
    }

    yield call(updateUserRequest, {
      companyId,
      user: {
        ...user,
        photoUrl: newPhotoUrl ? newPhotoUrl : null,
      },
    });
    if (user.uid === authUserUid) {
      yield put(setAuthUser({ ...user, companyId, photoUrl: newPhotoUrl ? newPhotoUrl : '' }));
    }
    yield put(getUsers());
    yield put(setActiveUserId(''));
    yield put(showSaveBanner(false));
    yield put(showToast({ message: t('users.updateUserSuccess') }));
    yield put(replace(`${ROUTES.companySettings.path}?tab=users&companyId=${companyId}`));
  } catch (e) {
    logger.error('updateUserSaga', e);
  }
  yield put(setIsUserEditLoading(false));
}

export function* sendPasswordResetEmailSaga({ payload: email }: PayloadAction<string>): SagaIterator {
  const success = yield call(sendPasswordResetEmailRequest, email);

  if (success) {
    yield put(setResetPasswordEmail(email));
  }

  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('users.resetPasswordSuccess') : t('users.resetPasswordFailed'),
    }),
  );
}

export function* initUsersSagas(): SagaIterator {
  yield takeEvery(getUsers.toString(), getUsersSaga);
  yield takeEvery(createUser.toString(), createUserSaga);
  yield takeEvery(deleteUser.toString(), deleteUserSaga);
  yield takeEvery(updateUser.toString(), updateUserSaga);
  yield takeEvery(getActiveUser.toString(), getActiveUserSaga);
  yield takeEvery(sendPasswordResetEmail.toString(), sendPasswordResetEmailSaga);
}
