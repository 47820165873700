import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { IDocumentationState, ILinks, IText, IDocumentationMeta } from './documentation.types';
import shortUUID from 'short-uuid';
import { IQAWithId } from '../qa/qa.types';

const initialState: IDocumentationState = {
  text: {
    charCount: 0,
    content: '',
  },
  qAList: [],
  openedQAIds: [],
  deleteQAId: '',
  isDeleteQAModalOpen: false,
  links: {},
  isDeleteAllLinksModalOpen: false,
  ignoreWords: [],
  documentationMeta: {
    isScrapingWebsite: false,
    isProcessingDocument: false,
    isAgentTraining: false,
    changes: [],
  },
};

export const documentationSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {
    getText: (state: IDocumentationState, action: PayloadAction<string>) => {},
    setText: (state: IDocumentationState, action: PayloadAction<IText>) => {
      state.text = action.payload;
    },
    saveText: (state: IDocumentationState, action: PayloadAction<string>) => {},
    getQAList: (state: IDocumentationState, action: PayloadAction<string>) => {},
    setQAList: (state: IDocumentationState, action: PayloadAction<IQAWithId[]>) => {
      state.qAList = action.payload ?? [];
    },
    addNewQA: (state: IDocumentationState) => {
      const id = shortUUID.generate();
      // state.qAList.push({ id, question: '', answer: '', charCount: 0 });
      state.openedQAIds.push(id);
    },
    openQA: (state: IDocumentationState, action: PayloadAction<string>) => {
      const newId = action.payload;
      if (state.openedQAIds.includes(newId)) {
        state.openedQAIds = state.openedQAIds.filter(id => id !== newId);
      } else {
        state.openedQAIds = [...state.openedQAIds, newId];
      }
    },
    resetOpenedQAs: (state: IDocumentationState) => {
      state.openedQAIds = [];
    },
    saveQA: (state: IDocumentationState, action: PayloadAction<Omit<IQAWithId, 'charCount'>>) => {},
    deleteQA: () => {},
    showDeleteQaModal: (state: IDocumentationState, action: PayloadAction<string>) => {
      state.deleteQAId = action.payload;
      state.isDeleteQAModalOpen = true;
    },
    closeDeleteQaModal: (state: IDocumentationState) => {
      state.deleteQAId = '';
      state.isDeleteQAModalOpen = false;
    },
    scrapWebsite: (state: IDocumentationState, action: PayloadAction<string>) => {},
    scrapeSitemap: (state: IDocumentationState, action: PayloadAction<string>) => {},
    getLinks: (state: IDocumentationState, action: PayloadAction<string>) => {},
    setLinks: (state: IDocumentationState, action: PayloadAction<ILinks>) => {
      state.links = action.payload;
    },
    setDeleteAllLinksModalOpen: (state: IDocumentationState, action: PayloadAction<boolean>) => {
      state.isDeleteAllLinksModalOpen = action.payload;
    },
    deleteAllLinks: () => {},
    deleteLink: (state: IDocumentationState, action: PayloadAction<string>) => {
      delete state.links[action.payload];
    },
    addIgnoreWord: (state: IDocumentationState, action: PayloadAction<string>) => {
      state.ignoreWords.push(action.payload);
    },
    deleteIgnoreWord: (state: IDocumentationState, action: PayloadAction<string>) => {
      state.ignoreWords = state.ignoreWords.filter(word => word !== action.payload);
    },

    setDocumentationMeta: (state: IDocumentationState, action: PayloadAction<IDocumentationMeta>) => {
      state.documentationMeta = action.payload;
    },
    trainAgent: () => {},
  },
});

export const {
  setText,
  getText,
  saveText,
  getQAList,
  setQAList,
  saveQA,
  deleteQA,
  addNewQA,
  openQA,
  resetOpenedQAs,
  showDeleteQaModal,
  closeDeleteQaModal,
  scrapWebsite,
  getLinks,
  setLinks,
  setDeleteAllLinksModalOpen,
  deleteAllLinks,
  deleteLink,
  scrapeSitemap,
  addIgnoreWord,
  deleteIgnoreWord,
  setDocumentationMeta,
  trainAgent,
} = documentationSlice.actions;

export const documentationReducer: Reducer<IDocumentationState> = documentationSlice.reducer;
