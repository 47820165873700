import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { initSagas } from './rootSaga';
import rootReducer from './rootReducer';
import { createReduxHistory, routerMiddleware } from './history';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['sidebar'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false, thunk: false }).prepend(sagaMiddleware).concat(routerMiddleware),
});

sagaMiddleware.run(initSagas);

export const persistor = persistStore(store);
export const history = createReduxHistory(store);
