import { TButtonVariant } from '../Button/types';
import Card from '../Card/Card';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { Typography } from '../Typography/Typography';

interface IActionCardProps {
  title: string;
  description: string;
  buttonText: string;
  variant: TButtonVariant;
  onClick: () => void;
}

export default function ActionCard({ title, description, buttonText, variant, onClick }: IActionCardProps) {
  return (
    <Card>
      <Typography variant="h4">{title}</Typography>
      <SC.DescWrapper>
        <Typography variant="body">{description}</Typography>
      </SC.DescWrapper>
      <Button onClick={onClick} variant={variant}>
        {buttonText}
      </Button>
    </Card>
  );
}
