import styled from 'styled-components';
import { WithCustomScrollbar } from '../../GlobalStyle';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 2rem;
  justify-items: flex-end;

  padding: ${({ theme }) => theme.spacing.s3};
  padding-bottom: ${({ theme }) => theme.spacing.s6};
  border: 0.1rem solid ${({ theme }) => theme.colors.border.dark};
  border-radius: ${({ theme }) => theme.radius.l};
  height: 100%;
  overflow: hidden;
`;

export const ScrollableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  ${WithCustomScrollbar}
`;
