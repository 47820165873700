import { IAgent, IAgentWithId, convertToSnakeCase } from '@tuler/shared';
import axiosInstance from '../../config/axios';
import { logger } from '../../logger';
import FirestoreService from '../../services/firestore.service';

export class AgentsService extends FirestoreService {
  constructor() {
    super('agents');
  }

  public getAll = async (companyId: string) => {
    try {
      return (await this.queryByField('company_id', companyId)) as IAgentWithId[];
    } catch (e) {
      logger.error('Agents.getAll', e);
    }
  };

  public deleteAll = async (companyId: string): Promise<void> => {
    try {
      await axiosInstance.delete(`agents/${companyId}`);
    } catch (e) {
      logger.error('Agents.deleteAll', e);
    }
  };
}

export class AgentService extends FirestoreService {
  constructor(agentId?: string) {
    super('agents', agentId);
  }

  public create = async (companyId: string, agentData: IAgent) => {
    try {
      const res = await axiosInstance.post(`agents/create`, convertToSnakeCase({ companyId, agentData }));
      return res.data.success;
    } catch (e) {
      logger.error('Agent.createAgent', e);
    }
  };

  public update = async (companyId: string, agentId: string, agentData: Partial<IAgent>) => {
    try {
      const res = await axiosInstance.patch(`agents/update`, convertToSnakeCase({ companyId, agentId, agentData }));
      return res.data.success;
    } catch (e) {
      logger.error('Agent.updateAgent', e);
    }
  };

  public delete = async (companyId: string, agentId: string): Promise<void> => {
    try {
      const res = await axiosInstance.delete(`agents/${companyId}/${agentId}`);
      return res.data.success;
    } catch (e) {
      logger.error('Agent.deleteAgent', e);
    }
  };
}
