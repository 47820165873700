import { styled } from 'styled-components';

export const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s1};

  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    filter: brightness(120%);
  }
`;
