import { convertToCamelCase, convertToSnakeCase } from '@tuler/shared';
import axiosInstance from '../../config/axios';
import { logger } from '../../logger';
import { IGetTelegramChatResponse, IGetTelegramChatsResponse, ITelegramChat, ITelegramChatWithId } from './telegram.types';

export async function getChatsRequest(companyId: string): Promise<IGetTelegramChatsResponse> {
  try {
    const res = await axiosInstance.get(`telegram/${companyId}`);
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('createChatRequest', e);
    return { success: false };
  }
}

export async function getChatRequest(companyId: string, id: number): Promise<IGetTelegramChatResponse> {
  try {
    const res = await axiosInstance.get(`telegram/${companyId}/chat`, { params: convertToSnakeCase({ id }) });
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('createChatRequest', e);
    return { success: false };
  }
}

export async function createChatRequest(companyId: string, chat: ITelegramChat) {
  try {
    const res = await axiosInstance.post(`telegram/${companyId}/chat`, convertToSnakeCase(chat));
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('createChatRequest', e);
    return { success: false };
  }
}

export async function updateChatRequest(companyId: string, chat: ITelegramChatWithId) {
  try {
    const res = await axiosInstance.patch(`telegram/${companyId}/chat`, convertToSnakeCase(chat));
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('updateChatRequest', e);
    return { success: false };
  }
}

export async function deleteChatRequest(companyId: string, id: number) {
  try {
    const res = await axiosInstance.delete(`telegram/${companyId}/chat`, { params: convertToSnakeCase({ id }) });
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('updateChatRequest', e);
    return { success: false };
  }
}
