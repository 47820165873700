import { ReactNode } from 'react';
import * as SC from './styles';

type TTypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'subtitle1' | 'placeholder' | 'caption' | 'body' | 'caption' | 'label' | 'message';

export type TTypographyColor = 'default' | 'body' | 'secondary' | 'light' | 'brand' | 'error' | 'success' | 'warning';

interface ITypographyProps {
  variant: TTypographyVariant;
  color?: TTypographyColor;
  bold?: boolean;
  children: ReactNode;
  ellipsis?: boolean;
  uppercase?: boolean;
  tag?: keyof JSX.IntrinsicElements;
  isPre?: boolean;
}

export function Typography({ variant, bold = false, color, ellipsis, children, uppercase = false, tag, isPre = false }: ITypographyProps) {
  const variants = Object.keys(SC);
  const current = variants.find(name => name === variant.charAt(0).toUpperCase() + variant.slice(1));

  const Component = SC[current as keyof typeof SC];
  return (
    <Component as={tag} $ellipsis={ellipsis} $bold={bold} $color={color} $uppercase={uppercase} $isPre={isPre}>
      {children}
    </Component>
  );
}
