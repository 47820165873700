import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const TooltipStyled = styled(Tooltip)`
  padding: 0.8rem 1.2rem !important;
  max-width: 28rem !important;

  color: ${({ theme }) => theme.colors.white} !important;
  font-size: 1.2rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 150% !important;

  border-radius: 0.4rem !important;
`;
