import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  CollectionReference,
  DocumentData,
  DocumentReference,
  Firestore,
  Query,
  QuerySnapshot,
  WhereFilterOp,
  setDoc,
  deleteDoc,
  writeBatch,
  orderBy,
  runTransaction,
  addDoc,
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { ConvertToCamelCase, ConvertToSnakeCase, convertToSnakeCase } from '@tuler/shared';
import { logger } from '../logger';

interface IQueryCondition {
  fieldName: string;
  operator: WhereFilterOp;
  fieldValue: unknown;
}

type SortOption = {
  field: string;
  direction: 'asc' | 'desc';
};

export default class FirestoreService {
  _db: Firestore;
  collection: string;
  collectionRef: CollectionReference<DocumentData>;
  docRef?: DocumentReference<DocumentData>;

  constructor(collectionName: string, docId?: string, subCollectionName?: string, subDocId?: string) {
    this._db = db;
    this.collection = collectionName;
    this.collectionRef = collection(this._db, this.collection);

    if (docId) {
      this.docRef = doc(this._db, this.collection, docId);
    }

    if (subCollectionName) {
      this.collectionRef = collection(this._db, `${this.collection}/${docId}/${subCollectionName}`);
    }

    if (subDocId) {
      this.docRef = doc(this._db, `${this.collection}/${docId}/${subCollectionName}/${subDocId}`);
    }
  }

  private async _getDocsFromQuery(query: Query<DocumentData>): Promise<QuerySnapshot<DocumentData>> {
    const res: any[] = [];
    const querySnapshot = await getDocs(query);
    querySnapshot.forEach(d => {
      res.push({ id: d.id, ...d.data() });
    });
    return res as unknown as QuerySnapshot<DocumentData>;
  }

  @ConvertToSnakeCase()
  protected async updateWithTransaction(updateFunction: (currentData: any) => any): Promise<any> {
    try {
      if (!this.docRef) return {};
      const docRef = this.docRef as DocumentReference<any>;

      return runTransaction(
        this._db,
        async transaction => {
          const docSnap = await transaction.get(docRef);

          if (docSnap.exists()) {
            const currentData = docSnap.data();
            const newData = updateFunction(currentData);
            transaction.update(docRef, newData);
          }

          return docSnap;
        },
        { maxAttempts: 10 },
      );
    } catch (e) {
      logger.error('updateWithTransaction', e);
      return {};
    }
  }

  @ConvertToCamelCase()
  protected async queryByField(fieldName: string, fieldValue: string, operator: WhereFilterOp = '=='): Promise<unknown[]> {
    try {
      const dbQuery = query(this.collectionRef, where(fieldName, operator, fieldValue));
      return (await this._getDocsFromQuery(dbQuery)) as unknown as unknown[];
    } catch (e) {
      logger.error('queryByField', e);
      return [];
    }
  }

  @ConvertToCamelCase()
  protected async queryByMultipleFields(conditions: IQueryCondition[]): Promise<unknown[]> {
    try {
      const dbQuery = query(
        this.collectionRef,
        ...conditions.map(condition => where(condition.fieldName, condition.operator, condition.fieldValue)),
      );

      return (await this._getDocsFromQuery(dbQuery)) as unknown as unknown[];
    } catch (e) {
      logger.error('queryByMultipleFields', e);
      return [];
    }
  }

  @ConvertToCamelCase()
  protected async queryAll(sort?: SortOption): Promise<unknown[]> {
    try {
      let dbQuery = query(this.collectionRef);

      if (sort) {
        const field = convertToSnakeCase(sort.field);
        dbQuery = query(dbQuery, orderBy(field, sort.direction));
      }

      return (await this._getDocsFromQuery(dbQuery)) as unknown as unknown[];
    } catch (e) {
      logger.error('queryAll', e);
      return [];
    }
  }

  @ConvertToCamelCase()
  protected async getAllDocs(): Promise<unknown[]> {
    try {
      const querySnapshot = await getDocs(this.collectionRef);
      const res: unknown[] = [];

      querySnapshot.forEach((doc: DocumentData) => {
        res.push({ id: doc.id, ...doc.data() } as unknown);
      });

      return res;
    } catch (e) {
      logger.error('getAllDocs', e);
      return [];
    }
  }

  @ConvertToCamelCase()
  protected async getDoc(): Promise<unknown> {
    try {
      if (!this.docRef) return {};

      const docSnap = await getDoc(this.docRef);

      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() } as unknown;
      }

      return {};
    } catch (e) {
      logger.error('getDoc', e);
      return {};
    }
  }

  @ConvertToSnakeCase()
  protected async updateDoc(data: any): Promise<any> {
    try {
      if (!this.docRef) return {};
      const docSnap = await updateDoc(this.docRef, data);
      return docSnap;
    } catch (e) {
      logger.error('updateDoc', e);
      return {};
    }
  }

  @ConvertToSnakeCase()
  protected async upsertDoc(data: any): Promise<any> {
    try {
      if (!this.docRef) return {};
      const docSnap = await setDoc(this.docRef, data);
      return docSnap;
    } catch (e) {
      logger.error('upsertDoc', e);
      return {};
    }
  }

  @ConvertToSnakeCase()
  protected async addDoc(data: any): Promise<any> {
    try {
      const docSnap = await addDoc(this.collectionRef, data);
      return docSnap;
    } catch (e) {
      logger.error('addDoc', e);
      return {};
    }
  }

  protected async deleteDocById(id: string): Promise<any> {
    try {
      await deleteDoc(doc(this.collectionRef, id));
    } catch (e) {
      logger.error('deleteDoc', e);
    }
  }

  protected async deleteDoc(): Promise<any> {
    try {
      if (!this.docRef) return false;
      await deleteDoc(this.docRef);
    } catch (e) {
      logger.error('deleteDoc', e);
    }
  }

  protected async deleteAllDocs(): Promise<any> {
    try {
      const querySnapshot = await getDocs(this.collectionRef);

      let batch = writeBatch(db);
      let operationCount = 0;

      for (const doc of querySnapshot.docs) {
        batch.delete(doc.ref);
        operationCount++;
        if (operationCount >= 500) {
          await batch.commit();
          batch = writeBatch(db);
          operationCount = 0;
        }
      }

      if (operationCount > 0) {
        await batch.commit();
      }
    } catch (e) {
      logger.error('deleteAllDocs', e);
    }
  }
}
