import { styled } from 'styled-components';

export const PaginationContainer = styled.div`
  .pagination {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.gray300};
    border-radius: 4px;
    user-select: none;
  }
  .page-item {
    width: 36px;
    height: 33px;

    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    list-style: none;

    color: ${props => props.theme.colors.gray500};
    border-right: 1px solid ${props => props.theme.colors.gray300};
    cursor: pointer;
    a {
      width: 36px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .page-item:last-child {
    border-right: 0;
  }
  .page-item.active {
    color: ${props => props.theme.colors.primary500};
    background: ${props => props.theme.colors.primary100};
  }
  .page-item:hover {
    background: ${props => props.theme.colors.primary50};
  }
  .page-arrow {
    width: 44px;
    a {
      width: 44px;
    }
  }
`;
