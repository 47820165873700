import axiosInstance from '../../config/axios';
import { logger } from '../../logger';
import {
  IAircall,
  IAircallWithId,
  IGetAircallResponse,
  IGetSmartmovingResponse,
  IGetTelegramNotificationResponse,
  IGetTrelloResponse,
  ISmartmoving,
  ISmartmovingWithId,
  ITelegramNotification,
  ITelegramNotificationWithId,
  ITrello,
  ITrelloWithId,
} from './integrations.types';
import { convertToCamelCase, convertToSnakeCase } from '@tuler/shared';

// Telegram Notifications

export async function getTelegramNotificationRequest(companyId: string): Promise<IGetTelegramNotificationResponse> {
  try {
    const res = await axiosInstance.get(`integrations/telegram-notification/${companyId}`);
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('getTelegramNotificationRequest', e);
    return { success: false };
  }
}

export async function createTelegramNotificationRequest(companyId: string, telegramNotification: ITelegramNotification) {
  try {
    const res = await axiosInstance.post(`integrations/telegram-notification/${companyId}`, convertToSnakeCase({ telegramNotification }));
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('createTelegramNotificationRequest', e);
    return { success: false };
  }
}

export async function updateTelegramNotificationRequest(companyId: string, telegramNotification: ITelegramNotificationWithId) {
  try {
    const res = await axiosInstance.patch(`integrations/telegram-notification/${companyId}`, convertToSnakeCase({ telegramNotification }));
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('updateTelegramNotificationRequest', e);
    return { success: false };
  }
}

// Trello

export async function getTrelloRequest(companyId: string): Promise<IGetTrelloResponse> {
  try {
    const res = await axiosInstance.get(`integrations/trello/${companyId}`);
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('getTrelloRequest', e);
    return { success: false };
  }
}

export async function createTrelloRequest(companyId: string, trello: ITrello) {
  try {
    const res = await axiosInstance.post(`integrations/trello/${companyId}`, convertToSnakeCase({ trello }));
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('createTrelloRequest', e);
    return { success: false };
  }
}

export async function updateTrelloRequest(companyId: string, trello: ITrelloWithId) {
  try {
    const res = await axiosInstance.patch(`integrations/trello/${companyId}`, convertToSnakeCase({ trello }));
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('updateTrelloRequest', e);
    return { success: false };
  }
}

// Smartmoving

export async function getSmartmovingRequest(companyId: string): Promise<IGetSmartmovingResponse> {
  try {
    const res = await axiosInstance.get(`integrations/smartmoving/${companyId}`);
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('getSmartmovingRequest', e);
    return { success: false };
  }
}

export async function createSmartmovingRequest(companyId: string, smartmoving: ISmartmoving) {
  try {
    const res = await axiosInstance.post(`integrations/smartmoving/${companyId}`, convertToSnakeCase({ smartmoving }));
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('createSmartmovingRequest', e);
    return { success: false };
  }
}

export async function updateSmartmovingRequest(companyId: string, smartmoving: ISmartmovingWithId) {
  try {
    const res = await axiosInstance.patch(`integrations/smartmoving/${companyId}`, convertToSnakeCase({ smartmoving }));
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('updateSmartmovingRequest', e);
    return { success: false };
  }
}

// Aircall

export async function getAircallRequest(companyId: string): Promise<IGetAircallResponse> {
  try {
    const res = await axiosInstance.get(`integrations/aircall/${companyId}`);
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('getAircallRequest', e);
    return { success: false };
  }
}

export async function createAircallRequest(companyId: string, aircall: IAircall) {
  try {
    const res = await axiosInstance.post(`integrations/aircall/${companyId}`, convertToSnakeCase({ aircall }));
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('createAircallRequest', e);
    return { success: false };
  }
}

export async function updateAircallRequest(companyId: string, aircall: IAircallWithId) {
  try {
    const res = await axiosInstance.patch(`integrations/aircall/${companyId}`, convertToSnakeCase({ aircall }));
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('updateAircallRequest', e);
    return { success: false };
  }
}
