import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IAuthUserState } from '../../redux/authUser/authUser.types';
import { updateAuthUser } from '../../redux/authUser/authUser.redux';
import { AccountValidationType, accountValidation } from '../../schemas';
import EntityForm from '../../components/EntityForm/EntityForm';
import ResetPassword from '../../components/ResetPassword/ResetPassword';
import * as SC from './styles';
import Layout from '../../components/Layout/Layout';
import Card from '../../components/Card/Card';
import { Typography } from '../../components/Typography/Typography';
import { FormField } from '../../common/types';
import { sendPasswordResetEmail } from '../../redux/users/users.redux';
import { IUsersState } from '../../redux/users/users.types';

export default function AccountSettingsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, isEditUserLoading } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const { resetPasswordSentUsers } = useSelector((state: { users: IUsersState }) => state.users);
  const entityFormKey = JSON.stringify(user);
  const isResetPasswordEmailSent = resetPasswordSentUsers.includes(user.email);

  const accountFormFields: FormField[] = [
    {
      name: 'photoUrl',
      defaultValue: user.photoUrl || '',
      label: '',
      type: 'image',
      required: false,
    },
    {
      name: 'displayName',
      defaultValue: user.displayName || '',
      label: t('account.editModalName'),
      required: true,
    },
    {
      name: 'email',
      defaultValue: user.email || '',
      label: t('account.editModalEmail'),
      required: false,
      disabled: true,
    },
  ];

  function handleEditAccount(data: AccountValidationType) {
    dispatch(updateAuthUser(data));
  }

  function handleResetPassword() {
    dispatch(sendPasswordResetEmail(user.email));
  }

  return (
    <Layout>
      <SC.Wrapper>
        <Typography variant="h1">{t('account.settingsTitle')}</Typography>
        <Card>
          <EntityForm
            id={user.uid}
            key={entityFormKey}
            fields={accountFormFields}
            onSubmit={handleEditAccount}
            validationSchema={accountValidation}
            isLoading={isEditUserLoading}
          />
        </Card>
        <ResetPassword onReset={handleResetPassword} isResetSent={isResetPasswordEmailSent} />
      </SC.Wrapper>
    </Layout>
  );
}
