import styled from 'styled-components';

export const ActionButton = styled.button<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  opacity: ${({ $disabled }) => ($disabled ? '.5' : '1')};
  &:hover {
    filter: brightness(120%);
  }
`;
