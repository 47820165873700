import styled from 'styled-components';

export const Info = styled.div<{ $backgroundColor: string }>`
  padding: 1.6rem;
  display: flex;
  align-items: flex-start;
  gap: 0.8rem;

  background: ${props => props.$backgroundColor};
  border-radius: 0.6rem;
`;
