import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s5};
  height: 100%;
  margin-top: ${({ theme }) => theme.spacing.s4};
  padding-bottom: ${({ theme }) => theme.spacing.s6};
`;
export const ActionBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s4};
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  flex-shrink: 0;
`;

export const QAList = styled.div`
  margin-bottom: 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`;
