import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { ICompanyForm, ICompanyState } from './company.types';

const initialState: ICompanyState = {
  loading: true,
  companies: [],
  company: {
    id: '',
    image: '',
    name: '',
    domain: '',
    industry: '',
    email: '',
    phone: '',
  },
  modalOpen: false,
  deleteModalOpen: false,
  isEditCompanyLoading: false,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    createCompany: (state: ICompanyState, action: PayloadAction<ICompanyForm>) => {},
    updateCompany: (state: ICompanyState, action: PayloadAction<ICompanyForm>) => {
      if (state?.companies?.length) {
        const editCompanyIndex = state.companies.findIndex(company => company.id === state.company.id);
        state.companies[editCompanyIndex] = { ...state.companies[editCompanyIndex], ...action.payload };
      }
    },
    getCompanies: () => {},
    deleteCompany: () => {},
    getCompany: (state: ICompanyState, action: PayloadAction<string>) => {},
    setCompanies: (state: ICompanyState, action: PayloadAction<ICompanyState['companies']>) => {
      state.companies = action.payload;
      state.loading = false;
    },
    setCompany: (state: ICompanyState, action: PayloadAction<ICompanyState['company']>) => {
      state.company = action.payload;
    },
    showModal: (state: ICompanyState, action: PayloadAction<boolean>) => {
      state.modalOpen = action.payload;
    },
    showDeleteModal: (state: ICompanyState, action: PayloadAction<ICompanyState['deleteModalOpen']>) => {
      state.deleteModalOpen = action.payload;
    },
    setIsEditCompanyLoading: (state: ICompanyState, action: PayloadAction<boolean>) => {
      state.isEditCompanyLoading = action.payload;
    },
  },
});

export const {
  createCompany,
  updateCompany,
  getCompany,
  getCompanies,
  setCompanies,
  setCompany,
  showModal,
  showDeleteModal,
  deleteCompany,
  setIsEditCompanyLoading,
} = companySlice.actions;

export const companyReducer: Reducer<ICompanyState> = companySlice.reducer;
