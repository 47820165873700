import { Unsubscribe, onSnapshot } from 'firebase/firestore';
import { StorageReference, deleteObject, getDownloadURL, getMetadata, ref } from 'firebase/storage';
import { logger } from '../../logger';
import axiosInstance from '../../config/axios';
import { ILink, IDocumentationMetaChange, EDocumentationAction, IDocumentationMeta } from './documentation.types';
import { storage } from '../../config/firebase';
import FirestoreService from '../../services/firestore.service';
import { convertToCamelCase } from '@tuler/shared';
import { IQAWithId } from '../qa/qa.types';

export class TextService extends FirestoreService {
  constructor(companyId: string, textId?: string) {
    super('companies', companyId, 'texts', textId || 'default');
  }

  updateText = async (content: string, charCount: number) => {
    try {
      await this.upsertDoc({ content, charCount });
    } catch (e) {
      logger.error('TextService.updateText', e);
    }
  };

  getText = async () => {
    try {
      return (await this.getDoc()) || { content: '', charCount: 0 };
    } catch (e) {
      logger.error('TextService.getText', e);
    }
  };
}

export class QAsService extends FirestoreService {
  constructor(companyId: string) {
    super('companies', companyId, 'qa');
  }

  getQAList = async () => {
    try {
      return await this.getAllDocs();
    } catch (e) {
      logger.error('QAsService.getQAList', e);
    }
  };
}

export class QAService extends FirestoreService {
  constructor(companyId: string, qaId: string) {
    super('companies', companyId, 'qa', qaId);
  }

  createQA = async (qA: Omit<IQAWithId, 'id'>) => {
    try {
      await this.upsertDoc(qA);
    } catch (e) {
      logger.error('QAService.createQA', e);
    }
  };

  updateQA = async (qA: Omit<IQAWithId, 'id'>) => {
    try {
      await this.upsertDoc(qA);
    } catch (e) {
      logger.error('QAService.updateQA', e);
    }
  };

  deleteQA = async () => {
    try {
      await this.deleteDoc();
    } catch (e) {
      logger.error('QAService.deleteQA', e);
    }
  };
}

export class WebsiteService extends FirestoreService {
  constructor(companyId: string) {
    super('companies', companyId, 'website');
  }

  async scrapWebsiteRequest(companyId: string, url: string, ignoreKeywords: string[]) {
    try {
      await axiosInstance.post(`/scraper/scrape`, { url: url, ignore_keywords: ignoreKeywords, company_id: companyId });
    } catch (e) {
      logger.error('WebsiteService.scrapWebsiteRequest', e);
    }
  }

  async scrapeSitemapRequest(companyId: string, url: string, ignoreKeywords: string[]) {
    try {
      await axiosInstance.post(`/scraper/scrape-sitemap`, { url: url, ignore_keywords: ignoreKeywords, company_id: companyId });
    } catch (e) {
      logger.error('WebsiteService.scrapSitemapRequest', e);
    }
  }

  getLinks = async () => {
    try {
      const links: Record<string, ILink> = {};
      const res = (await this.getAllDocs()) as ILink[];

      res.forEach(link => {
        links[link.id] = link;
      });

      return links;
    } catch (e) {
      logger.error('WebsiteService.getLinks', e);
    }
  };

  deleteAllLinks = async (): Promise<void> => {
    try {
      await this.deleteAllDocs();
    } catch (e) {
      logger.error('WebsiteService.deleteAllLinks', e);
    }
  };
}

export class LinkService extends FirestoreService {
  constructor(companyId: string, id: string) {
    super('companies', companyId, 'website', id);
  }

  deleteLink = async (): Promise<void> => {
    try {
      this.deleteDoc();
    } catch (e) {
      logger.error('LinkService.deleteLink', e);
    }
  };
}

export class DocumentationMetaService extends FirestoreService {
  constructor(companyId: string, id?: string) {
    super('companies', companyId, 'documentation_meta', id || 'default');
  }

  subscribeToMetadataChanges = (callback: (metadata: IDocumentationMeta) => void): Unsubscribe | undefined => {
    try {
      const unsubscribe = onSnapshot(this.docRef!, async docSnap => {
        if (docSnap.exists()) {
          const metadata = convertToCamelCase(docSnap.data());
          callback(metadata as IDocumentationMeta);
        }
      });

      return unsubscribe;
    } catch (e) {
      logger.error('DocumentationMetaService.subscribeToMetadataChanges', e);
    }
  };

  addDocumentationMetaChange = async (newItems: IDocumentationMetaChange[]): Promise<void> => {
    try {
      await this.updateWithTransaction((currentData: IDocumentationMeta) => {
        const changesArray = [...currentData.changes, ...newItems];
        const filteredChangesArray = this.filterChanges(changesArray);

        return { ...currentData, changes: filteredChangesArray };
      });
    } catch (e) {
      logger.error('DocumentationMetaService.addDocumentationMetaChange', e);
    }
  };

  filterChanges(arr: IDocumentationMetaChange[]) {
    const uniqArray = [...new Set(arr)];
    const groupedById = uniqArray.reduce((acc: { [key: string]: IDocumentationMetaChange[] }, item) => {
      acc[item.id] = (acc[item.id] || []).concat(item);
      return acc;
    }, {});

    return Object.values(groupedById).flatMap(chunk => {
      // if there are 3 entries then one is necessarily a delete so we don't keep any
      if (chunk.length === 3) {
        return [];
      }
      // if there is 1 entry we automatically keep it
      if (chunk.length === 1) {
        return chunk[0];
      }
      // if we got this far, then we have 2 entries so we analyse their types
      const actions = chunk.map(item => item.action);
      // if the entries are delete and update, we keep delete
      if (actions.includes(EDocumentationAction.Delete) && actions.includes(EDocumentationAction.Update)) {
        return { ...chunk[0], type: EDocumentationAction.Delete };
      }
      // if the entries are add and update, we keep add
      if (actions.includes(EDocumentationAction.Add) && actions.includes(EDocumentationAction.Update)) {
        return { ...chunk[0], action: EDocumentationAction.Add };
      }
      return [];
    });
  }

  async trainAgentRequest(companyId: string) {
    try {
      await axiosInstance.post(`/vector/train`, { company_id: companyId });
    } catch (e) {
      logger.error('DocumentationMetaService.trainAgentRequest', e);
    }
  }

  async deleteVectorCollection(companyId: string) {
    try {
      await axiosInstance.delete(`/vector/${companyId}`);
    } catch (e) {
      logger.error('DocumentationMetaService.deleteVectorCollection', e);
    }
  }
}
