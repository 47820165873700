import * as SC from './styles';

// use names from figma for not repeat icons
export enum IconName {
  Adjustments = 'adjustments',
  ChatAlt2 = 'chat-alt-2',
  Users = 'users',
  UserCircle = 'user-circle',
  UserGroup = 'user-group',
  Cog = 'cog',
  Logout = 'logout',
  ChevronRight = 'chevron-right',
  Search = 'search',
  User = 'user',
  Bell = 'bell',
  ChevronDown = 'chevron-down',
  X = 'x',
  Plus = 'plus',
  Eye = 'eye',
  EyeSlash = 'eye-slash',
  ChevronLeft = 'chevron-left',
  Calculator = 'calculator',
  Upload = 'upload',
  Trash = 'trash',
  PencilAlt = 'pencil-alt',
  GlobeAlt = 'globe-alt',
  ClipboardList = 'clipboard-list',
  Configuration = 'configuration',
  Routing2 = 'routing-2',
  Calendar = 'calendar',
  MenuAlt1 = 'menu-alt-1',
  CheckCircle = 'check-circle',
  Text = 'text',
  Number = 'number',
  Cube = 'cube',
  Companies = 'companies',
  MapPin = 'map-pin',
  Check = 'check',
  Danger = 'danger',
  Drag = 'drag',
  DocumentDuplicate = 'document-duplicate',
  DocumentDuplicate2 = 'document-duplicate-2',
  DocumentText = 'document-text',
  CloudUpload = 'cloud-upload',
  XCircle = 'x-circle',
  Phone = 'phone',
  Mail = 'mail',
  Theme = 'theme',
  Picker = 'picker',
  QA = 'qa',
  HelpCircle = 'help-circle',
  DotsHorizontal = 'dots-horizontal',
  Clock = 'clock',
  RoundedTriangle = 'rounded-triangle',
  OfficeBuilding = 'office-building',
  Moon = 'moon',
  ClipboardText = 'clipboard-text',
  Messages = 'messages',
  Camera = 'camera',
  Edit2 = 'edit-2',
  Message = 'message',
  MessageQuestion = 'message-question',
  NewTab = 'new-tab',
  Filter = 'filter',
  Sort = 'sort',
  SortUp = 'sort-up',
  SortDown = 'sort-down',
  Profile2User = 'profile-2user',
  InfoCircle = 'info-circle',
  Download = 'download',
  ChartBar = 'chart-bar',
  Home2 = 'home-2',
  Send = 'send',
  Send2 = 'send2',
  Loading = 'loading',
  Integrations = 'integrations',
}

interface IconProps {
  name: IconName;
  color?: string;
  width?: number;
  height?: number;
  backgroundWidth?: number;
  backgroundHeight?: number;
  withBackground?: boolean;
  backgroundColor?: string;
}

function Icon({
  name,
  color,
  width = 1.8,
  height = 1.8,
  backgroundWidth = 3.8,
  backgroundHeight = 3.8,
  withBackground = false,
  backgroundColor,
  ...props
}: IconProps) {
  if (withBackground) {
    return (
      <SC.IconBackground color={backgroundColor} $width={backgroundWidth} $height={backgroundHeight} {...props}>
        <SC.Icon src={`/icons/${name}.svg`} $width={width} $height={height} $color={color} />
      </SC.IconBackground>
    );
  }
  return <SC.Icon {...props} src={`/icons/${name}.svg`} $width={width} $height={height} $color={color} />;
}

export default Icon;
