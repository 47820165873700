import styled from 'styled-components';

export const Divider = styled.div<{ $noMargin: boolean }>`
  position: relative;
  width: 100%;
  height: 1px;
  margin: ${({ $noMargin }) => ($noMargin ? '0' : '3rem 0')};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray200};
  }
  span {
    padding: 0 0.8rem;
    position: absolute;
    top: 50%;
    left: 50%;

    font-size: 1.6rem;

    color: ${({ theme }) => theme.colors.gray600};
    background-color: ${props => props.theme.colors.white};
    transform: translate(-50%, -50%);
    z-index: 1;
  }
`;
