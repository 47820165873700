import styled from 'styled-components';

export const PageLoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GlobalLoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ModalLoaderContainer = styled.div`
  max-width: 28.3rem;
  margin: 3rem auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  color: ${({ theme }) => theme.colors.gray800};
  text-align: center;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;
