import { useMemo } from 'react';
import { hexToRgb } from '../common';

export function useTextColor(value: string): string {
  const textColor = useMemo(() => {
    const colors = hexToRgb(value);
    return colors[0] * 0.299 + colors[1] * 0.587 + colors[2] * 0.114 > 186 ? '#111111' : '#FFFFFF';
  }, [value]);

  return textColor;
}
