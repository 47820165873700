import { CSSProperties } from 'react';
import * as SC from './styles';
import Radio from '../Radio/Radio';
import { InputError } from '../Input/types';
import { Typography } from '../Typography/Typography';

export interface IRadioFieldOption {
  label: string;
  value: string;
}

export interface IRadioFieldProps {
  name: string;
  label?: string;
  errorText?: string;
  onChange?: (e: any) => void;
  placeholder?: string;
  style?: CSSProperties;
  disabled?: boolean;
  required?: boolean;
  options: IRadioFieldOption[];
  control?: any;
  error?: InputError;
}

function RadioField({ name, label, control, style, required = false, options, disabled = false, error }: IRadioFieldProps) {
  return (
    <SC.RadioFieldContainer style={style}>
      {!!label && (
        <Typography variant="label">
          {label} {required && <SC.RadioFieldLabelRequired>*</SC.RadioFieldLabelRequired>}
        </Typography>
      )}
      <SC.RadioWrapper $disabled={disabled}>
        {options.map(option => (
          <Radio key={option.value} name={name} label={option.label} disabled={disabled} value={option.value} control={control} />
        ))}
      </SC.RadioWrapper>
      {!!error && (
        <SC.InputErrorWrapper>
          <Typography variant="caption" color="error">
            {typeof error.message === 'string' ? error.message : null}
          </Typography>
        </SC.InputErrorWrapper>
      )}
    </SC.RadioFieldContainer>
  );
}

export default RadioField;
