import { useColors } from '../../hooks/theme';
import * as SC from './styles';

type TBadge = 'brand' | 'error' | 'warning' | 'success';

interface IBadgeProps {
  text: string | number;
  variant?: TBadge;
}

export default function Badge({ text, variant = 'brand' }: IBadgeProps) {
  const colors = useColors();
  let color = colors.green500;
  let bgColor = colors.green50;

  switch (variant) {
    case 'success':
      color = colors.success.default;
      bgColor = colors.success.lightness;
      break;
    case 'error':
      color = colors.error.default;
      bgColor = colors.error.lightness;
      break;
    case 'brand':
      color = colors.brand.default;
      bgColor = colors.brand.lightness;
      break;
    case 'warning':
      color = colors.warning.default;
      bgColor = colors.warning.lightness;
      break;
  }

  return (
    <SC.Wrapper $color={color} $bgColor={bgColor}>
      {text}
    </SC.Wrapper>
  );
}
