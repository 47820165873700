import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import { setPageLoading } from '../loader/loader.redux';
import { t } from '../../config/i18n';
import { getActiveCall, getCalls, setActiveCall, setCalls } from './call.redux';
import { ICallsState, IGetCallResponse, IGetCallsResponse } from './call.types';
import { showToast } from '../toasts/toasts.redux';
import { getCallRequest, getCallsRequest } from './call.service';
import { getDateFilter } from '../../common';
import { ICompanyState } from '../company/company.types';

export function* getCallsSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  yield put(setPageLoading(true));
  const { dateFilter }: ICallsState = yield select((state: { calls: ICallsState }) => state.calls);
  const currentFilter = getDateFilter(dateFilter);
  const { success, calls }: IGetCallsResponse = yield call(getCallsRequest, companyId, currentFilter.start, currentFilter.end);

  if (success) {
    yield put(setCalls(calls ?? []));
  } else {
    yield put(
      showToast({
        type: 'error',
        message: t('calls.getCallsFailed'),
      }),
    );
  }
  yield put(setPageLoading(false));
}

export function* getActiveCallSaga({ payload: id }: PayloadAction<string>): SagaIterator {
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { success, call: activeCall }: IGetCallResponse = yield call(getCallRequest, companyId, id);

  if (success && activeCall) {
    yield put(setActiveCall(activeCall));
  } else {
    yield put(
      showToast({
        type: 'error',
        message: t('calls.getCallFailed'),
      }),
    );
  }
}

export function* initCallsSagas(): SagaIterator {
  yield takeEvery(getCalls.toString(), getCallsSaga);
  yield takeEvery(getActiveCall.toString(), getActiveCallSaga);
}
