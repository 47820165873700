import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { ISidebarState } from './sidebar.types';

const initialState: ISidebarState = {
  openedMenus: [],
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleMenu: (state: ISidebarState, action: PayloadAction<string>) => {
      const name = action.payload;

      if (state.openedMenus.includes(name)) {
        state.openedMenus = state.openedMenus.filter(item => item !== name);
      } else {
        state.openedMenus = [...state.openedMenus, name];
      }
    },
  },
});

export const { toggleMenu } = sidebarSlice.actions;

export const sidebarReducer: Reducer<ISidebarState> = sidebarSlice.reducer;
