import { usePush } from '../../hooks/navigation';
import * as SC from './styles';

interface ICustomLinkProps {
  href: string;
  children: React.ReactNode;
}

export default function CustomLink({ href, children }: ICustomLinkProps) {
  const pushUrl = usePush();

  function handleOpenIntegrations() {
    pushUrl(href);
  }

  return <SC.CustomLink onClick={handleOpenIntegrations}>{children}</SC.CustomLink>;
}
