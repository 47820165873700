import { SagaIterator } from 'redux-saga';
import { select, call, put, takeEvery } from 'redux-saga/effects';
import { createTheme, getTheme, setTheme, setThemeLoading, updateTheme } from './theme.redux';
import { createThemeRequest, getThemeRequest, updateThemeRequest } from './theme.service';
import theme from '../../config/theme';
import { PayloadAction } from '@reduxjs/toolkit';
import { IThemeState } from './theme.types';

export function* getThemeSaga({ payload: id }: PayloadAction<string>): SagaIterator {
  const theme = yield call(getThemeRequest, id);

  yield put(setTheme(theme));
}

export function* createThemeSaga({ payload: id }: PayloadAction<string>): SagaIterator {
  yield call(createThemeRequest, id, { primaryColor: theme.colors.primary500 });
}

export function* updateThemeSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  yield put(setThemeLoading(true));
  const { editPrimaryColor } = yield select((state: { theme: IThemeState }) => state.theme);
  const newTheme = { primaryColor: editPrimaryColor };

  yield call(updateThemeRequest, companyId, newTheme);
  yield put(setTheme(newTheme));
  yield put(setThemeLoading(false));
}

export function* initThemeSagas(): SagaIterator {
  yield takeEvery(createTheme.toString(), createThemeSaga);
  yield takeEvery(getTheme.toString(), getThemeSaga);
  yield takeEvery(updateTheme.toString(), updateThemeSaga);
}
