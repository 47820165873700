import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import * as SC from './styles';
import { DateTime } from 'luxon';
import { ENotificationEnum, INotificationsState } from '../../redux/notifications/notifications.types';
import { useDispatch, useSelector } from 'react-redux';
import { setTypeFilter, setDateFilter, onNotificationClick } from '../../redux/notifications/notifications.redux';
import Select, { ISelectOption } from '../../components/Select/Select';
import Icon, { IconName } from '../../components/Icon/Icon';
import { useColors } from '../../hooks/theme';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { Typography } from '../../components/Typography/Typography';
import { EDateFilter } from '../../common/types';

enum ESelect {
  NotificationsTypeFilter = 'notificationsTypeFilter',
  NotificationsDateFilter = 'notificationsDateFilter',
}

export default function NotificationsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const colors = useColors();
  const { notifications, typeFilter, dateFilter } = useSelector((state: { notifications: INotificationsState }) => state.notifications);
  const hasNotifications = !!notifications.length;
  const dateOptions: ISelectOption<EDateFilter>[] = Object.values(EDateFilter).map(item => ({
    label: t(`notifications.filterCalendar-${item}`),
    value: item,
  }));

  const typeOptions: { label: string; value: ENotificationEnum | undefined }[] = [
    {
      label: t('notifications.filterAll'),
      value: undefined,
    },
    {
      label: t('notifications.filterUnknownAnswer'),
      value: ENotificationEnum.unknownAnswer,
    },
    {
      label: t('notifications.filterContactRequest'),
      value: ENotificationEnum.contactRequest,
    },
  ];

  const iconsData = {
    welcome: { icon: IconName.Bell, smallIcon: IconName.Plus, smallIconBg: colors.brand.default },
    unknown_answer: { icon: IconName.User, smallIcon: IconName.Danger, smallIconBg: colors.error.default },
    contact_request: { icon: IconName.Phone, smallIcon: IconName.Plus, smallIconBg: colors.brand.default },
    needs_human: { icon: IconName.User, smallIcon: IconName.Danger, smallIconBg: colors.error.default },
    ready_to_book: { icon: IconName.Bell, smallIcon: IconName.Danger, smallIconBg: colors.error.default },
    unanswered_question: { icon: IconName.Bell, smallIcon: IconName.Danger, smallIconBg: colors.error.default },
  };

  function handleClickNotification(type: ENotificationEnum, notificationId: string, sessionId: string) {
    dispatch(onNotificationClick(notificationId));
  }

  function handleSelectChange(name: ESelect, value: ENotificationEnum | undefined | EDateFilter) {
    if (name === ESelect.NotificationsDateFilter) {
      dispatch(setDateFilter(value as EDateFilter));
    } else {
      dispatch(setTypeFilter(value as ENotificationEnum));
    }
  }

  function getNotifications() {
    return notifications?.map(({ id, title, content, createdAt, sessionId, read, type }) => {
      const createdDate = DateTime.fromISO(createdAt);
      const formattedDate: string = createdDate.toLocaleString(DateTime.DATETIME_SHORT);
      const { icon, smallIcon, smallIconBg } = iconsData[type];

      return (
        <SC.Item key={id} $read={read} onClick={() => handleClickNotification(type, id, sessionId)}>
          <SC.ItemIcon>
            <Icon name={icon} withBackground color={colors.black} backgroundColor={colors.bg.darkest} />
            <SC.ItemIconSmall $bgColor={smallIconBg}>
              <Icon name={smallIcon} color={colors.white} width={1} height={1} />
            </SC.ItemIconSmall>
          </SC.ItemIcon>
          <SC.ItemText>
            <Typography variant="label" ellipsis>
              {title}
            </Typography>
            <Typography variant="caption" ellipsis>
              {content}
            </Typography>
          </SC.ItemText>
          <Typography variant="caption">{formattedDate}</Typography>
        </SC.Item>
      );
    });
  }

  return (
    <Layout>
      <PageContent
        title={t('notifications.title')}
        actions={
          <SC.Filter>
            <Select
              name={ESelect.NotificationsTypeFilter}
              value={typeFilter}
              options={typeOptions}
              onChange={handleSelectChange}
              position="right"
              hideChevron
              beforeIcon={IconName.Filter}
              iconColor={colors.text.default}
            />
            <Select
              name={ESelect.NotificationsDateFilter}
              value={dateFilter}
              options={dateOptions}
              onChange={handleSelectChange}
              position="right"
              hideChevron
              beforeIcon={IconName.Calendar}
              iconColor={colors.text.default}
            />
          </SC.Filter>
        }
      >
        <SC.Container>
          {hasNotifications ? (
            <SC.List>{getNotifications()}</SC.List>
          ) : (
            <EmptyContent icon={IconName.Bell} title={t('notifications.emptyTitle')} />
          )}
        </SC.Container>
      </PageContent>
    </Layout>
  );
}
