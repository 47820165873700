import * as SC from './styles';
import { useColors } from '../../hooks/theme';
import Modal, { EModalSize } from '../Modal/Modal';
import Icon, { IconName } from '../Icon/Icon';

export enum LoaderEnum {
  Global = 'global',
  Page = 'page',
  Modal = 'modal',
}

interface IProps {
  variant?: LoaderEnum;
  modalText?: string;
}

export default function Loader({ variant = LoaderEnum.Global, modalText }: IProps) {
  const colors = useColors();

  switch (variant) {
    case LoaderEnum.Global: {
      return (
        <SC.GlobalLoaderContainer>
          <Icon name={IconName.Loading} width={10} height={10} />
        </SC.GlobalLoaderContainer>
      );
    }
    case LoaderEnum.Page: {
      return (
        <SC.PageLoaderContainer>
          <Icon name={IconName.Loading} width={10} height={10} />
        </SC.PageLoaderContainer>
      );
    }
    case LoaderEnum.Modal: {
      if (modalText) {
        return (
          <Modal size={EModalSize.small} withoutClose>
            <SC.ModalLoaderContainer>
              <Icon name={IconName.Loading} width={10} height={10} />
              {modalText}
            </SC.ModalLoaderContainer>
          </Modal>
        );
      } else {
        return <> modalText required</>;
      }
    }
    default:
      return <></>;
  }
}
