import { SagaIterator } from 'redux-saga';
import { put, fork, call, select, takeEvery } from 'redux-saga/effects';
import {
  setMessageNeedsAttention,
  setNeedsAttentionMessageResolved,
  sendMessage,
  resetUserUnseenMessages,
  setAssistantTyping,
  setAssistantTypingStore,
} from './sessions.redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { IAssistantTyping, INewMessage, ISessionsState } from './sessions.types';
import { SessionService, newMessageRequest, resetUserUnseenMessagesRequest, setAssistantTypingRequest } from './sessions.service';

function* setMessageNeedsAttentionSaga({ payload: id }: PayloadAction<string>): SagaIterator {
  const { session } = yield select((state: { sessions: ISessionsState }) => state.sessions);
  const sessionInstance = new SessionService(session.id);

  yield call(sessionInstance.setMessageNeedsAttention, id);
  yield put(setNeedsAttentionMessageResolved(id));
}

function* sendMessageSaga({ payload: { sessionId, message } }: PayloadAction<INewMessage>) {
  yield call(setAssistantTypingRequest, sessionId, false);
  yield call(newMessageRequest, sessionId, message);
}

function* resetUserUnseenMessagesSaga({ payload: sessionId }: PayloadAction<string>) {
  yield call(resetUserUnseenMessagesRequest, sessionId);
}

function* setAssistantTypingStoreSaga({ payload: { sessionId, isTyping } }: PayloadAction<IAssistantTyping>) {
  const { session } = yield select((state: { sessions: ISessionsState }) => state.sessions);

  if (session.isAssistantTyping !== isTyping || sessionId !== session.id) {
    yield put(setAssistantTyping(isTyping));
    yield call(setAssistantTypingRequest, sessionId, isTyping);
  }
}

export function* initSessionsSagas(): SagaIterator {
  yield takeEvery(setMessageNeedsAttention.toString(), setMessageNeedsAttentionSaga);
  yield takeEvery(sendMessage.toString(), sendMessageSaga);
  yield takeEvery(resetUserUnseenMessages.toString(), resetUserUnseenMessagesSaga);
  yield takeEvery(setAssistantTypingStore.toString(), setAssistantTypingStoreSaga);
}
