import styled from 'styled-components';
import { IInputWrapperProps } from './types';
import { useColors } from '../../hooks/theme';

const getInputWrapperBorderColor = (props: IInputWrapperProps) => {
  const colors = useColors();
  const { $error, $disabled, $focused } = props;

  if ($error) return colors.red500;
  if ($focused) return colors.primary500;
  return 'transparent';
};

export const InputContainer = styled.div<{ $isFullHeight: boolean }>`
  width: 100%;
  height: ${({ $isFullHeight }) => ($isFullHeight ? '100%' : 'auto')};
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const InputWrapper = styled.label<IInputWrapperProps>`
  padding: 1.2rem 1.6rem;
  height: ${({ $isFullHeight }) => ($isFullHeight ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  border-radius: ${({ $isTextarea }) => ($isTextarea ? '1.2rem' : '10rem')};
  border: ${({ $isWithoutBorder }) => ($isWithoutBorder ? 0 : '.1rem')} solid ${getInputWrapperBorderColor};
  background: ${({ $isLight, theme }) => ($isLight ? theme.colors.bg.default : theme.colors.bg.dark)};
  opacity: ${props => (props.$disabled ? 0.6 : 1)};
  transition: all 0.2s;
  pointer-events: ${props => (props.$disabled ? 'none' : 'auto')};

  .PhoneInput {
    width: 100%;
  }
  .PhoneInputInput {
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5;
    color: ${props => (props.$disabled ? props.theme.colors.gray500 : props.theme.colors.gray900)};

    border: none;
    background: none;
    outline: none;
  }
`;

export const PasswordEye = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.s3};
  right: ${({ theme }) => theme.spacing.s4};

  cursor: pointer;
`;

export const TextArea = styled.textarea<{ $disabled: boolean; $isFullHeight: boolean }>`
  width: 100%;
  min-height: ${({ $isFullHeight }) => ($isFullHeight ? '100%' : '6.2rem')};
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5;
  color: ${props => (props.$disabled ? props.theme.colors.gray500 : props.theme.colors.gray900)};

  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  border: none;
  background: none;
  outline: none;
  resize: ${({ $isFullHeight }) => ($isFullHeight ? 'none' : 'vertical')};

  &::placeholder {
    color: ${props => props.theme.colors.gray400};
  }

  &:-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.colors.white} inset !important;
    -webkit-text-fill-color: 'transparent' !important;
  }
  &:-webkit-autofill::first-line {
    font-weight: 500 !important;
  }
  &[type='password'] {
    letter-spacing: 2px;
  }
  &:-webkit-autofill ~ label {
    transform: translateY(-116px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
  }
`;

export const InputElement = styled.input<{ $disabled?: boolean }>`
  padding-right: ${({ theme }) => theme.spacing.s6};
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5;
  color: ${props => (props.$disabled ? props.theme.colors.gray500 : props.theme.colors.gray900)};

  border: none;
  background: none;
  outline: none;

  &::placeholder {
    color: ${props => props.theme.colors.gray400};
  }

  &:-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 3rem ${props => props.theme.colors.white} inset !important;
    -webkit-text-fill-color: 'transparent' !important;
  }
  &:-webkit-autofill::first-line {
    font-weight: 500 !important;
  }
  &[type='password'] {
    letter-spacing: 0.2rem;
  }
  &:-webkit-autofill ~ label {
    transform: translateY(-11.6rem);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
  }
`;

export const InputLabelRequired = styled.sup`
  color: ${({ theme }) => theme.colors.error.default};
`;

export const InputLabel = styled.label`
  padding: 0.4rem;
  position: absolute;
  top: 50%;
  left: 0.8rem;

  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: ${props => props.theme.colors.gray500};
  transition: all 0.175s ease-out;
  background: ${props => props.theme.colors.white};
  transform-origin: top left;
`;

export const InputErrorWrapper = styled.div`
  position: absolute;
  top: calc(100% + 0.2rem);
  left: 1.6rem;
`;
