import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import { FormField } from '../../common/types';
import Card from '../../components/Card/Card';
import EntityForm from '../../components/EntityForm/EntityForm';
import { IIntegrationsState } from '../../redux/integrations/integrations.types';
import { ICompanyState } from '../../redux/company/company.types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  createTelegramNotification,
  getActiveTelegramNotification,
  updateTelegramNotification,
} from '../../redux/integrations/integrations.redux';
import { TelegramNotificationValidationType, telegramNotificationValidation } from '../../schemas';

export default function TelegramNotificationsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeTelegramNotification, isTelegramNotificationLoading } = useSelector(
    (state: { integrations: IIntegrationsState }) => state.integrations,
  );
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const formKey = JSON.stringify(activeTelegramNotification);

  const telegramNotificationFields: FormField[] = [
    {
      name: 'chatId',
      defaultValue: activeTelegramNotification?.chatId || '',
      label: t('telegramNotifications.formChatId'),
      placeholder: t('telegramNotifications.formChatIdPlaceholder'),
      required: true,
      type: 'password',
    },
  ];

  function handleSave(data: TelegramNotificationValidationType) {
    const chatId = Number(data.chatId);
    if (activeTelegramNotification?.id) {
      dispatch(updateTelegramNotification({ chatId, id: activeTelegramNotification.id }));
    } else {
      dispatch(createTelegramNotification({ chatId }));
    }
  }

  useEffect(() => {
    if (!companyId) return;
    dispatch(getActiveTelegramNotification(companyId));
  }, [companyId]);

  return (
    <Layout>
      <PageContent hasBack title={t('telegramNotifications.title')}>
        <Card>
          <EntityForm
            id={String(activeTelegramNotification?.id)}
            key={formKey}
            fields={telegramNotificationFields}
            onSubmit={handleSave}
            validationSchema={telegramNotificationValidation}
            isLoading={isTelegramNotificationLoading}
          />
        </Card>
      </PageContent>
    </Layout>
  );
}
