import { useDispatch, useSelector } from 'react-redux';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import { ECustomerStatus, ICustomersState } from '../../redux/customers/customers.types';
import { useEffect } from 'react';
import { getCustomer } from '../../redux/customers/customers.redux';
import { ICompanyState } from '../../redux/company/company.types';
import { useURLParams } from '../../hooks/navigation';
import Layout from '../../components/Layout/Layout';
import Badge from '../../components/Badge/Badge';
import Avatar from '../../components/Avatar/Avatar';
import Icon, { IconName } from '../../components/Icon/Icon';
import { Typography } from '../../components/Typography/Typography';

export default function CustomerPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { customerId } = useURLParams();
  const {
    customer: { status, firstName, lastName, phone, email },
  } = useSelector((state: { customers: ICustomersState }) => state.customers);
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const fullName = `${lastName}, ${firstName}`;

  function getItemBadge() {
    const text = t(`customers.${status}Status`);

    switch (status) {
      case ECustomerStatus.new:
        return <Badge text={text} variant="brand" />;
      case ECustomerStatus.contacted:
        return <Badge text={text} variant="warning" />;
      case ECustomerStatus.converted:
        return <Badge text={text} variant="success" />;
      case ECustomerStatus.lost:
        return <Badge text={text} variant="error" />;
    }
  }

  useEffect(() => {
    if (companyId && customerId) {
      dispatch(getCustomer({ customerId, companyId }));
    }
  }, [companyId, customerId]);

  return (
    <Layout>
      <SC.Wrapper>
        <SC.Info>
          <Avatar name={fullName} size="l" />
          <Typography variant="h3">{fullName}</Typography>
          {getItemBadge()}
        </SC.Info>
        <SC.Contacts>
          {email && (
            <SC.ContactsItem>
              <Icon name={IconName.Mail} />
              <Typography variant="caption">{email}</Typography>
            </SC.ContactsItem>
          )}
          {phone && (
            <SC.ContactsItem>
              <Icon name={IconName.Phone} />
              <Typography variant="caption">{phone}</Typography>
            </SC.ContactsItem>
          )}
        </SC.Contacts>
      </SC.Wrapper>
    </Layout>
  );
}
