import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getActiveNotification, markAsRead, onNotificationClick, setActiveNotification, setNotificationsOpen } from './notifications.redux';
import { NotificationsService } from './notifications.service';
import { logger } from '../../logger';
import { IAuthUserState } from '../authUser/authUser.types';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { ROUTES } from '../../constants/routes';
import { ENotificationEnum, INotificationWithId, INotificationsState } from './notifications.types';
import { ICompanyState } from '../company/company.types';
import { setPageLoading } from '../loader/loader.redux';
import { showToast } from '../toasts/toasts.redux';
import { t } from 'i18next';

export function* onNotificationClickSaga({ payload: notificationId }: PayloadAction<string>): SagaIterator {
  try {
    const { companyId, uid } = yield select((state: { authUser: IAuthUserState }) => state.authUser.user);
    const { notifications }: INotificationsState = yield select((state: { notifications: INotificationsState }) => state.notifications);
    const notification = notifications.find((notification: INotificationWithId) => notification.id === notificationId);
    if (!notification) return;

    if (!notification.read) {
      const notificationsService = new NotificationsService();
      yield call(notificationsService.markAsRead, uid, notificationId);
      yield put(markAsRead({ notificationId, userId: uid }));
    }
    if (notification.type === ENotificationEnum.unansweredQuestion) {
      const id = notification.qaId || 0;
      yield put(push(`${ROUTES.newQA.path}?id=${id}&companyId=${companyId}&notificationId=${notificationId}`));
      return;
    }

    if (notification?.sessionId) {
      yield put(push(`${ROUTES.chats.path}?sessionId=${notification.sessionId}`));
      return;
    }

    yield put(setNotificationsOpen(false));
  } catch (e) {
    logger.error('getNotificationsSaga', e);
  }
}

export function* getActiveNotificationSaga({ payload: notificationId }: PayloadAction<string>): SagaIterator {
  yield put(setPageLoading(true));
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const notificationsService = new NotificationsService();

  const { success, notification } = yield call(notificationsService.getNotification, companyId, notificationId);

  if (!success) {
    yield put(setPageLoading(false));
    return;
  }

  if (!notification.qaId) {
    yield put(setPageLoading(false));
  }
  yield put(setActiveNotification(notification));
}

export function* initNotificationsSagas(): SagaIterator {
  yield takeEvery(onNotificationClick.toString(), onNotificationClickSaga);
  yield takeEvery(getActiveNotification.toString(), getActiveNotificationSaga);
}
