import { styled } from 'styled-components';

export const InputContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const InputWrapper = styled.div`
  padding: 1.2rem 1.6rem;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  border-radius: 10rem;
  background: ${({ theme }) => theme.colors.bg.dark};
  transition: all 0.2s;
`;

export const InputElement = styled.input`
  padding-right: ${({ theme }) => theme.spacing.s6};
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.gray900};

  border: none;
  background: none;
  outline: none;

  &::placeholder {
    color: ${props => props.theme.colors.gray400};
  }

  &:-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 3rem ${props => props.theme.colors.white} inset !important;
    -webkit-text-fill-color: 'transparent' !important;
  }
  &:-webkit-autofill::first-line {
    font-weight: 500 !important;
  }
  &[type='password'] {
    letter-spacing: 0.2rem;
  }
  &:-webkit-autofill ~ label {
    transform: translateY(-11.6rem);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
  }
`;
