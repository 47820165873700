import * as SC from './styles';
import { useColors } from '../../hooks/theme';
import Button from '../Button/Button';
import { IBannerState } from '../../redux/banner/banner.types';
import { formEmitter } from '../../config/formEmitter';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { triggerBannerAction } from '../../redux/banner/banner.redux';
import { Typography } from '../Typography/Typography';
import { usePermission } from '../../hooks/usePermission';

export default function ConfirmationBanner() {
  const colors = useColors();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isTrainBannerVisible, isSaveBannerVisible, processingBanner } = useSelector((state: { banner: IBannerState }) => state.banner);
  const { isAdmin } = usePermission();

  function handleClickTrain() {
    formEmitter.emit('train');
  }

  function handleClickSave() {
    dispatch(triggerBannerAction('save'));
  }

  function handleClickSecondaryButton() {
    dispatch(triggerBannerAction('discard'));
  }

  function renderSaveBanner() {
    return (
      <SC.Container $backgroundColor={colors.black}>
        <Typography variant="h4" color="light">
          {t('banner.save.title')}
        </Typography>
        <SC.Buttons>
          <Button variant="secondary" onClick={handleClickSecondaryButton}>
            {t('banner.save.discard')}
          </Button>
          <Button variant="primary" onClick={handleClickSave}>
            {t('banner.save.save')}
          </Button>
        </SC.Buttons>
      </SC.Container>
    );
  }

  function renderTrainBanner() {
    return (
      <SC.Container $backgroundColor={colors.warning.default}>
        <SC.Info>
          <Typography variant="h4">{t('banner.warn.title')}</Typography>
          <SC.InfoDesc>
            <Typography variant="caption" color="default">
              {t('banner.warn.desc')}
            </Typography>
          </SC.InfoDesc>
        </SC.Info>
        <SC.Buttons>
          <Button variant="secondary" onClick={handleClickTrain}>
            {t('banner.warn.start')}
          </Button>
        </SC.Buttons>
      </SC.Container>
    );
  }

  function renderProcessingBanner() {
    return (
      <SC.Container $backgroundColor={colors.brand.default}>
        <SC.Info>
          <Typography variant="h4" color="light">
            {processingBanner.title}
          </Typography>
          <SC.InfoDesc>
            <Typography variant="caption" color="light">
              {processingBanner.description}
            </Typography>
          </SC.InfoDesc>
        </SC.Info>
        <SC.Buttons>
          <Oval
            height={20}
            width={20}
            color={colors.bg.default}
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor={'transparent'}
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </SC.Buttons>
      </SC.Container>
    );
  }

  if (!isAdmin) {
    return;
  }

  if (isSaveBannerVisible) {
    return renderSaveBanner();
  } else if (processingBanner.isVisible) {
    return renderProcessingBanner();
  } else if (isTrainBannerVisible) {
    return renderTrainBanner();
  }

  return <></>;
}
