import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  text-align: center;
  max-width: 30.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Text = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s6};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s2};
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.text.body};
  font-size: 12rem;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
`;
