import { Unsubscribe } from 'firebase/firestore';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DocumentationMetaService } from '../redux/documentation/documentation.service';
import { IDocumentationMeta } from '../redux/documentation/documentation.types';
import { setDocumentationMeta } from '../redux/documentation/documentation.redux';

export function useDocumentationMeta(companyId: string) {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    if (companyId) {
      const documentationMetaService = new DocumentationMetaService(companyId);

      unsubscribe = documentationMetaService.subscribeToMetadataChanges((metadata: IDocumentationMeta) => {
        dispatch(setDocumentationMeta(metadata));
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [companyId]);
}
