import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s4};
`;

export const BillingCards = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: ${({ theme }) => theme.spacing.s5};
`;

export const BillingCard = styled.div`
  padding: ${({ theme }) => theme.spacing.s3};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s1};

  border: 0.1rem solid ${props => props.theme.colors.border.dark};
  border-radius: ${({ theme }) => theme.radius.xs};
`;

export const StatisticsHead = styled.div`
  margin-top: ${({ theme }) => theme.spacing.s6};
  padding: ${({ theme }) => `${theme.spacing.s5} ${theme.spacing.s7}`};
  display: grid;
  grid-template-columns: 30% 1fr auto;

  background: ${({ theme }) => theme.colors.bg.dark};
`;

export const StatisticsItem = styled.div`
  padding: ${({ theme }) => `${theme.spacing.s5} ${theme.spacing.s7}`};
  display: grid;
  grid-template-columns: 30% 1fr auto;

  border: 0.1rem solid ${({ theme }) => theme.colors.border.default};
  border-right: 0;
  border-left: 0;
`;
