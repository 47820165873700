import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { ICustomerWithId, ICustomersState, ECustomerStatus, TCustomersSort } from './customers.types';

const initialState: ICustomersState = {
  customer: {
    id: '',
    status: ECustomerStatus.new,
    firstName: '',
    lastName: '',
    sessionId: '',
    companyId: '',
    email: '',
    phone: '',
    updatedAt: '',
    createdAt: '',
  },
  customers: [],
  sortBy: undefined,
  isDescendingOrder: false,
  searchValue: '',
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    getCustomers: (state: ICustomersState, action: PayloadAction<string>) => {},
    setCustomers: (state: ICustomersState, action: PayloadAction<ICustomersState['customers']>) => {
      state.customers = action.payload;
    },
    getCustomer: (state: ICustomersState, action: PayloadAction<{ customerId: string; companyId: string }>) => {},
    setCustomer: (state: ICustomersState, action: PayloadAction<ICustomerWithId>) => {
      state.customer = action.payload;
    },
    resetCustomer: (state: ICustomersState) => {
      state.customer = initialState.customer;
    },
    setSort: (state: ICustomersState, action: PayloadAction<TCustomersSort | undefined>) => {
      const newSort = action.payload;
      const oldSort = state.sortBy;
      const descending = state.isDescendingOrder;

      if (newSort !== oldSort) {
        state.sortBy = newSort;
        state.isDescendingOrder = true;
      } else if (descending) {
        state.isDescendingOrder = false;
      } else {
        state.sortBy = undefined;
      }
    },
    searchCustomers: (state: ICustomersState, action: PayloadAction<string>) => {
      if (state.searchValue !== action.payload) state.searchValue = action.payload;
    },
  },
});

export const { getCustomers, setCustomers, getCustomer, setCustomer, resetCustomer, setSort, searchCustomers } = customersSlice.actions;

export const customersReducer: Reducer<ICustomersState> = customersSlice.reducer;
