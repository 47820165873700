import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 2rem;
`;

export const ChatWrapper = styled.div<{ $isEmpty: boolean; $noSelectedChat: boolean }>`
  display: grid;
  grid-template-columns: 40.5rem 1fr;
  height: 100vh;
  overflow-y: auto;

  ${props => props.$noSelectedChat && 'grid-template-columns:40.5rem 1fr; align-items: center; justify-content: center;'}
`;

export const ChatBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const ChatHeaderLink = styled.button`
  padding: ${({ theme }) => `${theme.spacing.s3} ${theme.spacing.s4}`};
  margin: ${({ theme }) => theme.spacing.s6};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s2};

  background: ${({ theme }) => theme.colors.bg.default};
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: ${({ theme }) => theme.radius.xl};
  border: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.bg.dark};
  }
`;
