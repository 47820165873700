import styled from 'styled-components';
import { HideScrollbar } from '../../GlobalStyle';

export const List = styled.div`
  padding: 3.2rem 2.4rem 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  gap: ${({ theme }) => theme.spacing.s3};

  border-right: 0.1rem solid ${props => props.theme.colors.border.dark};
  overflow-y: auto;
  ${HideScrollbar}
`;

export const CallItem = styled.div<{ $isActive: boolean }>`
  padding: ${({ theme }) => `${theme.spacing.s5} ${theme.spacing.s4}`};
  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${({ theme }) => theme.spacing.s3};

  border-radius: ${({ theme }) => theme.radius.s};
  background: ${({ theme, $isActive }) => ($isActive ? theme.colors.brand.lightness : 'transparent')};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.brand.lightness};
  }
`;

export const CallItemCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s2};
`;
