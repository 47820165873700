import { Controller } from 'react-hook-form';
import * as SC from './styles';
import { Typography } from '../Typography/Typography';

export interface IRadioProps {
  name: string;
  label?: string;
  onChange?: (e: any) => void;
  value?: string;
  disabled?: boolean;
  control?: any;
}

function Radio({ name, label, value, disabled = false, control }: IRadioProps) {
  return (
    <SC.Label $disabled={disabled}>
      <Controller
        key={value}
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <SC.Input type="radio" checked={field.value === value} {...field} value={value} disabled={disabled} />
            <SC.InputWrapper $checked={field.value === value} />
          </>
        )}
      />
      <Typography variant="body">{label}</Typography>
    </SC.Label>
  );
}

export default Radio;
