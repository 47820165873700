import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 5rem;
`;

export const Header = styled.div`
  padding-bottom: 1.2rem;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;

  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray200};
`;

export const UserList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s1};
`;

export const User = styled.div`
  padding: ${({ theme }) => theme.spacing.s3};
  display: grid;
  gap: 0.8rem;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;

  cursor: pointer;
  transition: all 0.2s;
  border-radius: ${({ theme }) => theme.radius.xl};
  &:hover {
    background: ${({ theme }) => theme.colors.bg.dark};
  }
`;

export const UserText = styled.div`
  margin-left: 0.6rem;
  display: flex;
  flex-direction: column;
`;
