import styled from 'styled-components';

export const Wrapper = styled.div<{ $color: string; $bgColor: string }>`
  padding: 0.2rem 1rem;
  width: fit-content;

  font-size: 1.1rem;
  font-weight: 500;
  color: ${({ $color }) => $color};
  line-height: 150%;

  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: ${({ theme }) => theme.radius.xl};
`;
