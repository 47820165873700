import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { IQAWithId, IQAState, IQATag, IAnswerQACreate } from './qa.types';

export const initialState: IQAState = {
  qAList: [],
  openedQAIds: [],
  selectedQAIds: [],
  isDeleteQAModalOpen: false,
  tags: [],
  tagColors: [],
  tagOrder: [],
  deleteTagId: undefined,
  activeQa: undefined,
  filter: {
    tag: undefined,
    search: undefined,
    page: 1,
  },
  pageCount: 1,
};

export const qaSlice = createSlice({
  name: 'qa',
  initialState,
  reducers: {
    saveQA: (state: IQAState, action: PayloadAction<Omit<IQAWithId, 'charCount'>>) => {},
    saveQaAnswer: (state: IQAState, action: PayloadAction<IAnswerQACreate>) => {},
    deleteQAs: () => {},
    selectQA: (state: IQAState, action: PayloadAction<number>) => {
      const id = action.payload;
      if (state.selectedQAIds.includes(id)) {
        state.selectedQAIds = state.selectedQAIds.filter(item => item !== id);
      } else {
        state.selectedQAIds = [...state.selectedQAIds, id];
      }
    },
    selectAllQAs: (state: IQAState) => {
      state.selectedQAIds = state.qAList.map(qa => qa.id);
    },
    unselectAllQAs: (state: IQAState) => {
      state.selectedQAIds = [];
    },
    showDeleteQaModal: (state: IQAState) => {
      state.isDeleteQAModalOpen = true;
    },
    closeDeleteQaModal: (state: IQAState) => {
      state.selectedQAIds = [];
      state.isDeleteQAModalOpen = false;
    },
    getQAList: (state: IQAState, action: PayloadAction<string>) => {},
    setQAList: (state: IQAState, action: PayloadAction<IQAWithId[]>) => {
      state.qAList = action.payload ?? [];
    },
    getQaById: (state: IQAState, action: PayloadAction<{ companyId: string; qaId: number }>) => {},
    setActiveQa: (state: IQAState, action: PayloadAction<IQAWithId>) => {
      state.activeQa = action.payload;
    },
    updateQA: (state: IQAState, action: PayloadAction<{ oldId?: number; qa: IQAWithId }>) => {
      const { oldId, qa } = action.payload;
      if (oldId) {
        const qaIndex = state.qAList.findIndex(item => item.id === oldId);
        state.qAList[qaIndex] = qa;
      } else {
        const qaIndex = state.qAList.findIndex(item => item.id === qa.id);
        state.qAList[qaIndex] = qa;
      }
    },
    getTags: (state: IQAState, action: PayloadAction<string>) => {},
    setTags: (state: IQAState, action: PayloadAction<IQAState['tags']>) => {
      state.tags = action.payload;
    },
    updateTagsListItem: (state: IQAState, action: PayloadAction<IQATag>) => {
      const newTag = action.payload;
      const oldTags = [...state.tags].filter(item => item.id !== newTag.id);
      state.tags = [...oldTags, newTag];
    },
    getTagOrder: (state: IQAState, action: PayloadAction<string>) => {},
    setTagOrder: (state: IQAState, action: PayloadAction<number[]>) => {
      state.tagOrder = action.payload;
    },
    updateTagOrder: (state: IQAState, action: PayloadAction<{ id: number; order: number }>) => {},
    getTagColors: () => {},
    setTagColors: (state: IQAState, action: PayloadAction<IQAState['tagColors']>) => {
      state.tagColors = action.payload;
    },
    createTag: (state: IQAState, action: PayloadAction<{ name: string; callback?: (id: number) => void }>) => {},
    updateTag: (state: IQAState, action: PayloadAction<IQATag>) => {},
    showDeleteTagModal: (state: IQAState, action: PayloadAction<number>) => {
      state.deleteTagId = action.payload;
    },
    closeDeleteTagModal: (state: IQAState) => {
      state.deleteTagId = undefined;
    },
    deleteTag: (state: IQAState, action: PayloadAction<number>) => {},
    deleteTagFromQAs: (state: IQAState, action: PayloadAction<number>) => {
      const qaList = [...state.qAList].map(qa => ({ ...qa, tags: qa.tags?.filter(item => item !== action.payload) }));
      state.qAList = qaList;
    },
    setFilterTag: (state: IQAState, action: PayloadAction<IQATag | undefined>) => {
      state.filter.tag = action.payload;
      state.filter.page = 1;
    },
    setFilterSearch: (state: IQAState, action: PayloadAction<string>) => {
      state.filter.search = action.payload;
      state.filter.page = 1;
    },
    setFilterPage: (state: IQAState, action: PayloadAction<number>) => {
      state.filter.page = action.payload;
    },
    setPageCount: (state: IQAState, action: PayloadAction<number>) => {
      state.pageCount = action.payload;
    },
    resetFilter: (state: IQAState) => {
      state.filter = {
        tag: undefined,
        page: 1,
        search: undefined,
      };
    },
    openQA: (state: IQAState, action: PayloadAction<number>) => {
      const id = action.payload;
      if (state.openedQAIds.includes(id)) {
        state.openedQAIds = state.openedQAIds.filter(item => item !== id);
      } else {
        state.openedQAIds = [...state.openedQAIds, id];
      }
    },
    closeQAs: (state: IQAState) => {
      state.openedQAIds = [];
    },
  },
});

export const {
  saveQA,
  deleteQAs,
  selectQA,
  selectAllQAs,
  unselectAllQAs,
  showDeleteQaModal,
  closeDeleteQaModal,
  getQAList,
  getQaById,
  setActiveQa,
  setQAList,
  openQA,
  getTags,
  setTags,
  setFilterTag,
  updateQA,
  getTagColors,
  setTagColors,
  createTag,
  updateTag,
  deleteTag,
  updateTagsListItem,
  getTagOrder,
  setTagOrder,
  updateTagOrder,
  showDeleteTagModal,
  closeDeleteTagModal,
  deleteTagFromQAs,
  setFilterSearch,
  saveQaAnswer,
  setPageCount,
  setFilterPage,
  resetFilter,
} = qaSlice.actions;

export const qaReducer: Reducer<IQAState> = qaSlice.reducer;
