import { zodResolver } from '@hookform/resolvers/zod';
import * as SC from './styles';
import UploadImage from '../UploadImage/UploadImage';
import Input from '../Input/Input';
import RadioField from '../RadioField/RadioField';
import { Schema } from 'zod';
import { useConnectedForm } from '../../hooks/useConnectedForm';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { FormField } from '../../common/types';

interface IProps {
  id: string;
  fields: FormField[];
  validationSchema: Schema;
  onSubmit: (data: any) => void;
  isLoading?: boolean;
}

function EntityForm({ id, fields, validationSchema, onSubmit, isLoading = false }: IProps) {
  const imageField = fields.find(field => field.type === 'image');
  const {
    control,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useConnectedForm({
    id,
    resolver: zodResolver(validationSchema),
    validationSchema,
    onSubmit: handleSubmit,
    defaultValues: fields.reduce((acc, field) => ({ ...acc, [field.name]: field.defaultValue }), {}),
    mode: 'onBlur',
  });
  const image = imageField?.name ? getValues(imageField?.name) : null;

  async function onSubmitValidation(data: typeof validationSchema) {
    // TODO: this is a really ugly hack because the .refine method for zod validation is getting called on every change instead of only onBlur resulting in many calls to check if email already registered. The solution for now is to only do it here on submit.
    for (const key of Object.keys(data)) {
      if (fields.find(field => field.name === key)?.uniqueEmail) {
        try {
          const signInMethods = await fetchSignInMethodsForEmail(auth, data[key as keyof typeof data]);
          if (signInMethods.length > 0) {
            setError(key, {
              type: 'manual',
              message: 'Email already registered.',
            });
            return false;
          }
        } catch (error) {
          return false;
        }
      }
    }
    return true;
  }

  async function handleSubmit(data: typeof validationSchema) {
    const valid = await onSubmitValidation(data);
    if (valid) {
      onSubmit(data);
    }
  }

  function getFields() {
    return fields.map(field => {
      if (field.type === 'image') return;

      if (field.options) {
        return (
          <RadioField
            key={field.name}
            required={field.required}
            disabled={field.disabled}
            name={field.name}
            label={field.label}
            options={field.options}
            control={control}
            error={errors[field.name]}
          />
        );
      }

      return (
        <Input
          key={field.name}
          required={field.required}
          disabled={isLoading ? isLoading : field.disabled}
          name={field.name}
          label={field.label}
          type={field.type}
          control={control}
          error={errors[field.name]}
          placeholder={field.placeholder}
        />
      );
    });
  }

  return (
    <SC.Form>
      {!!imageField && <UploadImage name={imageField.name} control={control} image={image} setValue={setValue} />}
      {getFields()}
    </SC.Form>
  );
}

export default EntityForm;
