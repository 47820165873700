import * as SC from './styles';
import IconButton from '../IconButton/IconButton';
import { IconName } from '../Icon/Icon';
import { useColors } from '../../hooks/theme';
import { useRef, useState, FocusEvent } from 'react';
import { Typography } from '../Typography/Typography';
import Tooltip from '../Tooltip/Tooltip';
import { useDispatch } from 'react-redux';
import { IQATag, IQATagColor } from '../../redux/qa/qa.types';
import { showDeleteTagModal, updateTag, updateTagOrder } from '../../redux/qa/qa.redux';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import TagsMenu from '../QATagsMenu/QATagsMenu';

interface ITagsProps {
  tags?: number[];
  onAdd: (id: number) => void;
  onCreate: (tag: string) => void;
  onDelete: (id: number) => void;
  placeholder: string;
  title?: string;
  tooltipText?: string;
  tagOrder: number[];
  tagColors: IQATagColor[];
  companyTags: IQATag[];
  isDeleteTagOpen: boolean;
}

export default function Tags({
  tags = [],
  onCreate,
  onDelete,
  placeholder,
  title,
  tooltipText,
  onAdd,
  tagOrder,
  tagColors,
  companyTags,
  isDeleteTagOpen,
}: ITagsProps) {
  const colors = useColors();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [menuState, setMenuState] = useState<{ isOpen: boolean; isTop: boolean }>({ isOpen: false, isTop: true });
  const [editTagId, setEditTagId] = useState<undefined | number>(undefined);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const inputTagsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOnClickOutside(inputWrapperRef, handleMenuClose, [inputTagsRef]);

  function handleFocus(e: FocusEvent<HTMLInputElement>) {
    const inputElement = e.target;
    const rect = inputElement.getBoundingClientRect();
    const offsetToPageBottom = document.documentElement.scrollHeight - (rect.bottom + window.scrollY);

    setMenuState({ isOpen: true, isTop: offsetToPageBottom > 400 });
  }

  function handleMenuClose() {
    if (isDeleteTagOpen) return;

    if (editTagId) {
      setEditTagId(undefined);
      return;
    }

    setMenuState({ isOpen: false, isTop: true });
  }

  function handleKeydown(e: any) {
    if (e.key === 'Backspace' && !inputValue && tags.length) {
      onDelete(tags.at(-1)!);
    }

    if (e.key === 'Enter' && inputValue) {
      const filteredTags = companyTags.filter(tag => tag.label?.toLowerCase().includes(inputValue?.toLowerCase()));

      if (!filteredTags.length) {
        onCreate(inputValue);
        setInputValue('');
        return;
      }

      const firstSuggestionId = filteredTags[0].id;
      if (!tags.includes(firstSuggestionId)) {
        onAdd(firstSuggestionId);
      }

      setInputValue('');
    }
  }

  function handleAdd(id: number) {
    if (editTagId) setEditTagId(undefined);
    onAdd(id);
    setInputValue('');
  }

  function handleChange(e: any) {
    const { value } = e.target;
    setInputValue(value);
  }

  function stopPropagation(e: any) {
    e.stopPropagation();
  }

  function handleUpdateTag(tag: IQATag) {
    dispatch(updateTag(tag));
  }

  function handleCreateTag() {
    onCreate(inputValue);
    setInputValue('');
  }

  function handleUpdateTagOrder(id: number, order: number) {
    dispatch(updateTagOrder({ id, order }));
  }

  function handleRemoveTag(id: number) {
    onDelete(id);
  }

  function handleDelete(id: number) {
    dispatch(showDeleteTagModal(id));
  }

  function handleSetEditTag(id?: number) {
    setEditTagId(id);
  }

  function handleTagsClick() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  function getTag(id: number) {
    const tag = companyTags.find(tag => tag.id === id);
    const color = tagColors.find(color => color.id === tag?.colorId);

    return (
      <SC.Tag onClick={stopPropagation} key={id} title={tag?.label} $background={color?.secondaryColor ?? ''}>
        <SC.TagText $color={color?.color ?? ''}>{tag?.label}</SC.TagText>
        <IconButton id={tag?.id} onClick={handleRemoveTag} iconName={IconName.X} iconColor={color?.color ?? ''} />
      </SC.Tag>
    );
  }

  return (
    <SC.Wrapper>
      <SC.TagHeader>
        {title && <Typography variant="label">{title}</Typography>}
        {tooltipText && <Tooltip id="tagsTooltip" text={tooltipText} iconName={IconName.InfoCircle} iconColor={colors.text.secondary} />}
      </SC.TagHeader>
      <SC.Tags onClick={handleTagsClick} ref={inputTagsRef}>
        {tags.map(tag => getTag(tag))}
        <SC.NewTagWrapper ref={inputWrapperRef}>
          <SC.InputWrapper>
            <SC.Input
              onKeyDown={handleKeydown}
              onChange={handleChange}
              placeholder={placeholder}
              onFocus={handleFocus}
              list="suggestions"
              value={inputValue}
              ref={inputRef}
            />
          </SC.InputWrapper>
          {menuState.isOpen && (
            <TagsMenu
              companyTags={companyTags}
              inputValue={inputValue}
              onUpdate={handleUpdateTag}
              onCreate={handleCreateTag}
              onAdd={handleAdd}
              onUpdateOrder={handleUpdateTagOrder}
              addedTags={tags}
              tagOrder={tagOrder}
              tagColors={tagColors}
              isTop={menuState.isTop}
              onDelete={handleDelete}
              editTagId={editTagId}
              onSetEditTag={handleSetEditTag}
            />
          )}
        </SC.NewTagWrapper>
      </SC.Tags>
    </SC.Wrapper>
  );
}
