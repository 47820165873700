import styled from 'styled-components';
import { WithCustomScrollbar } from '../../GlobalStyle';

export const TagsMenu = styled.div<{ $isTop: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  ${({ $isTop }) => ($isTop ? 'top:100%;' : 'bottom:100%;')}
  width: 22rem;
  height: auto;
  max-height: 30rem;
  padding: ${({ theme }) => `${theme.spacing.s4} ${theme.spacing.s2} ${theme.spacing.s4} ${theme.spacing.s2}`};

  border-radius: ${({ theme }) => theme.radius.s};
  background: ${({ theme }) => theme.colors.bg.default};
  box-shadow: ${({ theme }) => theme.shadows.light};
  z-index: 1;
`;

export const TagsMenuList = styled.div`
  max-height: 17rem;
  overflow-x: auto;
  margin: ${({ theme }) => `${theme.spacing.s3} 0 ${theme.spacing.s4}`};
  display: flex;
  flex-direction: column;
  ${WithCustomScrollbar}
`;

export const TagsMenuTitle = styled.div`
  margin-left: ${({ theme }) => theme.spacing.s3};
  display: flex;
`;

export const TagsMenuNew = styled.button`
  padding: ${({ theme }) => `${theme.spacing.s2} ${theme.spacing.s3}`};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s2};
  width: 100%;

  background: none;
  border: none;
  transition: all 0.2s;
  border-radius: ${({ theme }) => theme.radius.xl};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.bg.dark};
  }
`;

export const TagsMenuNewTag = styled.span`
  padding: ${({ theme }) => `${theme.spacing.s1} ${theme.spacing.s2}`};

  color: ${({ theme }) => theme.colors.text.default};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  border-radius: ${({ theme }) => theme.radius.xl};
  background: ${({ theme }) => theme.colors.bg.darkest};
`;

export const TagsEditMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 20rem;
  height: 30rem;
  padding: ${({ theme }) => `${theme.spacing.s2} ${theme.spacing.s2}`};

  border-radius: ${({ theme }) => theme.radius.s};
  background: ${({ theme }) => theme.colors.bg.default};
  box-shadow: ${({ theme }) => theme.shadows.light};
`;

export const TagsMenuItem = styled.div<{ $isAdded: boolean }>`
  width: 100%;
  height: 4.2rem;
  position: relative;
  padding: ${({ theme }) => `0 ${theme.spacing.s1}`};
  padding-left: ${({ theme }) => `${theme.spacing.s3}`};
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: ${({ theme }) => theme.spacing.s2};
  margin-bottom: ${({ theme }) => theme.spacing.s1};

  cursor: pointer;
  transition: all 0.2s;
  border-radius: ${({ theme }) => theme.radius.xl};
  user-select: none;
  background: ${({ theme, $isAdded }) => ($isAdded ? theme.colors.bg.darkest : 'transparent')};

  &:hover {
    background: ${({ theme }) => theme.colors.bg.dark};
  }
`;

export const TagsMenuItemHandle = styled.div`
  background: none;
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: grab;
`;

export const TagsMenuItemText = styled.div<{ $textColor: string; $backgroundColor: string }>`
  padding: ${({ theme }) => `${theme.spacing.s1} ${theme.spacing.s2}`};
  max-width: fit-content;

  font-size: 1.2rem;
  font-weight: 600;
  line-height: 150%;
  color: ${({ $textColor }) => $textColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.radius.xl};
`;
