import { ChangeEvent, useEffect, useState } from 'react';
import { IQATag, IQATagColor } from '../../redux/qa/qa.types';
import ActionButton from '../ActionButton/ActionButton';
import { IconName } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../hooks/useDebounce';

interface IQATagsMenuDetails {
  colors: IQATagColor[];
  tag: IQATag;
  isTop: boolean;
  onDelete: (id: number) => void;
  onUpdate: (tag: IQATag) => void;
}

export default function QATagsMenuDetails({ colors, tag, isTop, onDelete, onUpdate }: IQATagsMenuDetails) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(tag.label);

  const debouncedInputValue = useDebounce(inputValue);
  function handleChangeColor(colorId: number) {
    onUpdate({ ...tag, colorId });
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
  }

  useEffect(() => {
    setInputValue(tag.label);
  }, [tag]);

  useEffect(() => {
    if (debouncedInputValue !== tag.label) {
      onUpdate({ ...tag, label: debouncedInputValue });
    }
  }, [debouncedInputValue]);

  return (
    <SC.Details $isTop={isTop}>
      <SC.DetailsInput value={inputValue} onChange={handleInputChange} />
      <SC.DetailsDelete>
        <ActionButton onClick={onDelete} id={tag.id} iconName={IconName.Trash} text={t('qa.tagsDelete')} />
      </SC.DetailsDelete>
      <SC.DetailsColorsTitle>
        <Typography variant="caption">{t('qa.tagsColorsTitle')}</Typography>
      </SC.DetailsColorsTitle>
      <SC.DetailsColors>
        {colors.map(color => (
          <SC.DetailsColorsItem key={color.id} $isActive={tag.colorId === color.id} onClick={() => handleChangeColor(color.id)}>
            <SC.DetailsColorsItemCircle $backgroundColor={color.secondaryColor} $borderColor={color.color} />
            <Typography variant="caption">{color.label}</Typography>
          </SC.DetailsColorsItem>
        ))}
      </SC.DetailsColors>
    </SC.Details>
  );
}
