import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListHeader = styled.div`
  padding: ${({ theme }) => `${theme.spacing.s6} ${theme.spacing.s7}`};
  display: grid;
  grid-template-columns: 30% 30% 1fr;
  gap: ${({ theme }) => theme.spacing.s3};

  border-radius: ${({ theme }) => `${theme.radius.m} ${theme.radius.m} 0 0`};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.border.default};
`;

export const ChatItem = styled.div`
  padding: ${({ theme }) => `${theme.spacing.s6} ${theme.spacing.s7}`};
  display: grid;
  grid-template-columns: 30% 30% 1fr auto;
  gap: ${({ theme }) => theme.spacing.s3};

  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.border.default};

  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.bg.dark};
  }

  &:last-child {
    border-radius: ${({ theme }) => `0 0 ${theme.radius.m} ${theme.radius.m}`};
    border-bottom: 0;
  }
`;
