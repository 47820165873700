import { StorageReference, deleteObject, getDownloadURL, getMetadata, ref } from 'firebase/storage';
import { logger } from '../../logger';
import axiosInstance from '../../config/axios';
import { storage } from '../../config/firebase';
import { convertToCamelCase } from '@tuler/shared';
import { t } from 'i18next';

export class DocumentsService {
  async upload(companyId: string, document: any) {
    try {
      const formData = new FormData();
      formData.append(`document`, document);

      const res = await axiosInstance.post(`/storage/${companyId}/document`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('DocumentsService.uploadDocument', e);
      return {
        success: false,
        errorMessage: t('documents.uploadFailedAlreadyExists'),
      };
    }
  }
}
