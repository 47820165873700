import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { fetchAndActivate, getAll, getRemoteConfig } from 'firebase/remote-config';
import { EFeatureFlag, TFeatureConfig } from '../redux/authUser/authUser.types';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = firebase.initializeApp(config);
const db = getFirestore(app);
const functions = getFunctions();
const auth = getAuth();
const storage = getStorage();

if (process.env.NODE_ENV === 'development') {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, '127.0.0.1', 9199);
}

async function fetchRemoteConfigs(): Promise<TFeatureConfig> {
  const remoteConfig = getRemoteConfig(app);
  // Defines the maximum age in milliseconds of an entry in the config cache before it is considered stale. 10 minutes in this case
  remoteConfig.settings.minimumFetchIntervalMillis = 600000;
  await fetchAndActivate(remoteConfig);
  const featureFlags = getAll(remoteConfig);
  const dynamicFeatureFlags: TFeatureConfig = {};

  Object.keys(EFeatureFlag).forEach(key => {
    dynamicFeatureFlags[key] = featureFlags[EFeatureFlag[key as keyof typeof EFeatureFlag]].asBoolean();
  });

  return dynamicFeatureFlags;
}

export { db, functions, auth, storage, fetchRemoteConfigs };
