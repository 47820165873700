import { convertToCamelCase } from '@tuler/shared';
import axiosInstance from '../../config/axios';
import { db } from '../../config/firebase';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { logger } from '../../logger';

export class NotificationsService {
  public getNotification = async (companyId: string, notificationId: string) => {
    try {
      const res = await axiosInstance.get(`companies/${companyId}/notifications/${notificationId}`);
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('QAsService.createTag', e);
      return {
        success: false,
      };
    }
  };
  public markAsRead = async (userId: string, notificationId: string) => {
    try {
      const usersRef = doc(db, 'users', userId);
      const notificationRef = doc(collection(usersRef, 'notifications'), notificationId);
      await updateDoc(notificationRef, { read: true });
    } catch (e) {
      throw new Error(`markAsReadRequest: ${e}`);
    }
  };
}
