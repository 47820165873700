import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { ILoaderState } from './loader.types';

const initialState: ILoaderState = {
  isGlobalLoading: false,
  isPageLoading: false,
  isModalLoading: false,
  message: '',
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setGlobalLoading: (state: ILoaderState, action: PayloadAction<boolean>) => {
      state.isGlobalLoading = action.payload;
    },
    setPageLoading: (state: ILoaderState, action: PayloadAction<boolean>) => {
      state.isPageLoading = action.payload;
    },
    showModalLoading: (state: ILoaderState, action: PayloadAction<string>) => {
      state.isModalLoading = true;
      state.message = action.payload;
    },
    closeModalLoading: (state: ILoaderState) => {
      state.isModalLoading = false;
      state.message = '';
    },
  },
});

export const { setGlobalLoading, setPageLoading, showModalLoading, closeModalLoading } = loaderSlice.actions;

export const loaderReducer: Reducer<ILoaderState> = loaderSlice.reducer;
