import styled from 'styled-components';

export const TabsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.s2};
  border-radius: ${({ theme }) => theme.radius.xl};
  background: ${props => props.theme.colors.bg.default};
`;

export const Tabs = styled.div<{ $tabCount: number }>`
  position: relative;

  display: grid;
  align-items: center;
  grid-template-columns: repeat(${({ $tabCount }) => $tabCount}, 1fr);
`;

export const TabsBg = styled.div<{ $activeTab: number; $tabCount: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ $activeTab, $tabCount }) => ` calc( ${$activeTab} *  ${100 / $tabCount}%)`};
  width: calc(100% / ${({ $tabCount }) => $tabCount});

  border-radius: ${({ theme }) => theme.radius.xl};
  background: ${({ theme }) => theme.colors.bg.dark};
  box-shadow: ${({ theme }) => theme.shadows.md};
  transition: all 0.5s ease-in-out;
`;

export const TabsItem = styled.div<{ $isActive: boolean; $isWithBorder: boolean }>`
  padding: 0.8rem 1.2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  color: ${({ $isActive, theme }) => ($isActive ? theme.colors.text.default : theme.colors.text.secondary)};

  transition: all 0.2s;
  z-index: 1;
  cursor: pointer;

  user-select: none;

  &:hover {
    color: ${({ theme }) => theme.colors.text.default};
  }

  &:first-child:before {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    height: 2rem;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 0.1rem;

    background: ${({ theme, $isWithBorder }) => ($isWithBorder ? theme.colors.border.dark : 'transparent')};
  }
`;
