import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { goBackAction, pushAction, replaceAction } from '../../redux/navigation/navigation.redux';

interface QueryParams {
  [key: string]: string | null;
}

export function useURLParams(): QueryParams {
  const [searchParams] = useSearchParams();
  const params: QueryParams = {};

  for (const [key, value] of searchParams.entries()) {
    params[key as string] = value;
  }

  return params;
}

export function usePush() {
  const dispatch = useDispatch();
  return (url: string) => dispatch(pushAction(url));
}

export function usePushQuery() {
  const dispatch = useDispatch();
  const location = useLocation();

  return (query: { [key: string]: string | number }) => {
    const queryParams = new URLSearchParams(location.search);

    for (const [key, value] of Object.entries(query)) {
      queryParams.set(key, value as string);
    }

    dispatch(pushAction(`${location.pathname}?${queryParams.toString()}`));
  };
}

export function useRemoveQuery() {
  const dispatch = useDispatch();
  const location = useLocation();

  return (keysToRemove: string[]) => {
    const queryParams = new URLSearchParams(location.search);

    for (const key of keysToRemove) {
      queryParams.delete(key);
    }

    dispatch(pushAction(`${location.pathname}?${queryParams.toString()}`));
  };
}

export function useReplace() {
  const dispatch = useDispatch();
  return (url: string) => dispatch(replaceAction(url));
}

export function useGoBack() {
  const dispatch = useDispatch();

  const current = useLocation();
  const hasBack = current.pathname.split('/').length > 2;

  return { hasBack, goBack: () => dispatch(goBackAction()) };
}
