import { useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import { Typography } from '../../components/Typography/Typography';
import * as SC from './styles';
import { Trans, useTranslation } from 'react-i18next';
import { IQAState } from '../../redux/qa/qa.types';
import { useDispatch, useSelector } from 'react-redux';
import { ICompanyState } from '../../redux/company/company.types';
import {
  closeDeleteTagModal,
  deleteTag,
  getQaById,
  getTagColors,
  getTagOrder,
  getTags,
  saveQA,
  saveQaAnswer,
} from '../../redux/qa/qa.redux';
import { usePushQuery, useURLParams } from '../../hooks/navigation';
import { getActiveNotification, resetActiveNotification } from '../../redux/notifications/notifications.redux';
import { INotificationsState } from '../../redux/notifications/notifications.types';
import { QAValidationType } from '../../schemas';
import InfoBox from '../../components/InfoBox/InfoBox';
import QAForm from '../../components/QAForm/QAForm';
import PageContent from '../../components/PageContent/PageContent';
import PromptModal from '../../components/PromptModal/PromptModal';

export default function EditQAPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tagOrder, tagColors, tags, deleteTagId, activeQa } = useSelector((state: { qa: IQAState }) => state.qa);
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const { activeNotification } = useSelector((state: { notifications: INotificationsState }) => state.notifications);
  const { notificationId, id: urlId } = useURLParams();
  const id = Number((activeNotification?.qaId ? activeNotification.qaId : urlId) ?? '0');
  const isNew = id === 0;
  const hasAnswer = !!activeNotification && activeNotification?.qaId === activeQa?.id;
  let title = isNew ? t('qa.newQATitle') : t('qa.editQATitle');
  if (notificationId) title = t('qa.unanswerQATitle');

  const newQa = {
    id: 0,
    question: activeNotification?.data?.question || '',
    answer: '',
    charCount: activeNotification?.data?.question.length,
  };

  function handleSaveQA(data: QAValidationType) {
    if (activeNotification && !activeNotification?.qaId) {
      dispatch(saveQaAnswer({ qa: data, notificationId: activeNotification.id }));
      return;
    }

    dispatch(saveQA({ ...data, id: id }));
  }

  function handleCloseDeleteTag() {
    dispatch(closeDeleteTagModal());
  }

  function handleDeleteTag() {
    if (deleteTagId) {
      dispatch(deleteTag(deleteTagId));
    }
  }

  useEffect(() => {
    if (companyId) {
      dispatch(getTags(companyId));
      dispatch(getTagColors());
      dispatch(getTagOrder(companyId));
    }
  }, [companyId]);

  useEffect(() => {
    if (id && !isNew && companyId) {
      dispatch(getQaById({ companyId, qaId: id }));
    }
  }, [id, companyId, activeNotification?.id]);

  useEffect(() => {
    if (notificationId && companyId) {
      dispatch(getActiveNotification(notificationId));
    }
    return () => {
      dispatch(resetActiveNotification());
    };
  }, [notificationId, companyId]);

  const qa = activeQa && !isNew ? activeQa : newQa;
  const qaKey = JSON.stringify(qa);

  return (
    <Layout>
      {deleteTagId && (
        <PromptModal
          variant="danger"
          title={t('qa.deleteTagTitle')}
          questionKey="qa.deleteTagQuestion"
          name={''}
          cancelText={t('qa.deleteTagCancel')}
          submitText={t('qa.deleteTagSubmit')}
          onClose={handleCloseDeleteTag}
          onSubmit={handleDeleteTag}
        />
      )}
      <PageContent hasBack={!notificationId} title={title}>
        {hasAnswer && (
          <SC.InfoBoxWrapper>
            <InfoBox level="success" message={t('qa.answerQASuccess')} />
          </SC.InfoBoxWrapper>
        )}
        <QAForm
          qa={qa}
          key={qaKey}
          onSubmit={handleSaveQA}
          tagOrder={tagOrder}
          tagColors={tagColors}
          companyTags={tags}
          isDeleteTagOpen={!!deleteTagId}
        />
      </PageContent>
    </Layout>
  );
}
