import styled from 'styled-components';

export const TagHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s1};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s2};
`;

export const Tags = styled.div`
  height: fit-content;
  padding: 0.4rem 1.6rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1.2rem;

  border-radius: ${({ theme }) => theme.radius.xs};
  border: 0.1rem solid ${({ theme }) => theme.colors.border.dark};
  cursor: pointer;
`;

export const Tag = styled.div<{ $background: string }>`
  height: 3.4rem;
  padding: ${({ theme }) => theme.spacing.s2};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s1};

  cursor: auto;
  color: ${({ theme }) => theme.colors.text.default};
  border-radius: ${({ theme }) => theme.radius.xl};
  background: ${({ $background }) => $background};
`;

export const TagText = styled.p<{ $color: string }>`
  max-width: 18rem;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: ${({ $color }) => $color};
`;

export const NewTagWrapper = styled.div`
  position: relative;
  width: 22rem;

  cursor: auto;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 3.4rem;
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  flex: 1;
  height: 1.8rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  width: 100%;

  background: none;
  border: none;
  outline: none;
  z-index: 2;
`;

export const InputSuggestion = styled.span<{ $color: string }>`
  position: absolute;
  top: 1.1rem;
  left: 0;
  height: 1.8rem;

  font-size: 1.2rem;
  line-height: 1.2rem;
  z-index: 1;
  color: ${({ $color }) => $color};
`;
