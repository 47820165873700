import { useEffect, useRef, useState } from 'react';
import { collection, doc, onSnapshot, query, Unsubscribe, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import { logger } from '../logger';
import { IMessageWithId, ISessionWithId, convertToCamelCase } from '@tuler/shared';
import { useDispatch } from 'react-redux';
import { setChatSessions, setSessionMessages, setSessionWithoutMessages } from '../redux/sessions/sessions.redux';
import { IDashboardSession } from '../types';
import { EDateFilter } from '../common/types';
import { getDateFilter, playNotificationSound } from '../common';

export default function useMessages(id: string) {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsubscribeMessages: Unsubscribe;

    if (!id) return;

    try {
      const messagesCollectionRef = collection(doc(db, 'sessions', id), 'messages');

      unsubscribeMessages = onSnapshot(messagesCollectionRef, querySnapshot => {
        const messages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        dispatch(
          setSessionMessages({
            id,
            messages: (convertToCamelCase(messages || []) as IMessageWithId[]).sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);

              return dateA.getTime() - dateB.getTime();
            }),
          }),
        );

        logger.warn('Messages subcollection event');
      });
    } catch (e) {
      logger.error('useMessages', e);
    }

    return () => {
      if (unsubscribeMessages) {
        unsubscribeMessages();
      }
    };
  }, [id]);
}

export function useSession(id: string) {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsubscribeSession: Unsubscribe;
    if (!id) return;

    try {
      unsubscribeSession = onSnapshot(doc(db, 'sessions', id), document => {
        const data = document.data();

        if (data) {
          const session = {
            ...data,
            id: document.id,
          } as IDashboardSession;
          dispatch(setSessionWithoutMessages(convertToCamelCase(session) as IDashboardSession));
        }

        logger.warn('Session event');
      });
    } catch (e) {
      logger.error('unsubscribeSessions', e);
    }

    return () => {
      if (unsubscribeSession) {
        unsubscribeSession();
      }
    };
  }, [id]);
}

export function useSessions(companyId: string, dateFilter: EDateFilter, currentSessionId: string, oldSessionsUnreadMessageCount: number) {
  const dispatch = useDispatch();
  const previousUnreadCountRef = useRef<number>(oldSessionsUnreadMessageCount);
  const isInitialLoadRef = useRef<boolean>(true);

  useEffect(() => {
    let unsubscribeSessions: Unsubscribe;

    if (!companyId) return;

    try {
      const currentFilter = getDateFilter(dateFilter);
      const sessionsRef = collection(db, 'sessions');
      const filteredSessionsQuery = query(
        sessionsRef,
        where('company_id', '==', companyId),
        where('updated_at', '>=', currentFilter.start),
        where('updated_at', '<=', currentFilter.end),
      );

      unsubscribeSessions = onSnapshot(filteredSessionsQuery, querySnapshot => {
        const sessions: ISessionWithId[] = querySnapshot.docs.map(
          doc =>
            convertToCamelCase({
              ...doc.data(),
              id: doc.id,
            }) as ISessionWithId,
        );

        if (sessions) {
          const sortedSessions = sessions.sort((a, b) => {
            const dateA = new Date(a.updatedAt);
            const dateB = new Date(b.updatedAt);
            return dateB.getTime() - dateA.getTime();
          });

          dispatch(setChatSessions(sortedSessions));

          const newSessionsUnreadMessageCount = sessions
            .filter(session => session.id !== currentSessionId)
            .reduce((acc, session) => {
              return acc + (session.userMessageUnseenCount ?? 0);
            }, 0);

          if (!isInitialLoadRef.current && newSessionsUnreadMessageCount > previousUnreadCountRef.current) {
            playNotificationSound();
          }

          previousUnreadCountRef.current = newSessionsUnreadMessageCount;
          isInitialLoadRef.current = false;
        }

        logger.warn('Session event');
      });
    } catch (e) {
      logger.error('Error subscribing to sessions', e);
    }

    return () => {
      if (unsubscribeSessions) {
        unsubscribeSessions();
      }
    };
  }, [companyId, dateFilter, currentSessionId]);
}
