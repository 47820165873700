import * as SC from './styles';
import Modal, { EModalSize } from '../Modal/Modal';
import Button from '../Button/Button';
import { useColors } from '../../hooks/theme';
import { Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Input from '../Input/Input';
import { Typography } from '../Typography/Typography';

type TPromptModalVariant = 'primary' | 'danger';

interface IPromptModalProps {
  title: string;
  questionKey?: string;
  name?: string;
  description?: string;
  variant?: TPromptModalVariant;
  inputTitle?: string;
  inputPlaceholder?: string;
  inputError?: string;
  cancelText: string;
  submitText: string;
  onClose: () => void;
  onSubmit: () => void;
}

export default function PromptModal({
  title,
  questionKey,
  name,
  description,
  variant = 'primary',
  cancelText,
  submitText,
  inputTitle,
  inputPlaceholder,
  inputError,
  onClose,
  onSubmit,
}: IPromptModalProps) {
  const colors = useColors();
  const needsConfirmation = !!inputTitle && !!inputPlaceholder && !!inputError;
  const validationSchema = z.object({
    confirmationText: z.string().refine(value => value === name, {
      message: inputError,
    }),
  });
  const {
    control,
    handleSubmit: submitForm,
    watch,
    formState: { errors },
  } = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
    defaultValues: { confirmationText: '' },
    mode: 'onBlur',
  });
  const handleSubmit = needsConfirmation ? submitForm(onSubmit) : onSubmit;
  const isNameCorrect = name === watch('confirmationText');

  return (
    <Modal onClose={onClose} size={EModalSize.small}>
      <SC.Body>
        <Typography variant="h2">{title}</Typography>
        {questionKey ? (
          <Typography variant="caption">
            <Trans i18nKey={questionKey} values={{ name }} components={{ bold: <strong /> }} />
          </Typography>
        ) : (
          <Typography variant="caption">{description}</Typography>
        )}
        {needsConfirmation && (
          <SC.InputWrapper>
            <Input
              required
              name="confirmationText"
              label={inputTitle}
              placeholder={inputPlaceholder}
              type="text"
              control={control}
              error={errors.confirmationText}
            />
          </SC.InputWrapper>
        )}
        <SC.Buttons>
          <Button onClick={onClose} color={colors.white} bgColor={colors.gray800} variant="secondary" expand>
            {cancelText}
          </Button>
          <Button onClick={() => handleSubmit()} variant={variant} disabled={needsConfirmation && !isNameCorrect} expand>
            {submitText}
          </Button>
        </SC.Buttons>
      </SC.Body>
    </Modal>
  );
}
