import styled from 'styled-components';

export const ErrorBody = styled.div`
  margin: 40px auto;
  max-width: 32.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.2rem;

  text-align: center;
`;

export const ErrorButtonWrapper = styled.div`
  width: 100%;
  margin: 3rem 3rem 0;
`;
