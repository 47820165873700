import { combineReducers } from 'redux';
import { usersReducer } from './users/users.redux';
import { companyReducer } from './company/company.redux';
import { loaderReducer } from './loader/loader.redux';
import { routerReducer } from './history';
import { sessionReducer } from './sessions/sessions.redux';
import { errorReducer } from './error/error.redux';
import { themeReducer } from './theme/theme.redux';
import { notificationsReducer } from './notifications/notifications.redux';
import { customersReducer } from './customers/customers.redux';
import { documentationReducer } from './documentation/documentation.redux';
import { agentsReducer } from './agents/agents.redux';
import { authUserReducer } from './authUser/authUser.redux';
import { toastsReducer } from './toasts/toasts.redux';
import { sidebarReducer } from './sidebar/sidebar.redux';
import { bannerReducer } from './banner/banner.redux';
import { navigationReducer } from './navigation/navigation.redux';
import { dashboardReducer } from './dashboard/dashboard.redux';
import { qaReducer } from './qa/qa.redux';
import { documentsReducer } from './documents/documents.redux';
import { telegramReducer } from './telegram/telegram.redux';
import { callsReducer } from './call/call.redux';
import { billingReducer } from './billing/billing.redux';
import { integrationsReducer } from './integrations/integrations.redux';

export default combineReducers({
  authUser: authUserReducer,
  users: usersReducer,
  sessions: sessionReducer,
  company: companyReducer,
  loader: loaderReducer,
  router: routerReducer,
  error: errorReducer,
  theme: themeReducer,
  notifications: notificationsReducer,
  customers: customersReducer,
  documentation: documentationReducer,
  agents: agentsReducer,
  toasts: toastsReducer,
  sidebar: sidebarReducer,
  banner: bannerReducer,
  navigation: navigationReducer,
  dashboard: dashboardReducer,
  qa: qaReducer,
  documents: documentsReducer,
  telegram: telegramReducer,
  calls: callsReducer,
  billing: billingReducer,
  integrations: integrationsReducer,
});
