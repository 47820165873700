import { Reducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IIntegrationsState,
  ITelegramNotificationWithId,
  ITrelloWithId,
  ISmartmovingWithId,
  ISmartmoving,
  ITelegramNotification,
  ITrello,
  IAircallWithId,
  IAircall,
} from './integrations.types';

const initialState: IIntegrationsState = {
  activeTelegramNotification: undefined,
  isTelegramNotificationLoading: false,
  activeTrello: undefined,
  isTrelloLoading: false,
  activeSmartmoving: undefined,
  isSmartmovingLoading: false,
  activeAircall: undefined,
  isAircallLoading: false,
};

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    getActiveTelegramNotification: (state: IIntegrationsState, action: PayloadAction<string>) => {},
    setActiveTelegramNotification: (state: IIntegrationsState, action: PayloadAction<ITelegramNotificationWithId | undefined>) => {
      state.activeTelegramNotification = action.payload;
    },
    createTelegramNotification: (state: IIntegrationsState, action: PayloadAction<ITelegramNotification>) => {},
    updateTelegramNotification: (state: IIntegrationsState, action: PayloadAction<ITelegramNotificationWithId>) => {},
    setIsTelegramNotificationLoading: (state: IIntegrationsState, action: PayloadAction<boolean>) => {
      state.isTelegramNotificationLoading = action.payload;
    },

    getActiveTrello: (state: IIntegrationsState, action: PayloadAction<string>) => {},
    setActiveTrello: (state: IIntegrationsState, action: PayloadAction<ITrelloWithId | undefined>) => {
      state.activeTrello = action.payload;
    },
    createTrello: (state: IIntegrationsState, action: PayloadAction<ITrello>) => {},
    updateTrello: (state: IIntegrationsState, action: PayloadAction<ITrelloWithId>) => {},
    setIsTrelloLoading: (state: IIntegrationsState, action: PayloadAction<boolean>) => {
      state.isTrelloLoading = action.payload;
    },

    getActiveSmartmoving: (state: IIntegrationsState, action: PayloadAction<string>) => {},
    setActiveSmartmoving: (state: IIntegrationsState, action: PayloadAction<ISmartmovingWithId | undefined>) => {
      state.activeSmartmoving = action.payload;
    },
    createSmartmoving: (state: IIntegrationsState, action: PayloadAction<ISmartmoving>) => {},
    updateSmartmoving: (state: IIntegrationsState, action: PayloadAction<ISmartmovingWithId>) => {},
    setIsSmartmovingLoading: (state: IIntegrationsState, action: PayloadAction<boolean>) => {
      state.isSmartmovingLoading = action.payload;
    },

    getActiveAircall: (state: IIntegrationsState, action: PayloadAction<string>) => {},
    setActiveAircall: (state: IIntegrationsState, action: PayloadAction<IAircallWithId | undefined>) => {
      state.activeAircall = action.payload;
    },
    createAircall: (state: IIntegrationsState, action: PayloadAction<IAircall>) => {},
    updateAircall: (state: IIntegrationsState, action: PayloadAction<IAircallWithId>) => {},
    setIsAircallLoading: (state: IIntegrationsState, action: PayloadAction<boolean>) => {
      state.isAircallLoading = action.payload;
    },
  },
});

export const {
  getActiveTelegramNotification,
  setActiveTelegramNotification,
  createTelegramNotification,
  updateTelegramNotification,
  getActiveTrello,
  setActiveTrello,
  createTrello,
  updateTrello,
  getActiveSmartmoving,
  setActiveSmartmoving,
  createSmartmoving,
  updateSmartmoving,
  setIsTelegramNotificationLoading,
  setIsTrelloLoading,
  setIsSmartmovingLoading,
  getActiveAircall,
  setActiveAircall,
  createAircall,
  updateAircall,
  setIsAircallLoading,
} = integrationsSlice.actions;

export const integrationsReducer: Reducer<IIntegrationsState> = integrationsSlice.reducer;
