import { db, storage } from '../../config/firebase';
import { logger } from '../../logger';
import { doc, getDocs, collection, getDoc, deleteDoc } from 'firebase/firestore';
import { ICompany, ICompanyWithId, ICreateCompany } from './company.types';
import axiosInstance from '../../config/axios';
import { deleteObject, listAll, ref } from 'firebase/storage';
import { convertToCamelCase, convertToSnakeCase } from '@tuler/shared';
import { push } from 'redux-first-history';
import { ROUTES } from '../../constants/routes';

export class CompanyService {
  public create = async (data: ICreateCompany) => {
    try {
      const res = await axiosInstance.post('companies/create', convertToSnakeCase(data));
      return res.data.success;
    } catch (e) {
      logger.error('CompanyService.create', e);
    }
  };

  public update = async (id: string, data: ICompany) => {
    try {
      const res = await axiosInstance.patch(`companies/${id}`, convertToSnakeCase(data));
      return res.data.success;
    } catch (e) {
      logger.error('CompanyService.update', e);
    }
  };
}

export async function getCompaniesRequest() {
  try {
    const res: ICompanyWithId[] = [];
    const querySnapshot = await getDocs(collection(db, 'companies'));
    querySnapshot.forEach(d => {
      if (d.id === 'default') return;

      res.push({ id: d.id, ...d.data() } as ICompanyWithId);
    });
    return convertToCamelCase(res);
  } catch (e) {
    logger.error('getCompaniesRequest', e);
  }
}

export async function getCompanyRequest(id: string) {
  try {
    const companyRef = doc(db, 'companies', id);
    const docSnap = await getDoc(companyRef);

    if (docSnap.exists()) {
      const company = { id: docSnap.id, ...docSnap.data() };
      if ('vapi_api_key' in company) {
        delete company.vapi_api_key;
      }

      return convertToCamelCase(company);
    }
  } catch (e) {
    logger.error('getCompanyRequest', e);
  }
}

export async function deleteCompanyRequest(id: string) {
  try {
    const companyRef = doc(db, 'companies', id);

    const subCollections = ['customers', 'documentation_meta', 'website', 'qa', 'texts'];

    for (const subCollection of subCollections) {
      const subCollectionRef = collection(companyRef, subCollection);
      const snapshot = await getDocs(subCollectionRef);
      if (!snapshot.empty) {
        const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
        await Promise.all(deletePromises);
      }
    }
    const res = (await axiosInstance.delete(`companies/${id}`)).data;
    await deleteDoc(companyRef);
    push(ROUTES.dashboard.path);
    return res.success;
  } catch (e) {
    logger.error('deleteCompanyRequest', e);
    return false;
  }
}

export async function deleteAgentsImageRequest(id: string) {
  try {
    const imagesRef = ref(storage, `${id}/agents`);
    if (imagesRef) {
      const imagesList = await listAll(imagesRef);
      await Promise.all(imagesList.items.map(async object => deleteObject(object)));
    }
  } catch (error) {
    throw new Error(`deleteAgentsImageRequest ${error}`);
  }
}

export async function deleteCompanyDocumentsRequest(id: string) {
  try {
    const documentsRef = ref(storage, `${id}/documents/default`);
    if (documentsRef) {
      const documentsList = await listAll(documentsRef);
      await Promise.all(
        documentsList.items.map(async object => {
          deleteObject(object);
        }),
      );
    }
  } catch (error) {
    throw new Error(`deleteCompanyDocumentsRequest ${error}`);
  }
}

export async function createEmailSenderRequest({
  email,
  name,
  companyId,
}: {
  email: string;
  name: string;
  companyId: string;
}): Promise<void> {
  try {
    const res = await axiosInstance.post(`users/email-sender`, { email, name, company_id: companyId });
    return res.data;
  } catch (e) {
    logger.error('createEmailSenderRequest', e);
  }
}

export async function deleteEmailSenderRequest({ email }: { email: string }): Promise<void> {
  try {
    await axiosInstance.delete(`users/email-sender`, { params: { email } });
  } catch (e) {
    logger.error('createEmailSenderRequest', e);
  }
}
