import styled from 'styled-components';
import { WithCustomScrollbar } from '../../GlobalStyle';

export const Select = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const SelectValue = styled.div<{ $expand: boolean }>`
  max-width: ${({ $expand }) => ($expand ? '100%' : '20rem')};
  padding: 1.2rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;

  background: ${props => props.theme.colors.bg.dark};
  border-radius: 2.1em;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background: ${props => props.theme.colors.bg.darkest};
  }
`;

export const SelectValueLabel = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectValueChevron = styled.div<{ $isActive: boolean }>`
  transition: all 0.2s;
  transform: rotate(${props => (props.$isActive ? '180deg' : '0')});
`;

export const SelectOptions = styled.div<{ $isOpen: boolean; $position: 'left' | 'right' }>`
  width: 20rem;
  padding: 0.8rem;
  position: absolute;
  max-height: 21.6rem;
  top: calc(100% + 0.6rem);
  right: ${({ $position }) => ($position === 'right' ? '0' : 'auto')};
  left: ${({ $position }) => ($position === 'left' ? '0' : 'auto')};

  background: ${props => props.theme.colors.white};
  border-radius: 2.4rem;
  overflow-y: auto;
  transition: all 0.2s;
  opacity: ${props => (props.$isOpen ? '1' : '0')};
  visibility: ${props => (props.$isOpen ? 'visible' : 'hidden')};
  transform: translateY(${props => (props.$isOpen ? '0' : '2rem')});
  box-shadow: ${({ theme }) => theme.shadows.md};
  z-index: 2;
  ${WithCustomScrollbar}
`;

export const SelectOption = styled.div<{ $isActive: boolean }>`
  padding: 1.2rem 1.2rem 1.3rem;
  display: flex;
  height: 4rem;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: ${({ theme }) => theme.spacing.s1};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: pointer;
  transition: all 0.2s;
  border-radius: 2.1rem;
  background: ${({ $isActive, theme }) => ($isActive ? theme.colors.bg.dark : theme.colors.bg.default)};

  &:hover {
    background: ${props => props.theme.colors.bg.darkest};
  }
`;

export const SelectOptionSpan = styled.span<{ $textColor: string | undefined }>`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${({ $textColor, theme }) => ($textColor ? $textColor : theme.colors.text.default)};
  line-height: 150%;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
