import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Input from '../Input/Input';
import { useConnectedForm } from '../../hooks/useConnectedForm';
import { QAValidationType, qAValidation } from '../../schemas';
import { IQATag, IQATagColor, IQAWithId } from '../../redux/qa/qa.types';
import { useDispatch } from 'react-redux';
import { createTag } from '../../redux/qa/qa.redux';
import Tags from '../QATags/QATags';
import { useEffect } from 'react';
import Card from '../Card/Card';

interface IQAFormProps {
  qa: IQAWithId;
  onSubmit: (data: QAValidationType, id: number) => void;
  tagOrder: number[];
  tagColors: IQATagColor[];
  companyTags: IQATag[];
  isDeleteTagOpen: boolean;
}

export default function QAForm({ qa, onSubmit, tagOrder, tagColors, companyTags, isDeleteTagOpen }: IQAFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useConnectedForm({
    onSubmit: handleSubmit,
    validationSchema: qAValidation,
    defaultValues: {
      question: qa.question,
      answer: qa.answer,
      tags: qa.tags,
    },
    mode: 'onBlur',
  });
  const tags: number[] = watch('tags');

  function handleSubmit(data: QAValidationType) {
    onSubmit(data, qa.id);
  }

  function handleCreateTag(tag: string) {
    if (tag)
      dispatch(createTag({ name: tag, callback: (id: number) => setValue('tags', tags ? [...tags, id] : [id], { shouldDirty: true }) }));
  }

  function handleAddTag(id: number) {
    const updatedTags = tags ? [...new Set([...tags, id])] : [id];
    setValue('tags', updatedTags, { shouldDirty: true });
  }

  function handleDeleteTag(id: number) {
    const updatedTags = tags.filter(item => item !== id);
    setValue('tags', updatedTags, { shouldDirty: true });
  }

  useEffect(() => {
    tags?.forEach(tag => {
      const tagIds = companyTags.map(item => item.id);
      if (!tagIds.includes(tag)) {
        handleDeleteTag(tag);
      }
    });
  }, [companyTags]);

  useEffect(() => {
    setValue('tags', tags);
  }, []);

  return (
    <Card>
      <SC.Form>
        <Input
          required
          name="question"
          label={t('qa.editModalQuestion')}
          type="text"
          control={control}
          error={errors['question']}
          placeholder={t('qa.editModalQuestionPlaceholder')}
        />
        <Input
          required
          name="answer"
          label={t('qa.editModalAnswer')}
          type="textarea"
          control={control}
          error={errors['answer']}
          placeholder={t('qa.editModalAnswerPlaceholder')}
        />
        <Tags
          tags={tags}
          onAdd={handleAddTag}
          onCreate={handleCreateTag}
          onDelete={handleDeleteTag}
          placeholder={t('qa.tagsPlaceholder')}
          title={t('qa.editModalTags')}
          tooltipText={t('qa.editModalTagsTooltip')}
          tagOrder={tagOrder}
          tagColors={tagColors}
          companyTags={companyTags}
          isDeleteTagOpen={isDeleteTagOpen}
        />
      </SC.Form>
    </Card>
  );
}
