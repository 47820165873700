/**
 * Used to provide the theme to styled-components. DO NOT USE this directly in your components.
 */

// TODO: Remove old variables
const theme = {
  colors: {
    /* old ---> */
    primary50: '#EBF5FF',
    primary100: '#E1EFFE',
    primary200: '#C3DDFD',
    primary300: '#A4CAFE',
    primary400: '#76A9FA',
    primary500: '#3f83f8',
    gray50: '#f9fafb',
    gray100: '#f3f4f6',
    gray200: '#e5e7eb',
    gray300: '#d1d5db',
    gray400: '#9ca3af',
    gray500: '#6b7280',
    gray600: '#4b5563',
    gray700: '#374151',
    gray800: '#1F2A37',
    gray900: '#111928',
    green50: '#F3FAF7',
    green500: '#0e9f6e',
    red50: '#FDF2F2',
    red500: '#f05252',
    yellow50: '#FDFDEA',
    yellow500: '#C27803',
    /* <--- old */
    black: '#111111',
    white: '#ffffff',
    bg: { default: '#FFFFFF', dark: '#F3F3F3', darkest: '#E7E7E7' },
    border: {
      default: '#F3F3F3',
      dark: '#E7E7E7',
    },
    text: { default: '#111111', body: '#414141', secondary: '#707070', light: '#FFFFFF' },
    brand: { default: '#0099FF', light: '#80CCFF', lightness: '#E5F5FF' },
    error: { default: '#DD3C58', light: '#EE9EAC', lightness: '#FCEBEE' },
    success: { default: '#0E9F6E', light: '#86CFB7', lightness: '#E7F5F0' },
    warning: { default: '#FEAB23', light: '#FFD591', lightness: '#FFF7E9' },
  },
  radius: { xs: '2rem', s: '2.4rem', m: '3.2rem', l: '4rem', xl: '10rem' },
  spacing: {
    s1: '.4rem',
    s2: '.8rem',
    s3: '1.2rem',
    s4: '1.6rem',
    s5: '2rem',
    s6: '2.4rem',
    s7: '3.2rem',
    s8: '4rem',
    s9: '4.8rem',
    s10: '8.8rem',
  },
  /* old ---> */
  shadows: {
    light: '0px 1px 0px 0px rgba(17, 17, 17, 0.10), 0px 0px 2px 0px rgba(17, 17, 17, 0.15)',
    sm: '0px 1px 2px rgba(0, 0, 0, 0.08)',
    md: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05)',
  },
  gradients: {
    light100: 'linear-gradient(135.69deg, rgba(105, 138, 255, 0.2) 0.59%, rgba(155, 219, 255, 0.2) 99.41%)',
    light300: 'linear-gradient(136deg, rgba(67, 165, 255, 0.26) 0.59%, rgba(152, 224, 255, 0.26) 99.41%, rgba(152, 218, 248, 0.26) 99.41%)',
  },
  /* <--- old */
};

export default theme;
