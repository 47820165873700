import * as SC from './styles';
import Icon, { IconName } from '../Icon/Icon';

interface IIconButtonProps<T> {
  id?: T;
  iconName: IconName;
  iconColor?: string;
  width?: number;
  height?: number;
  withBackground?: boolean;
  backgroundColor?: string;
  onClick: (id: T) => void;
  rotate?: number;
  isDisabled?: boolean;
  stopPropagation?: boolean;
}

export default function IconButton<T>({
  id,
  iconName,
  iconColor,
  withBackground = false,
  backgroundColor,
  onClick,
  width,
  height,
  rotate = 0,
  isDisabled = false,
  stopPropagation = false,
}: IIconButtonProps<T>) {
  return (
    <SC.IconButton
      onClick={e => {
        onClick(id as T);
        stopPropagation && e.stopPropagation();
      }}
      $rotate={rotate}
      $isDisabled={isDisabled}
    >
      <Icon
        name={iconName}
        color={iconColor}
        width={width}
        height={height}
        withBackground={withBackground}
        backgroundColor={backgroundColor}
      />
    </SC.IconButton>
  );
}
