import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.s6};
`;

export const CallWrapper = styled.div<{ $isEmpty: boolean; $noSelectedCall: boolean }>`
  display: grid;
  grid-template-columns: 40.5rem 1fr;
  overflow-y: auto;

  ${props => props.$noSelectedCall && 'align-items: center; justify-content: center;'}
`;
