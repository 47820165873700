import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  deleteAgent,
  enableAgent,
  getAgents,
  resetEditAgentId,
  saveAgent,
  setAgents,
  setEditAgentId,
  showAgentModal,
  showDeleteAgentModal,
  updateAgent,
} from './agents.redux';
import { closeModalLoading, showModalLoading } from '../loader/loader.redux';
import { IAgentWithId } from '@tuler/shared/lib/types';
import { t } from '../../config/i18n';
import { IAgentForm, IAgentsState } from './agents.types';
import { ICompanyState } from '../company/company.types';
import { AgentService, AgentsService } from './agents.service';
import { deleteImagesRequest, uploadImageRequest } from '../shared-services/image.service';
import { showToast } from '../toasts/toasts.redux';
import shortUUID from 'short-uuid';
import { logger } from '../../logger';

export function* getAgentsSaga({ payload: companyId }: PayloadAction<string>): SagaIterator {
  const agentsService = new AgentsService();
  const agents = yield call(agentsService.getAll, companyId);

  yield put(setAgents(agents ?? []));
}

export function* saveAgentSaga({ payload: agentForm }: PayloadAction<IAgentForm>): SagaIterator {
  yield put(showAgentModal(false));
  yield put(showModalLoading(t('agents.agentSavingText')));
  const { editAgentId } = yield select((state: { agents: IAgentsState }) => state.agents);
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  let success: boolean;
  if (editAgentId) {
    success = yield call(saveEditedAgentSaga, agentForm);
  } else {
    success = yield call(saveNewAgentSaga, agentForm);
  }

  yield put(getAgents(companyId));
  yield put(resetEditAgentId());
  yield put(closeModalLoading());
  if (success) {
    yield put(
      showToast({
        message: editAgentId ? t('agents.agentUpdated') : t('agents.agentCreated'),
      }),
    );
  } else {
    yield put(showToast({ type: 'error', message: editAgentId ? t('agents.agentUpdateFailed') : t('agents.agentCreateFailed') }));
  }
}

export function* saveEditedAgentSaga(agentForm: IAgentForm): SagaIterator {
  const image = agentForm.image;
  const { id: companyId } = yield select((state: { company: ICompanyState }) => state.company.company);
  const { agents } = yield select((state: { agents: IAgentsState }) => state.agents);
  const { editAgentId } = yield select((state: { agents: IAgentsState }) => state.agents);
  const oldAgent = agents.find((agent: IAgentWithId) => agent.id === editAgentId);
  const isImageChanged = oldAgent.image !== image;
  let newImageUrl = oldAgent.image;
  let newImageSmallUrl = oldAgent.imageSmall;

  if (isImageChanged && !!image) {
    const images = yield call(uploadImageRequest, companyId, 'agents', editAgentId, image, true);
    newImageUrl = images.image;
    newImageSmallUrl = images.imageSmall;
  }

  if (isImageChanged && !image && oldAgent.image.includes('http')) {
    yield call(deleteImagesRequest, [oldAgent.image, oldAgent.imageSmall]);
    newImageUrl = '';
    newImageSmallUrl = '';
  }

  const updatedAgent = {
    ...agentForm,
    image: newImageUrl,
    imageSmall: newImageSmallUrl,
    companyId,
    enabled: oldAgent.enabled,
  };
  const agentService = new AgentService(editAgentId);

  return yield call(agentService.update, companyId, editAgentId, updatedAgent);
}

export function* saveNewAgentSaga(agentForm: IAgentForm): SagaIterator {
  const { image } = agentForm;
  const { id: companyId } = yield select(state => state.company.company);
  const imageId = shortUUID.generate();
  let imageURL = image;
  let imageSmallUrl = image;

  if (image) {
    const images = yield call(uploadImageRequest, companyId, 'agents', imageId, image, true);
    imageURL = images.image;
    imageSmallUrl = images.imageSmall;
  }

  const newAgent = {
    ...agentForm,
    image: imageURL,
    imageSmall: imageSmallUrl,
    companyId,
    enabled: false,
  };

  const agentService = new AgentService();
  return yield call(agentService.create, companyId, newAgent);
}

export function* enableAgentSaga({ payload: { enable, id } }: PayloadAction<{ enable: boolean; id: string }>): SagaIterator {
  const { agents } = yield select((state: { agents: IAgentsState }) => state.agents);
  const agent = agents.find((agent: IAgentWithId) => agent.id === id);
  const updatedAgent: IAgentWithId = {
    ...agent,
    enabled: enable,
  };

  const agentService = new AgentService(id);

  yield call(agentService.update, agent.companyId, id, { enabled: enable });
  yield put(updateAgent(updatedAgent));
  yield put(
    showToast({
      message: enable ? t('agents.agentActivated') : t('agents.agentDeactivated'),
    }),
  );
}

export function* deleteAgentSaga(): SagaIterator {
  yield put(showModalLoading(t('agents.agentSavingText')));
  yield put(showDeleteAgentModal(false));
  const {
    company: { id: companyId },
  } = yield select(state => state.company);
  const { editAgentId, agents } = yield select((state: { agents: IAgentsState }) => state.agents);
  const imageToDelete = agents.find((agent: IAgentWithId) => agent.id === editAgentId).image;
  const deleteImage = imageToDelete.includes('http');
  const agentService = new AgentService(editAgentId);

  if (deleteImage) {
    try {
      yield call(deleteImagesRequest, [imageToDelete]);
    } catch (e) {
      logger.error('Error deleting agent profile picture', e);
    }
  }

  const success = yield call(agentService.delete, companyId, editAgentId);
  yield put(getAgents(companyId));

  yield put(setEditAgentId(''));
  yield put(closeModalLoading());
  yield put(
    showToast({
      type: success ? 'success' : 'error',
      message: success ? t('agents.agentDeleted') : t('agents.agentDeleteFailed'),
    }),
  );
}

export function* initAgentsSagas(): SagaIterator {
  yield takeEvery(getAgents.toString(), getAgentsSaga);
  yield takeEvery(saveAgent.toString(), saveAgentSaga);
  yield takeEvery(deleteAgent.toString(), deleteAgentSaga);
  yield takeEvery(enableAgent.toString(), enableAgentSaga);
}
