import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { ENotificationEnum, INotificationWithId, INotificationsState } from './notifications.types';
import { EDateFilter } from '../../common/types';

const initialState: INotificationsState = {
  notifications: [],
  isNotificationsOpen: false,
  unreadNotificationsCount: 0,
  typeFilter: undefined,
  dateFilter: EDateFilter.today,
  activeNotification: undefined,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationsOpen: (state: INotificationsState, action: PayloadAction<boolean>) => {
      state.isNotificationsOpen = action.payload;
    },
    setNotifications: (state: INotificationsState, action: PayloadAction<INotificationWithId[]>) => {
      state.notifications = action.payload;
    },
    onNotificationClick: (state: INotificationsState, action: PayloadAction<string>) => {},
    markAsRead: (state: INotificationsState, action: PayloadAction<{ notificationId: string; userId: string }>) => {
      const { notificationId, userId } = action.payload;
      const notificationToUpdate = state.notifications.find(
        (notification: INotificationWithId) => notification.id === notificationId,
      ) as INotificationWithId;

      if (!notificationToUpdate.read) {
        notificationToUpdate.read = true;
      }
    },
    setUnreadNotificationsCount: (state: INotificationsState, action: PayloadAction<number>) => {
      state.unreadNotificationsCount = action.payload;
    },
    setTypeFilter: (state: INotificationsState, action: PayloadAction<ENotificationEnum | undefined>) => {
      state.typeFilter = action.payload;
    },
    setDateFilter: (state: INotificationsState, action: PayloadAction<EDateFilter>) => {
      state.dateFilter = action.payload;
    },
    getActiveNotification: (state: INotificationsState, action: PayloadAction<string>) => {},
    resetActiveNotification: (state: INotificationsState, action: PayloadAction<undefined>) => {
      state.activeNotification = undefined;
    },
    setActiveNotification: (state: INotificationsState, action: PayloadAction<INotificationWithId>) => {
      state.activeNotification = action.payload;
    },
  },
});

export const {
  onNotificationClick,
  setNotificationsOpen,
  setNotifications,
  markAsRead,
  setUnreadNotificationsCount,
  setTypeFilter,
  setDateFilter,
  getActiveNotification,
  resetActiveNotification,
  setActiveNotification,
} = notificationsSlice.actions;

export const notificationsReducer: Reducer<INotificationsState> = notificationsSlice.reducer;
