import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import EntityForm from '../../components/EntityForm/EntityForm';
import { createCompany } from '../../redux/company/company.redux';
import { ICompanyForm } from '../../redux/company/company.types';
import { useDispatch } from 'react-redux';
import { newCompanyValidation } from '../../schemas';
import { Typography } from '../../components/Typography/Typography';
import { FormField } from '../../common/types';

export default function CreateCompanyPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const companyFormFields: FormField[] = [
    {
      name: 'image',
      defaultValue: '',
      label: '',
      type: 'image',
      required: false,
    },
    {
      name: 'name',
      defaultValue: '',
      label: t('company.addCompanyName'),
      placeholder: t('company.addCompanyNamePlaceholder'),
      required: true,
    },
    {
      name: 'domain',
      defaultValue: '',
      label: t('company.addCompanyDomain'),
      placeholder: t('company.addCompanyDomainPlaceholder'),
      required: true,
    },
    {
      name: 'industry',
      defaultValue: '',
      label: t('company.addCompanyIndustry'),
      placeholder: t('company.addCompanyIndustryPlaceholder'),
      required: true,
    },
    {
      name: 'adminName',
      defaultValue: '',
      label: t('company.addCompanyAdminName'),
      placeholder: t('company.addCompanyAdminNamePlaceholder'),
      required: true,
    },
    {
      name: 'adminEmail',
      defaultValue: '',
      label: t('company.addCompanyAdminEmail'),
      placeholder: t('company.addCompanyAdminEmailPlaceholder'),
      required: true,
      uniqueEmail: true,
    },
    {
      name: 'email',
      defaultValue: '',
      label: t('company.addCompanyEmail'),
      placeholder: t('company.addCompanyEmailPlaceholder'),
      required: true,
    },
    {
      name: 'phone',
      defaultValue: '',
      label: t('company.addCompanyPhone'),
      placeholder: t('company.addCompanyPhonePlaceholder'),
      required: true,
      type: 'phone',
    },
  ];

  function handleCreateCompany(data: ICompanyForm) {
    dispatch(createCompany(data));
  }

  return (
    <Layout>
      <SC.Wrapper>
        <SC.TitleWrapper>
          <Typography variant="h1">{t('company.addCompanyTitle')}</Typography>
        </SC.TitleWrapper>

        <SC.FormWrapper>
          <EntityForm id="new-company" fields={companyFormFields} onSubmit={handleCreateCompany} validationSchema={newCompanyValidation} />
        </SC.FormWrapper>
      </SC.Wrapper>
    </Layout>
  );
}
