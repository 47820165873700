import { useRef } from 'react';
import Icon, { IconName } from '../Icon/Icon';
import * as SC from './styles';
import { useTextColor } from '@tuler/shared';

interface IColorPickerProps {
  value: string;
  onChange: (value: string) => void;
}

export default function ColorPicker({ value, onChange }: IColorPickerProps) {
  const textColor = useTextColor(value);

  const timeOut: any = useRef();

  function handleChange(data: string) {
    clearTimeout(timeOut.current);
    timeOut.current = setTimeout(() => {
      onChange(data);
    }, 100);
  }

  return (
    <SC.ColorPicker $bgColor={value} $textColor={textColor}>
      <SC.ColorPickerInput type="color" value={value} onChange={e => handleChange(e.target.value)} />
      <Icon name={IconName.Picker} color={textColor} height={1.2} width={1.2} />
      {value}
    </SC.ColorPicker>
  );
}
