import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import Content from './Content';
import LogRocket from 'logrocket';

function App() {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID!, { shouldCaptureIP: false });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Content />
      </PersistGate>
    </Provider>
  );
}

export default App;
