import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { discardChanges, leavePage, showDiscardModal, showSaveBanner, triggerBannerAction } from './banner.redux';
import { formEmitter } from '../../config/formEmitter';
import { IBannerState } from './banner.types';

export function* triggerBannerActionsSaga({ payload: prefix }: PayloadAction<string>) {
  const events: string[] = yield call(formEmitter.list);
  const { invalidSaveForms } = yield select((state: { banner: IBannerState }) => state.banner);

  if (prefix === 'save' && invalidSaveForms.length > 0) {
    return;
  }

  const saveEvents = events.filter(event => event.startsWith(prefix));

  for (const event of saveEvents) {
    yield call(formEmitter.emit, event);
  }
}

export function* leavePageSaga() {
  yield put(triggerBannerAction('discard'));
  yield put(showSaveBanner(false));
  yield put(discardChanges());
  yield put(showDiscardModal(false));
}

export function* initBannerSagas(): SagaIterator {
  yield takeEvery(leavePage.toString(), leavePageSaga);
  yield takeEvery(triggerBannerAction.toString(), triggerBannerActionsSaga);
}
