import { history } from './redux/store';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { Route, Routes } from 'react-router-dom';
import { GlobalStyle } from './GlobalStyle';
import AuthPage from './pages/AuthPage/AuthPage';
import { ROUTES } from './constants/routes';
import ChatsPage from './pages/ChatsPage/ChatsPage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import DocumentationPage from './pages/DocumentationPage/DocumentationPage';
import AgentsPage from './pages/AgentsPage/AgentsPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import NotificationsPage from './pages/NotificationsPage/NotificationsPage';
import CustomersPage from './pages/CustomersPage/CustomersPage';
import CustomerPage from './pages/CustomerPage/CustomerPage';
import CompanySettingsPage from './pages/CompanySettingsPage/CompanySettingsPage';
import AccountSettingsPage from './pages/AccountSettingsPage/AccountSettingsPage';
import CreateCompanyPage from './pages/CreateCompanyPage/CreateCompanyPage';
import { useDispatch, useSelector } from 'react-redux';
import { IAuthUserState } from './redux/authUser/authUser.types';
import { useAuth } from './hooks/useAuth';
import LogRocket from 'logrocket';
import { useEffect, useState } from 'react';
import QAPage from './pages/QAPage/QAPage';
import { setFeatureFlags } from './redux/authUser/authUser.redux';
import { fetchRemoteConfigs } from './config/firebase';
import EditQAPage from './pages/EditQAPage/EditQAPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import CallsPage from './pages/CallsPage/CallsPage';
import IntegrationsPage from './pages/IntegrationsPage/IntegrationsPage';
import AircallPage from './pages/AircallPage/AircallPage';
import SmartmovingPage from './pages/SmartmovingPage/SmartmovingPage';
import TrelloPage from './pages/TrelloPage/TrelloPage';
import TelegramChatsPage from './pages/TelegramChatsPage/TelegramChatsPage';
import TelegramNotificationsPage from './pages/TelegramNotificationsPage/TelegramNotificationsPage';
import TelegramChatsEditPage from './pages/TelegramChatsEditPage/TelegramChatsEditPage';

function Content() {
  const dispatch = useDispatch();
  const [featureFlagsFetched, setFeatureFlagsFetched] = useState(false);
  const { user } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const { isReady } = useAuth();

  useEffect(() => {
    LogRocket.identify(user.uid, {
      name: user.displayName,
      email: user.email,
      subscriptionType: 'pro',
    });
  }, [user.uid]);

  useEffect(() => {
    fetchRemoteConfigs().then(flags => {
      dispatch(setFeatureFlags(flags));
      setFeatureFlagsFetched(true);
    });
  }, [dispatch]);

  if (!isReady || !featureFlagsFetched) {
    return null;
  }

  return (
    <>
      <GlobalStyle />
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
      />
      <Router history={history}>
        <Routes>
          <Route path={ROUTES.login.path} element={<AuthPage emailLogin />} />
          <Route path={ROUTES.uploaderLogin.path} element={<AuthPage />} />
          <Route path={ROUTES.chats.path} element={<ChatsPage />} />
          <Route path={ROUTES.documentation.path} element={<DocumentationPage />} />
          <Route path={ROUTES.agents.path} element={<AgentsPage />} />
          <Route path={ROUTES.dashboard.path} element={<DashboardPage />} />
          <Route path={ROUTES.notifications.path} element={<NotificationsPage />} />
          <Route path={ROUTES.customers.path} element={<CustomersPage />} />
          <Route path={ROUTES.customer.path} element={<CustomerPage />} />
          <Route path={ROUTES.companySettings.path} element={<CompanySettingsPage />} />
          <Route path={ROUTES.userProfile.path} element={<AccountSettingsPage />} />
          <Route path={ROUTES.createCompany.path} element={<CreateCompanyPage />} />
          <Route path={ROUTES.qa.path} element={<QAPage />} />
          <Route path={ROUTES.newQA.path} element={<EditQAPage />} />
          <Route path={ROUTES.calls.path} element={<CallsPage />} />
          <Route path={ROUTES.integrations.path} element={<IntegrationsPage />} />
          <Route path={ROUTES.aircall.path} element={<AircallPage />} />
          <Route path={ROUTES.smartmoving.path} element={<SmartmovingPage />} />
          <Route path={ROUTES.trello.path} element={<TrelloPage />} />
          <Route path={ROUTES.telegramChats.path} element={<TelegramChatsPage />} />
          <Route path={ROUTES.telegramChatEdit.path} element={<TelegramChatsEditPage />} />
          <Route path={ROUTES.telegramNotifications.path} element={<TelegramNotificationsPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default Content;
