/**
 * hexToRgb - Converts Hex color to Rgb color.
 * @param hex - hex color.
 * @returns Rgb color.
 */
export function hexToRgb(hex: string) {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
  }
  return [0, 0, 0];
}
