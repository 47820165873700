import { convertToCamelCase } from '@tuler/shared';
import axiosInstance from '../../config/axios';

export async function uploadImageRequest(companyId: string, folder: string, name: string, base64: string, includeLowResolution?: boolean) {
  try {
    const images = convertToCamelCase(
      (
        await axiosInstance.post(`/storage/${companyId}/image`, {
          folder: folder,
          name: name,
          file: base64,
          include_low_resolution: includeLowResolution,
        })
      ).data,
    );
    return {
      image: images.imageUrl,
      imageSmall: images.smallImageUrl,
    };
  } catch (error) {
    throw new Error(`uploadImageRequest ${error}`);
  }
}

export async function deleteImagesRequest(images: string[]): Promise<void> {
  try {
    const response = (await axiosInstance.post(`/storage/delete-files`, { images })).data;
  } catch (error) {
    throw new Error(`deleteImagesRequest ${error}`);
  }
}
