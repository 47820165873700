import styled from 'styled-components';

export const ColorPicker = styled.label<{ $bgColor: string; $textColor: string }>`
  position: relative;
  display: flex;
  width: 9.6rem;
  padding: 0.8rem 1.1rem;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: ${({ $textColor }) => $textColor};

  background: ${({ $bgColor }) => $bgColor};
  border-radius: 0.8rem;
  transition: all 0.2s;
  cursor: pointer;
`;

export const ColorPickerInput = styled.input`
  width: 0;
  height: 0;
  position: absolute;
  right: 0;
  top: 0;

  opacity: 0;
`;
