import styled from 'styled-components';

export const AvatarWrapper = styled.div<{ $size: string }>`
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
`;

export const AvatarImage = styled.img<{ $size: string }>`
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};

  object-fit: cover;
  border-radius: ${({ theme }) => theme.radius.xl};
`;

export const AvatarPlaceholder = styled.div<{ $size: string; $fontSize: string }>`
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: 500;
  line-height: 150%;
  color: ${props => props.theme.colors.text.light};

  background: ${props => props.theme.colors.brand.default};
  border-radius: ${({ theme }) => theme.radius.xl};
`;
