import { styled } from 'styled-components';
import { IButtonStyleProps } from './types';

export const Button = styled.button<IButtonStyleProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 1.6rem;
  gap: 0.8rem;
  width: ${props => (props.$expand ? '100%' : 'fit-content')};

  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5;
  text-transform: capitalize;
  color: ${props => props.$color};
  background: ${props => props.$bgColor};
  border: ${props => (props.$withBorders ? `0.1rem solid ${props.$color}` : 0)};

  border-radius: 10rem;
  transition: all 0.1s;
  box-shadow: ${({ theme, $withShadows }) => ($withShadows ? theme.shadows.light : 'none')};
  cursor: pointer;
  user-select: none;
  white-space: ${({ $noWrap }) => ($noWrap ? 'nowrap' : 'normal')};

  &:disabled {
    pointer-events: none;
  }
  &:hover {
    filter: ${props => (['outline', 'secondary'].includes(props.$variant!) ? 'brightness(85%)' : 'brightness(110%)')};
  }
  &:active {
    transform: scale(0.98);
  }
`;

export const ButtonLoader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
