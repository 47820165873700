import { EDateFilter } from '../../common/types';
import { IBaseResponse } from '../../types';

export interface ICallsState {
  calls: IListCall[];
  activeCall?: ICall;
  dateFilter: EDateFilter;
  handlerFilter?: ECallHandler;
}

export interface ICallMessage {
  role: string;
  message: string;
}

export interface ICall {
  id: number;
  recordingUrl: string;
  phoneNumber?: string;
  createdAt: string;
  summary: string;
  messages: ICallMessage[];
  direction: string;
}

export interface IListCall {
  id: number;
  phoneNumber?: string;
  createdAt: string;
  summary: string;
  direction: string;
  handler: ECallHandler;
}

export enum ECallHandler {
  AI = 'ai',
  Human = 'human',
}

export interface IGetCallsResponse extends IBaseResponse {
  calls?: IListCall[];
}

export interface IGetCallResponse extends IBaseResponse {
  call?: ICall;
}
