import styled from 'styled-components';

export const IconButton = styled.button<{ $rotate: number; $isDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: none;
  transition: all 0.2s;
  cursor: pointer;
  will-change: transform;
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};

  transform: rotate(${({ $rotate }) => $rotate}deg);
  &:hover {
    transform: scale(1.1) rotate(${({ $rotate }) => $rotate}deg);
  }
  &:active {
    transform: scale(0.98) rotate(${({ $rotate }) => $rotate}deg);
  }
`;
