import styled, { keyframes } from 'styled-components';

const appearMessage = keyframes`
 0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Container = styled.div<{ $isAssistant: boolean }>`
  display: flex;
  align-items: ${props => (!props.$isAssistant ? 'flex-start' : 'flex-end')};
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s1};
`;

export const Message = styled.div<{ $isAssistant: boolean }>`
  max-width: 42rem;
  animation: ${appearMessage} 0.2s linear;
  border-radius: 1.5rem;
  padding: 0.7rem 1.2rem;
  font-size: 1.2rem;
  word-wrap: break-word;
  white-space: pre-line;
  ${props =>
    !props.$isAssistant
      ? `background: ${props.theme.colors.bg.dark}; color: ${props.theme.colors.text.default};`
      : `background: ${props.theme.colors.brand.default}; color: ${props.theme.colors.white};`};
`;

export const Row = styled.div<{ $isAssistant: boolean }>`
  display: flex;
  justify-content: ${({ $isAssistant }) => (!$isAssistant ? 'flex-start' : 'flex-end')};
  gap: ${({ theme }) => theme.spacing.s2};
`;

export const CreatedAt = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 1rem;
`;

export const MessageUrl = styled.a<{ $color: string }>`
  color: ${({ $color }) => $color};
`;
