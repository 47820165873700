import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import { useURLParams } from '../../hooks/navigation';
import { FormField } from '../../common/types';
import { ICompanyState } from '../../redux/company/company.types';
import { ITelegramState } from '../../redux/telegram/telegram.types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { createChat, getActiveChat, setActiveChat, updateChat } from '../../redux/telegram/telegram.redux';
import { TelegramChatValidationType, telegramChatValidation } from '../../schemas';
import EntityForm from '../../components/EntityForm/EntityForm';
import Card from '../../components/Card/Card';

export default function TelegramChatsEditPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { chatId: urlChatId } = useURLParams();
  const { isChatLoading, activeChat } = useSelector((state: { telegram: ITelegramState }) => state.telegram);
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const chatId = Number(urlChatId);
  const isEdit = chatId;
  const editChatFormKey = JSON.stringify(activeChat);

  const chatFields: FormField[] = [
    {
      name: 'chatName',
      defaultValue: activeChat?.chatName || '',
      label: t('telegramChats.formTitleChatName'),
      placeholder: t('telegramChats.formTitleChatNamePlaceholder'),
      required: true,
    },
    {
      name: 'chatId',
      defaultValue: activeChat?.chatId.toString() || '',
      label: t('telegramChats.formTitleChatId'),
      placeholder: t('telegramChats.formTitleChatIdPlaceholder'),
      required: true,
    },
    {
      name: 'chatAdminId',
      defaultValue: activeChat?.chatAdminId?.toString() || '',
      label: t('telegramChats.formTitleChatAdminId'),
      placeholder: t('telegramChats.formTitleChatAdminIdPlaceholder'),
    },
  ];

  function handleSaveChat(data: TelegramChatValidationType) {
    if (chatId) {
      dispatch(updateChat({ ...data, id: chatId, chatAdminId: data.chatAdminId ? data.chatAdminId : undefined }));
    } else {
      dispatch(createChat({ ...data, chatAdminId: data.chatAdminId ? data.chatAdminId : undefined }));
    }
  }

  useEffect(() => {
    if (!companyId) return;

    if (chatId) {
      dispatch(getActiveChat(chatId));
    } else {
      dispatch(setActiveChat(undefined));
    }
  }, [companyId]);

  return (
    <Layout>
      <PageContent hasBack title={t('telegramChats.editChatTitle')}>
        <Card>
          <EntityForm
            id={String(chatId)}
            key={editChatFormKey}
            fields={chatFields}
            onSubmit={handleSaveChat}
            validationSchema={telegramChatValidation}
            isLoading={isChatLoading}
          />
        </Card>
      </PageContent>
    </Layout>
  );
}
