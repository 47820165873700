import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { logger } from '../../logger';
import { ROUTES } from '../../constants/routes';
import { push } from 'redux-first-history';
import { auth } from '../../config/firebase';
import { PayloadAction } from '@reduxjs/toolkit';
import { logout, setAuthUser, setIsUserEditLoading, updateAuthUser } from './authUser.redux';
import { IAuthUserState } from './authUser.types';
import { t } from '../../config/i18n';
import { AccountValidationType } from '../../schemas';
import { deleteImagesRequest, uploadImageRequest } from '../shared-services/image.service';
import { showToast } from '../toasts/toasts.redux';
import { updateUserRequest } from '../users/users.service';

export function* updateAuthUserSaga({ payload: updatedUser }: PayloadAction<AccountValidationType>): SagaIterator {
  yield put(setIsUserEditLoading(true));
  const {
    uid,
    companyId,
    email,
    role,
    photoUrl: oldPhotoUrl,
    token,
  } = yield select((state: { authUser: IAuthUserState }) => state.authUser.user);
  const { displayName, photoUrl } = updatedUser;
  const isPhotoUrlChanged = oldPhotoUrl !== photoUrl;
  let newPhotoUrl = oldPhotoUrl;

  if (isPhotoUrlChanged && !!photoUrl) {
    newPhotoUrl = (yield call(uploadImageRequest, companyId, 'users', uid, photoUrl)).image;
  }

  if (isPhotoUrlChanged && !photoUrl && oldPhotoUrl?.includes('http')) {
    yield call(deleteImagesRequest, [oldPhotoUrl]);
    newPhotoUrl = '';
  }
  const user = {
    uid,
    displayName,
    email,
    role,
    photoUrl: newPhotoUrl ? newPhotoUrl : null,
  };

  yield call(updateUserRequest, {
    companyId,
    user,
  });
  yield put(setAuthUser({ ...user, companyId, token }));
  yield put(showToast({ message: t('account.settingsUpdated') }));
  yield put(setIsUserEditLoading(false));
}

export function* logoutSaga(): SagaIterator {
  try {
    yield call([auth, auth.signOut]);
    yield call([localStorage, 'removeItem'], 'userToken');
    yield put(push(ROUTES.login.path));
  } catch (e) {
    logger.error('logoutSaga', e);
  }
}

export function* initAuthUserSagas(): SagaIterator {
  yield takeEvery(logout.toString(), logoutSaga);
  yield takeEvery(updateAuthUser.toString(), updateAuthUserSaga);
}
