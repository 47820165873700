import { IBaseResponse } from '../../types';

export enum UserRole {
  superuser = 'superuser', // Tuler admin
  admin = 'admin',
  default = 'default', // can be for a company or for Tuler
}

export interface IUserResponse {
  uid: string;
  email: string;
  display_name: string;
  role: UserRole;
  photo_url: string;
  companyId: string;
  token?: string;
}

export interface IUser {
  email: string;
  displayName: string;
  photoUrl?: string;
  role: UserRole;
  whatsappPhone?: string;
}

export interface IUserWithId {
  uid: string;
  email: string;
  displayName: string;
  photoUrl?: string;
  role: UserRole;
  whatsappPhone?: string;
}

export interface IUsersState {
  users: IUserWithId[];
  deleteUserModalOpen: boolean;
  activeUserId: string;
  isLoadingUsers: boolean;
  isUserEditLoading: boolean;
  activeUser?: IActiveUser;
  resetPasswordSentUsers: string[];
}

export interface ICreateUser {
  companyId: string;
  user: IUser;
}

export interface IUpdateUser {
  companyId: string;
  user: IUserWithId;
}

export interface IActiveUser {
  uid: string;
  displayName?: string;
  photoUrl?: string;
  exists: boolean;
}

export interface IGetUserResponse extends IBaseResponse {
  user?: IUserWithId;
}
