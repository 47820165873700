import styled from 'styled-components';

export const Container = styled.div<{ $backgroundColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 30rem;
  right: 0;
  height: 7.4rem;
  padding: ${({ theme }) => theme.spacing.s4} ${({ theme }) => theme.spacing.s10};

  background-color: ${props => props.$backgroundColor};
  z-index: 2;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s4};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s1};
`;

export const InfoDesc = styled.div`
  opacity: 0.5;
`;
