import React from 'react';
import { Container } from './styles';

interface IProps {
  children: React.ReactNode;
  authRequired?: boolean;
  skipQuoteCheck?: boolean;
  checkMobile?: boolean;
}

export default function ScreenWrapper({ children }: IProps) {
  return <Container>{children}</Container>;
}
