import { convertToCamelCase, convertToSnakeCase } from '@tuler/shared';
import axiosInstance from '../../config/axios';
import { IGetBillingDataResponse } from './billing.types';
import { logger } from '../../logger';

export async function getBillingDataRequest(companyId: string, startDate: string, endDate: string): Promise<IGetBillingDataResponse> {
  try {
    const res = await axiosInstance.get(`call/${companyId}/billing`, {
      params: convertToSnakeCase({
        startDate: startDate,
        endDate: endDate,
      }),
    });
    return convertToCamelCase(res.data);
  } catch (e) {
    logger.error('getBillingDataRequest', e);
    return { success: false };
  }
}
