import { CSSProperties, ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import * as SC from './styles';
import Icon, { IconName } from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';

export type TSearchBorder = 'all' | 'bottom' | 'none';

interface ISearchInputProps {
  placeholder?: string;
  onSearch: (value: string) => void;
  initialValue?: string;
  containerStyles?: CSSProperties;
  inputStyles?: CSSProperties;
  border?: TSearchBorder;
  expand?: boolean;
}

function SearchInput({
  placeholder,
  onSearch,
  containerStyles,
  initialValue,
  inputStyles,
  border = 'all',
  expand = false,
}: ISearchInputProps) {
  const [value, setValue] = useState('');
  const [oldSearchValue, setOldSearchValue] = useState('');

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter' && oldSearchValue !== value) {
      onSearch(value);
      setOldSearchValue(value);
    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function handleReset() {
    setValue('');
    onSearch('');
    setOldSearchValue('');
  }

  useEffect(() => {
    if (initialValue !== undefined && initialValue !== value) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <SC.Container style={containerStyles} $border={border} $expand={expand}>
      <Icon name={IconName.Search} />
      <SC.Input value={value} placeholder={placeholder} onKeyDown={handleKeyDown} onChange={handleChange} style={inputStyles} />
      {!!value && <IconButton iconName={IconName.X} onClick={handleReset} />}
    </SC.Container>
  );
}

export default SearchInput;
