import { styled } from 'styled-components';

export const Body = styled.div`
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const InputWrapper = styled.div`
  margin: 1.6rem 0 0.8rem;
`;

export const Buttons = styled.div`
  margin-top: 0.8rem;
  display: flex;
  gap: 1.6rem;
`;
