import * as SC from './styles';
import Icon from '../Icon/Icon';
import { IButtonProps } from './types';
import { useColors } from '../../hooks/theme';
import { Oval } from 'react-loader-spinner';

function Button({
  type = 'button',
  icon,
  bgColor,
  color,
  variant = 'primary',
  expand = false,
  disabled = false,
  loading = false,
  children,
  noWrap = false,
  onClick,
}: IButtonProps) {
  const colors = useColors();

  switch (variant) {
    case 'primary':
      color = colors.text.light;
      bgColor = colors.brand.default;
      break;
    case 'secondary':
      color = colors.text.default;
      bgColor = colors.bg.dark;
      break;
    case 'outline':
      color = colors.brand.default;
      bgColor = 'transparent';
      break;
    case 'danger':
      color = colors.text.light;
      bgColor = colors.error.default;
      break;
    case 'white':
      color = colors.text.default;
      bgColor = colors.bg.default;
      break;
    default:
      color = colors.text.light;
      bgColor = colors.brand.default;
      break;
  }

  if (disabled) {
    // add opacity
    color = color + '84';
    bgColor = bgColor + '84';
  }

  if (loading && variant === 'outline') {
    // add opacity
    color = color + 84;
    bgColor = 'transparent';
  } else if (loading) {
    // add opacity
    color = 'transparent';
    bgColor = bgColor + '84';
  }

  const loaderColor = bgColor === 'transparent' ? colors.white : bgColor?.toString().slice(0, 7);
  const loaderSecondaryColor = color === 'transparent' ? colors.white : color?.toString().slice(0, 7);
  const withBorders = !['primary', 'secondary', 'white'].includes(variant);
  const withShadows = variant === 'white';

  return (
    <SC.Button
      disabled={disabled || loading}
      type={type}
      $bgColor={bgColor}
      $color={color}
      $expand={expand}
      $variant={variant}
      $withBorders={withBorders}
      $withShadows={withShadows}
      onClick={onClick}
      $noWrap={noWrap}
    >
      {loading && (
        <SC.ButtonLoader>
          <Oval
            height={18}
            width={18}
            color={loaderColor}
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor={loaderSecondaryColor}
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </SC.ButtonLoader>
      )}
      {icon && <Icon name={icon} color={color} />}
      {children}
    </SC.Button>
  );
}

export default Button;
