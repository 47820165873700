import { useTheme, DefaultTheme } from 'styled-components';

export function useColors() {
  const theme = useTheme();

  return theme?.colors;
}

export function useShadows() {
  const theme = useTheme();

  return theme?.shadows;
}

export function useGradients() {
  const theme = useTheme();

  return theme?.gradients;
}

export function useRadiuses() {
  const theme = useTheme();

  return theme?.radius;
}

export function useSpacings() {
  const theme = useTheme();

  return theme?.spacing;
}
