import LogRocket from 'logrocket';

export const logger = {
  info: (...args) => process.env.NODE_ENV === 'development' && console.log(...args),
  error: (...args) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(...args);
    } else {
      LogRocket.captureException(args[1], {
        extra: {
          pageName: window.location.pathname,
          message: args[0],
        },
      });
    }
  },
  warn: (...args) => process.env.NODE_ENV === 'development' && console.warn(...args),
};
