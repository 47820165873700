import styled from 'styled-components';
import { HideScrollbar } from '../../GlobalStyle';

export const Container = styled.div`
  margin-top: auto;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem;

  border: 0.1rem solid ${({ theme }) => theme.colors.border.dark};
  border-bottom: 0;
  border-radius: ${({ theme }) => `${theme.radius.m} ${theme.radius.m}`} 0 0;
  background: ${({ theme }) => theme.colors.bg.default};
`;

export const InputWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.s5};
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing.s2};

  border-radius: ${({ theme }) => theme.radius.s};
  background: ${({ theme }) => theme.colors.bg.dark};
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.s4} 0;

  font-size: 1.4rem;
  line-height: 150%;
  font-family: inherit;

  border: 0;
  border-radius: 0.25rem;
  resize: none;
  box-sizing: border-box;
  background: transparent;
  ${HideScrollbar}
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.brand.default};
  }
`;

export const ButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.s3} 0;
`;
