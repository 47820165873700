import * as SC from './styles';
import Icon, { IconName } from '../Icon/Icon';
import { useColors } from '../../hooks/theme';
import IconButton from '../IconButton/IconButton';
import { QAValidationType } from '../../schemas';
import { Typography } from '../Typography/Typography';
import { IQATag, IQATagColor, IQAWithId } from '../../redux/qa/qa.types';
import Avatar from '../Avatar/Avatar';
import { useTranslation } from 'react-i18next';
import Checkbox from '../Checkbox/Checkbox';

interface IQAItemProps {
  qa: IQAWithId;
  isOpen: boolean;
  suggestionTags?: string[];
  showActionButtons: boolean;
  onSelect: () => void;
  onOpen: () => void;
  onOpenEdit: () => void;
  onSubmit: (data: QAValidationType, id: number) => void;
  tagOrder: number[];
  tagColors: IQATagColor[];
  companyTags: IQATag[];
  userName?: string;
  userAvatar?: string;
  isSelected: boolean;
  showCheckbox: boolean;
}

export default function QAItem({
  qa,
  isOpen,
  showActionButtons,
  onSelect,
  onOpen,
  onOpenEdit,
  tagColors,
  companyTags,
  userName,
  userAvatar,
  isSelected,
  showCheckbox,
}: IQAItemProps) {
  const colors = useColors();
  const { t } = useTranslation();

  function getTag(id: number) {
    const tag = companyTags.find(tag => tag.id === id);
    const color = tagColors.find(color => color.id === tag?.colorId);

    return (
      <SC.HeaderInfoTag key={`listing-tag-${id}`} $textColor={color?.color ?? ''} $backgroundColor={color?.secondaryColor ?? ''}>
        {tag?.label}
      </SC.HeaderInfoTag>
    );
  }

  return (
    <SC.Wrapper>
      <SC.Header>
        <SC.HeaderInfo>
          {showCheckbox && <Checkbox name={qa.id + 'qa'} checked={isSelected} onChange={onSelect} />}
          <SC.HeaderInfoIcon>
            {userName ? (
              <Avatar name={userName} image={userAvatar} size="m" customTitle={t('qa.lastEditor', { name: userName })} />
            ) : (
              <Icon name={IconName.MessageQuestion} />
            )}
          </SC.HeaderInfoIcon>
          <SC.HeaderInfoCol>
            <Typography variant="label" ellipsis={!isOpen}>
              {qa.question}
            </Typography>
            <Typography variant="caption" ellipsis={!isOpen} isPre={isOpen}>
              {qa.answer}
            </Typography>
            {!!qa.tags?.length && <SC.HeaderInfoTags>{qa.tags.map(getTag)}</SC.HeaderInfoTags>}
          </SC.HeaderInfoCol>
        </SC.HeaderInfo>
        <SC.HeaderActions>
          {showActionButtons && (
            <IconButton id={qa?.id} iconName={IconName.PencilAlt} onClick={onOpenEdit} iconColor={colors.text.default} withBackground />
          )}
          <IconButton
            id={qa.id}
            iconName={IconName.ChevronDown}
            onClick={onOpen}
            iconColor={colors.text.default}
            withBackground
            rotate={isOpen ? 180 : 0}
          />
        </SC.HeaderActions>
      </SC.Header>
    </SC.Wrapper>
  );
}
