export enum UserRole {
  superuser = 'superuser', // Tuler admin
  admin = 'admin',
  default = 'default', // can be for a company or for Tuler
}

export enum EFeatureFlag {
  AGENT_ENABLED = 'AGENT_ENABLED',
  CHAT_ENABLED = 'CHAT_ENABLED',
  CUSTOMERS_ENABLED = 'CUSTOMERS_ENABLED',
  DOCUMENTS_ENABLED = 'DOCUMENTS_ENABLED',
}

export type TFeatureConfig = {
  [key in EFeatureFlag]?: boolean;
} & Record<string, boolean>;

export interface IAuthUser {
  uid: string;
  email: string;
  displayName: string;
  photoUrl?: string;
  role: UserRole;
  companyId?: string;
  token?: string;
}

export interface IAuthUserState {
  user: Required<IAuthUser>;
  isResetPasswordEmailSent: boolean;
  isEditUserLoading: boolean;
  featureFlags: TFeatureConfig;
}
