import { Reducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBillingData, IBillingState } from './billing.types';

const initialState: IBillingState = {
  totalCost: 0,
  todayCost: 0,
  dailyAnalytics: [],
  isBillingLoading: false,
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    getBillingData: (state: IBillingState, action: PayloadAction<string>) => {},
    setBillingData: (state: IBillingState, action: PayloadAction<IBillingData>) => {
      state.totalCost = action.payload.totalCost;
      state.todayCost = action.payload.todayCost;
      state.dailyAnalytics = action.payload.dailyAnalytics;
    },
    setIsBillingLoading: (state: IBillingState, action: PayloadAction<boolean>) => {
      state.isBillingLoading = action.payload;
    },
  },
});

export const { getBillingData, setBillingData, setIsBillingLoading } = billingSlice.actions;

export const billingReducer: Reducer<IBillingState> = billingSlice.reducer;
