import styled from 'styled-components';

export const Input = styled.input`
  display: none;
`;

export const InputWrapper = styled.span<{ $checked: boolean }>`
  flex-shrink: 0;
  display: block;
  width: 1.6rem;
  height: 1.6rem;

  transition: all 0.2s;
  border: ${({ theme, $checked }) =>
    $checked ? `.4rem solid ${theme.colors.brand.default} !important` : `.1rem solid ${theme.colors.border.dark}`};
  border-radius: 50%;
  background: ${props => (props.$checked ? props.theme.colors.white : props.theme.colors.bg.darkest)};
`;

export const Label = styled.label<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  cursor: pointer;
  pointer-events: ${props => (props.$disabled ? 'none' : 'auto')};
  &:hover ${InputWrapper} {
    border: 0.1rem solid ${({ theme }) => theme.colors.brand.default};
    background: ${props => props.theme.colors.bg.default};
  }
`;
