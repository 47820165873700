import { IDashboardSession } from '../../types';
import { logger } from '../../logger';
import FirestoreService from '../../services/firestore.service';
import { convertToSnakeCase } from '@tuler/shared';
import axiosInstance from '../../config/axios';
import { boolean } from 'zod';

export class SessionService extends FirestoreService {
  constructor(sessionId?: string) {
    super('sessions', sessionId);
  }

  public setMessageNeedsAttention = async (createdAt: string) => {
    try {
      const { messages } = (await this.getDoc()) as IDashboardSession;
      const messageIndex = messages.findIndex(message => message.createdAt === createdAt);

      if (messageIndex !== -1) {
        messages[messageIndex].needsAttention = false;
        this.updateDoc({ messages });
      }
    } catch (e) {
      logger.error('setMessageNeedsAttention', e);
    }
  };
}

export async function newMessageRequest(sessionId: string, message: string) {
  try {
    const res = await axiosInstance.post(`session/assistant-message`, convertToSnakeCase({ sessionId, message }));
  } catch (e) {
    logger.error('newMessageRequest', e);
  }
}

export async function resetUserUnseenMessagesRequest(sessionId: string) {
  try {
    const res = await axiosInstance.delete(`session/${sessionId}/reset-user-unseen-messages`);
  } catch (e) {
    logger.error('resetUserUnseenMessagesRequest', e);
  }
}

export async function setAssistantTypingRequest(sessionId: string, isTyping: boolean) {
  try {
    const res = await axiosInstance.post(`session/${sessionId}/set-typing`, {
      entity: 'assistant',
      is_typing: isTyping,
    });
  } catch (e) {
    logger.error('setAssistantTypingRequest', e);
  }
}
