import { convertToCamelCase, convertToSnakeCase } from '@tuler/shared';
import axiosInstance from '../../config/axios';
import { logger } from '../../logger';
import FirestoreService from '../../services/firestore.service';
import { IGetCallsAnalyticsResponse } from './dashboard.types';

export class DashboardService extends FirestoreService {
  constructor() {
    super('sessions');
  }

  public getActiveSessions = async (companyId: string, startDate: string, endDate: string) => {
    try {
      const sessions = await this.queryByMultipleFields([
        { fieldName: 'updated_at', fieldValue: startDate, operator: '>=' },
        { fieldName: 'updated_at', fieldValue: endDate, operator: '<=' },
        { fieldName: 'company_id', fieldValue: companyId, operator: '==' },
      ]);

      return sessions.length;
    } catch (e) {
      logger.error('DashboardService.getActiveSessions', e);
    }
  };

  public getAskedQuestions = async (companyId: string, startDate: string, endDate: string) => {
    try {
      const res = await axiosInstance.get(`analytics/${companyId}/asked-question`, {
        params: convertToSnakeCase({
          startDate: startDate,
          endDate: endDate,
        }),
      });
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('DashboardService.getAskedQuestions', e);
    }
  };

  public getChatOpenAnalytics = async (companyId: string, startDate: string, endDate: string) => {
    try {
      const res = await axiosInstance.get(`analytics/${companyId}/chat-open`, {
        params: convertToSnakeCase({
          startDate: startDate,
          endDate: endDate,
        }),
      });
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('DashboardService.getChatOpenAnalytics', e);
    }
  };

  public getCallsAnalytics = async (companyId: string, startDate: string, endDate: string): Promise<IGetCallsAnalyticsResponse> => {
    try {
      const res = await axiosInstance.get(`analytics/${companyId}/calls`, {
        params: convertToSnakeCase({
          startDate: startDate,
          endDate: endDate,
        }),
      });
      return convertToCamelCase(res.data);
    } catch (e) {
      logger.error('DashboardService.getCallsAnalytics', e);
      return { success: false };
    }
  };
}
