import { SagaIterator } from 'redux-saga';
import { select, call, put, takeEvery, take } from 'redux-saga/effects';
import {
  getCompaniesRequest,
  getCompanyRequest,
  deleteCompanyRequest,
  deleteAgentsImageRequest,
  deleteCompanyDocumentsRequest,
  deleteEmailSenderRequest,
  CompanyService,
} from './company.service';
import {
  updateCompany,
  createCompany,
  getCompany,
  getCompanies,
  setCompanies,
  setCompany,
  showDeleteModal,
  deleteCompany,
  setIsEditCompanyLoading,
} from './company.redux';
import { closeModalLoading, setPageLoading, showModalLoading } from '../loader/loader.redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { ICompanyForm } from './company.types';
import { logger } from '../../logger';
import { deleteUsersRequest, deleteCompanyUsersRequest, sendPasswordResetEmailRequest } from '../users/users.service';
import { deleteThemeRequest } from '../theme/theme.service';
import { deleteImagesRequest } from '../shared-services/image.service';
import { t } from 'i18next';
import { AgentsService } from '../agents/agents.service';
import { showToast } from '../toasts/toasts.redux';
import { showSaveBanner } from '../banner/banner.redux';
import { replace } from 'redux-first-history';
import { DocumentationMetaService } from '../documentation/documentation.service';
import { standardizeDomain } from '../../common';
import { ROUTES } from '../../constants/routes';

export function* createCompanySaga({ payload: companyForm }: PayloadAction<ICompanyForm>): SagaIterator {
  yield put(showModalLoading(t('company.createCompanyLoading')));
  const { name, adminName, adminEmail, domain, industry, image, email, phone } = companyForm;
  const companyService = new CompanyService();

  const isSuccess = yield call(companyService.create, {
    name,
    domain: standardizeDomain(domain),
    industry,
    image: image ? image : undefined,
    email,
    phone,
    adminEmail,
    adminName,
  });

  if (isSuccess) {
    // TODO: send reset email in backend
    yield call(sendPasswordResetEmailRequest, email);

    yield put(getCompanies());
    yield take(setCompanies);
    yield put(replace(ROUTES.dashboard.path));
    yield put(closeModalLoading());
  }

  yield put(
    showToast({
      type: isSuccess ? 'success' : 'error',
      message: isSuccess ? t('company.createSuccess') : t('company.createFailed'),
    }),
  );
}

export function* updateCompanySaga({ payload: companyForm }: PayloadAction<ICompanyForm>): SagaIterator {
  yield put(setIsEditCompanyLoading(true));
  const { name, domain, industry, image, email, phone } = companyForm;
  const { id } = yield select(state => state.company.company);
  const companyService = new CompanyService();

  const isSuccess = yield call(companyService.update, id, {
    name,
    domain: standardizeDomain(domain),
    industry,
    image: image ? image : undefined,
    email,
    phone,
  });

  if (isSuccess) {
    yield put(getCompanies());
    yield put(getCompany(id));
  }
  yield put(setIsEditCompanyLoading(false));
  yield put(showSaveBanner(false));
  yield put(
    showToast({
      type: isSuccess ? 'success' : 'error',
      message: isSuccess ? t('company.updateSuccess') : t('company.updateFailed'),
    }),
  );
}

export function* getCompaniesSaga(): SagaIterator {
  const companies = yield call(getCompaniesRequest);
  yield put(setCompanies(companies));
}

export function* getCompanySaga({ payload }: PayloadAction<string>): SagaIterator {
  try {
    // TODO: think about better loader system
    const company = yield call(getCompanyRequest, payload);

    yield put(setCompany({ ...company }));
  } catch (e) {
    logger.error('getCompanySaga', e);
  }
}

export function* deleteCompanySaga(): SagaIterator {
  try {
    yield put(showDeleteModal(false));
    yield put(showModalLoading(t('company.deleteCompanyLoading')));
    const {
      company: { id, users, image, email },
      agents,
    } = yield select(state => state.company);
    const deleteImage = image?.includes('http');

    if (deleteImage) {
      yield call(deleteImagesRequest, [image]);
    }
    yield call(deleteCompanyDocumentsRequest, id);
    yield call(deleteAgentsImageRequest, id);
    yield call(deleteThemeRequest, id);
    yield call(deleteEmailSenderRequest, { email });
    if (agents?.length) {
      const agentsService = new AgentsService();
      yield call(agentsService.deleteAll, id);
    }
    yield call(deleteUsersRequest, users);
    yield call(deleteCompanyUsersRequest, users);
    const documentationMetaService = new DocumentationMetaService(id);
    yield call(documentationMetaService.deleteVectorCollection, id);
    const success = yield call(deleteCompanyRequest, id);
    yield put(getCompanies());
    yield take(setCompanies);
    yield put(replace(ROUTES.dashboard.path));
    yield put(closeModalLoading());
    if (success) {
      showToast({
        message: t('company.deleteSuccess'),
      });
    } else {
      showToast({
        type: 'error',
        message: t('company.deleteFailed'),
      });
    }
  } catch (e) {
    logger.error('deleteCompanySaga', e);
    showToast({
      type: 'error',
      message: t('company.deleteFailed'),
    });
  }
}

export function* initCompanySagas(): SagaIterator {
  yield takeEvery(createCompany.toString(), createCompanySaga);
  yield takeEvery(updateCompany.toString(), updateCompanySaga);
  yield takeEvery(getCompanies.toString(), getCompaniesSaga);
  yield takeEvery(getCompany.toString(), getCompanySaga);
  yield takeEvery(deleteCompany.toString(), deleteCompanySaga);
}
