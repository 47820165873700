import { ENotificationEnum, IRawNotification } from './../redux/notifications/notifications.types';
import { useEffect } from 'react';
import { collection, doc, onSnapshot, orderBy, query, Unsubscribe, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import { logger } from '../logger';
import { useDispatch } from 'react-redux';
import { setNotifications, setUnreadNotificationsCount } from '../redux/notifications/notifications.redux';
import { INotificationWithId } from '../redux/notifications/notifications.types';
import { convertToCamelCase } from '@tuler/shared';
import { t } from 'i18next';
import { getDateFilter } from '../common';
import { EDateFilter } from '../common/types';

function getModifiedNotification(notification: IRawNotification): INotificationWithId {
  return {
    id: notification.id,
    type: notification.type,
    title: t(`notifications.notificationTitle-${convertToCamelCase(notification.type)}`),
    content: t(t(`notifications.notificationDesc-${convertToCamelCase(notification.type)}`), notification.data),
    createdAt: notification.createdAt,
    sessionId: notification.sessionId,
    read: notification.read,
    data: notification.data,
    qaId: Number(notification.qaId),
  };
}

export default function useNotifications(
  uid: string,
  isNotificationsPage: boolean,
  typeFilter?: ENotificationEnum,
  dateFilter?: EDateFilter,
) {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsubscribeNotifications: Unsubscribe;

    if (uid) {
      try {
        const usersRef = doc(db, 'users', uid);
        const notificationsRef = collection(usersRef, 'notifications');
        let notificationsPath = query(notificationsRef, orderBy('created_at'));

        if (isNotificationsPage && typeFilter) {
          notificationsPath = query(notificationsPath, where('type', '==', typeFilter));
        }

        if (isNotificationsPage && dateFilter) {
          const currentFilter = getDateFilter(dateFilter);

          notificationsPath = query(
            notificationsPath,
            where('created_at', '>=', currentFilter.start),
            where('created_at', '<=', currentFilter.end),
          );
        }

        unsubscribeNotifications = onSnapshot(notificationsPath, querySnapshot => {
          let notifications: INotificationWithId[] = [];

          querySnapshot.forEach(doc => {
            notifications.push(getModifiedNotification(convertToCamelCase({ id: doc.id, ...doc.data() }) as IRawNotification));
          });

          dispatch(setUnreadNotificationsCount(notifications.filter(notification => !notification.read).length));

          if (typeFilter) {
            notifications = notifications.filter(notification => notification.type === typeFilter);
          }

          if (isNotificationsPage) {
            dispatch(
              setNotifications(
                notifications.sort((a, b) => {
                  const dateA = new Date(a.createdAt);
                  const dateB = new Date(b.createdAt);
                  return dateB.getTime() - dateA.getTime();
                }),
              ),
            );
          }

          logger.warn('Notification event');
        });
      } catch (e) {
        logger.error('useNotifications', e);
      }
    }

    return () => {
      if (unsubscribeNotifications) {
        unsubscribeNotifications();
      }
    };
  }, [uid, isNotificationsPage, typeFilter, dateFilter]);
}
